import React, { createContext } from "react";
import useScanner from "../hooks/useScanner";

export const ScannerContext = createContext([]);

function ScannerContextProvider({ children }) {
  const scanner = useScanner();

  return (
    <ScannerContext.Provider value={scanner}>
      {children}
    </ScannerContext.Provider>
  );
}

export default ScannerContextProvider;
