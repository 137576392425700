import axios from "axios";
import { API_HOST, API_HOST_PREV_DASHBOARD_V2 } from "../constants";

export const getToken = (paramName = "access_token_prev") => {
  if (localStorage) {
    return localStorage.getItem(paramName);
  }
  return null;
};

export const withToken = (callback) => async (params) => {
  if (!getToken()) return;
  const token = getToken();
  return await callback({ token, ...params });
};

export const authenticatedPutFile = withToken(
  async ({ path, data, config = {}, token, host = API_HOST }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .put(url, data, {
        headers: { Authorization: auth },
        ...config,
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => ({ error }));
  }
);

export const authenticatedPostFile = withToken(
  async ({ path, data, config = {}, token, host = API_HOST }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .post(url, data, {
        headers: { Authorization: auth },
        ...config,
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const authenticatedPost = withToken(
  async ({ path, data, token, headers = {}, host = API_HOST, params = {} }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .post(url, data, {
        headers: { Authorization: auth, ...headers },
        params,
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          return [];
        }
      })
      .catch((error) => ({ error }));
  }
);

// Converter petição

export async function convertePeticao(param) {
  if (!getToken()) return;

  const url = `${API_HOST}/utils/peticoes/converter`;
  const auth = "Bearer " + getToken();

  return axios
    .put(url, param, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export const authenticatedGetFile = withToken(
  async ({ path, token, host = API_HOST }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios({
      url,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: auth },
    })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
      })
      .catch(function (error) {
        throw error;
      });
  }
);

export const authenticatedDelete = withToken(
  async ({ path, data, config = {}, token, host = API_HOST }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .delete(url, {
        headers: { Authorization: auth },
        ...config,
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => ({ error }));
  }
);

export const authenticatedGet = withToken(
  async ({ path, token, host = API_HOST, headers = {}, ...rest }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios({
      url,
      method: "GET",
      headers: { Authorization: auth, ...headers },
      ...rest,
    })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => ({ error }));
  }
);

// Converter petição

export async function putPeticao(body) {
  if (!getToken()) return;

  const url = `${API_HOST_PREV_DASHBOARD_V2}/clientes-peticoes/${body.id}`;
  const auth = "Bearer " + getToken();

  return axios
    .put(url, body, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export const unauthenticatedGet = async ({
  path,
  token,
  host = API_HOST,
  headers = {},
  ...rest
}) => {
  const url = `${host}${path}`;

  return await axios({
    url,
    method: "GET",
    headers: { ...headers },
    ...rest,
  })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error) => ({ error }));
};
