import styled from "styled-components";
import React from "react";
import GridAtividades from "../components/CalculoCnis/GridAtividades";
import { useContext } from "react";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";
import { useNavigate } from "react-router-dom";
import { SegmentContext } from "@prev/ui-kit";
import { useEffect } from "react";

const CalculoCnisContainer = styled.div`
  max-width: 1280px;
  margin: auto;
`;

const Footer = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  bottom: 0;
  box-shadow: 0px 1px 15px rgba(99, 99, 99, 0.2);
  background-color: white;
`;

function CalculoCnis() {
  const { parseCnis, parseComunicado, setTipoFluxo } = useContext(FluxoBeneficioContext)
  const navigate = useNavigate()
  const { analytics, identified } = useContext(SegmentContext)

  if(!parseCnis || !parseComunicado) {
      setTipoFluxo("")
      navigate("/")
  };

  useEffect(() => {
    if(analytics && identified) {
      analytics.track("Entrou na tela de Vínculos no fluxo de benefício por incapacidade")
    }
  }, [analytics, identified])

  return (
    <>
      <CalculoCnisContainer>
        <GridAtividades />
      </CalculoCnisContainer>
      <Footer />
    </>
  );
}

export default CalculoCnis;
