import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { arraysEqual } from "../functions/arraysEquals";
import { getContribuicoesAutomaticas } from "../services/CalculoService";

const useVinculosSegurados = (
  cnis,
  setVinculos,
  currentItem,
  setCurrentItem
) => {
  const [openDividirVinculo, setOpenDividirVinculo] = useState(false);
  const [openNovoVinculo, setOpenNovoVinculo] = useState(false);

  useEffect(() => {
    if (cnis?.vinculos) updateVinculos(cnis.vinculos);
  }, [cnis]);

  const sortNovaListaVinculos = (list) => {
    if (!arraysEqual(list, cnis.vinculos)) {
      setVinculos(list.sort((a, b) => moment(a.inicio) - moment(b.inicio)));
    }
  };

  const updateVinculos = (vinculosNovos) => {
    const vinculosAtualizados = vinculosNovos.map((vinculo) => {
      return {
        ...vinculo,
        contribuicoes: getContribuicoesAutomaticas(vinculo),
        key: vinculo.key || uniqueId(),
      };
    });
    sortNovaListaVinculos(vinculosAtualizados);
  };

  const atualizaNomeVinculo = (value, vinculo) => {
    const removeVinculoAntigo = cnis.vinculos.filter(
      (vinculoAntigo) => vinculoAntigo.key !== vinculo.key
    );
    const vinculoNomeAtualizado = {
      ...vinculo,
      nomeDeExibicao: value,
      origem: value,
    };
    const vinculosAtualizados = [...removeVinculoAntigo, vinculoNomeAtualizado];
    sortNovaListaVinculos(vinculosAtualizados);
  };

  const atualizaPeriodoContribuicao = (periodo, vinculo) => {
    const [inicio, fim] = periodo;
    const vinculoAtualizado = {
      ...vinculo,
      inicio: moment(inicio).format("YYYY-MM-DD"),
      fim: moment(fim).format("YYYY-MM-DD"),
    };
    const removeVinculoAntesAtualizar = cnis.vinculos.filter(
      (prevVinculo) => vinculo.key !== prevVinculo.key
    );
    const vinculosAtualizados = [
      ...removeVinculoAntesAtualizar,
      vinculoAtualizado,
    ];
    sortNovaListaVinculos(vinculosAtualizados);
  };

  const atualizaVinculoRecebido = (vinculo, vinculoAtualizado) => {
    const removeVinculoAntesAtualizar = cnis.vinculos.filter(
      (prevVinculo) => vinculo.key !== prevVinculo.key
    );
    const vinculosAtualizados = [
      ...removeVinculoAntesAtualizar,
      vinculoAtualizado,
    ];
    sortNovaListaVinculos(vinculosAtualizados);
  };

  const adicionaProfissaoVinculo = (profissao, vinculo) =>
    atualizaVinculoRecebido(vinculo, {
      ...vinculo,
      profissoes: profissao ? [profissao] : [],
    });

  const adicionaCategoriaVinculo = (categoria, vinculo) =>
    atualizaVinculoRecebido(vinculo, {
      ...vinculo,
      categorias: categoria ? [categoria] : [],
    });

  const atualizaTipoAtividadeVinculo = (atividade, vinculo) =>
    atualizaVinculoRecebido(vinculo, {
      ...vinculo,
      atividadeEspecial: atividade,
    });

  const atualizaCarenciaVinculo = (carencia, vinculo) =>
    atualizaVinculoRecebido(vinculo, {
      ...vinculo,
      contaComoCarencia: carencia,
    });

  const deletarVinculo = (vinculoParaExcluir, setAvisoDeletar) => {
    const newData = cnis.vinculos.filter(
      (data) => data.key !== vinculoParaExcluir.key
    );
    setAvisoDeletar(false);
    setVinculos(newData);
  };

  return {
    openDividirVinculo,
    setOpenDividirVinculo,
    openNovoVinculo,
    setOpenNovoVinculo,
    currentItem,
    setCurrentItem,
    atualizaNomeVinculo,
    atualizaPeriodoContribuicao,
    adicionaProfissaoVinculo,
    adicionaCategoriaVinculo,
    atualizaTipoAtividadeVinculo,
    atualizaCarenciaVinculo,
    deletarVinculo,
    updateVinculos,
  };
};

export default useVinculosSegurados;
