import IMask from "imask";
import moment from "moment";

const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: 2100 },
  },
  format: (date) => moment(date).format(`DD/MM/YYYY`),
  mask: Date,
  parse: (date) => moment(date, `DD/MM/YYYY`),
  pattern: `DD/MM/YYYY`,
});

const onKeyUpDatepickerMask = (event) => {
  if (event.target.value.length === 10) {
    event.target.blur();
  }
  const input = event.target;
  const convertedValue = MASKED.resolve(input.value);
  input.value = convertedValue;
};

export default onKeyUpDatepickerMask;
