import { ScannerContext } from "../context/ScannerProvider"
import { useContext, useEffect } from "react"
import Grid from "antd/lib/card/Grid"
import { DisconnectOutlined } from "@ant-design/icons"
import { Button, PrevPlatformContext, Text } from "@prev/ui-kit"
import { useNavigate } from "react-router-dom"

const ExpiredMobile = () => {
    const  { theme } = useContext(PrevPlatformContext)
    const { setActiveStep, setArquivos } = useContext(ScannerContext)
    const navigate = useNavigate();

    const handleDisconnect = () => {
        setActiveStep(0)
        navigate("/")
    }

    useEffect(() => {
        localStorage.removeItem("access_token_prev")
        setArquivos([])
    }, [])

    return (
        <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', flexDirection: 'column', rowGap: '20px', columnGap: '20px'}}>
            <DisconnectOutlined style={{ fontSize: '50px', color: theme.color.neutral[600]}} color={theme.color.neutral[600]}/>
            <Text emphasys size='lg '>Esta sessão expirou</Text>
            <Text color={theme.color.neutral[600]}>
                Clique no botão abaixo para atualizar o navegador do seu celular e leia novamente o 
                QR code na versão web
            </Text>
            <Button size='large' onClick={handleDisconnect}>Atualizar</Button>
        </Grid>
    )
}

export default ExpiredMobile