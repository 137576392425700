import React from "react";
import {
  PrevPlatformProvider,
  LoadingProvider,
  SegmentProvider,
} from "@prev/ui-kit";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "antd/dist/antd.css";
import CalculoCnis from "./pages/CalculoCnis";
import Contribuicoes from "./components/CalculoCnis/Contribuicoes";
import EditarDocumento from "./pages/EditarDocumento";
import Scanner from "./pages/scanner/Scanner";
import ScannerContextProvider from "./context/ScannerProvider";
import { QrCodeReading } from "./pages/QrCodeReading/QrCodeReading";
import UploadFilesPage from "./pages/UploadFilesPage";
import InformacoesComplementares from "./pages/InformacoesComplementares";
import FluxoBeneficioProvider from "./context/FluxoBeneficioProvider";
import ErrorPage from "./pages/ErrorPage";
import Conclusao from "./pages/Conclusao";
import Header from "./components/Header";
import RelatorioValorCausa from "./components/RelatorioValorCausa/RelatorioValorCausa";

function ProviderWrapper({ children }) {
  return (
    <PrevPlatformProvider>
      <LoadingProvider>
        <SegmentProvider>
          <FluxoBeneficioProvider>
            <ScannerContextProvider>{children}</ScannerContextProvider>
          </FluxoBeneficioProvider>
        </SegmentProvider>
      </LoadingProvider>
    </PrevPlatformProvider>
  );
}

export default function Root() {
  return (
    <ProviderWrapper>
      <BrowserRouter basename="/enviar">
        <Routes>
          <Route path="/" element={<QrCodeReading />} />
          <Route path="/arquivos" element={<Scanner />} />
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/beneficio-por-incapacidade">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/relatorio-valor-causa"
            element={<RelatorioValorCausa />}
          />
          <Route path="/calculo" element={<CalculoCnis />} />
          <Route path="/calculo/:key" element={<Contribuicoes />} />
          <Route path="/editor" element={<EditarDocumento />} />
          <Route path="/upload-files" element={<UploadFilesPage />} />
          <Route path="/conclusao" element={<Conclusao />} />
          <Route
            path="/informacoes-complementares"
            element={<InformacoesComplementares />}
          />
          <Route path="/error" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </ProviderWrapper>
  );
}
