import {
  Button,
  DatePicker,
  FieldInput,
  Label,
  PrevPlatformContext,
  Select,
  Switch,
  Text,
} from "@prev/ui-kit";
import { Col, Form, Row } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";
import {
  dateFormatYYYYMMDDToDDMMYYYY,
  isValidDate,
} from "../../functions/momentFunctions";
import moment from "moment";
import { atividadeEspecial } from "../../constants";
import { v4 as uuidv4 } from "uuid";
import onKeyUpDatepickerMask from "../../utils/masks/maskDatePicker";

const DividirVinculoModalContainer = styled.div`
  & .ant-modal-body {
    padding: 0px;
    margin: 15px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0px 24px;
`;

function DividirVinculoModal({
  setOpenDividirVinculo,
  currentItem,
  setVinculos,
  vinculos,
}) {
  const { theme } = useContext(PrevPlatformContext);

  const disabledDate = (current) => {
    let startCheck = true;
    let endCheck = true;
    if (currentItem.inicio) {
      startCheck =
        current && current <= moment(currentItem.inicio, "YYYY-MM-DD");
    }
    if (currentItem.fim) {
      endCheck = current && current >= moment(currentItem.fim, "YYYY-MM-DD");
    }
    return (currentItem.inicio && startCheck) || (currentItem.fim && endCheck);
  };

  const contribuicoesEntre = (de, ate) => {
    const contribuicoesOriginais = currentItem.contribuicoes;
    const contribuicoesFiltradas = contribuicoesOriginais.filter(
      (contribuicao) => {
        const currentCompetencia = moment(contribuicao.competencia, "YYYY-MM");
        const estaEntre = currentCompetencia.isBetween(de, ate);
        const mesmoMes = currentCompetencia.isSame(de, "month");
        if (estaEntre || mesmoMes) return contribuicao;
        return false;
      }
    );
    return contribuicoesFiltradas;
  };

  const divideVinculo = (values) => {
    const removeOriginalVinculo = vinculos.filter(
      (vinculo) => vinculo.key !== currentItem.key
    );

    const vinculoOne = {
      ...currentItem,
      contaComoCarencia: values.carenciaOne,
      inicio: moment(values.dataInicialOne, "DD/MM/YYYY").format("YYYY-MM-DD"),
      fim: moment(values.dataFinalOne, "DD/MM/YYYY").format("YYYY-MM-DD"),
      nomeDeExibicao: values.nomeDeExibicaoOne,
      atividadeEspecial: values.tipoAtividadeOne,
      key: uuidv4(),
      contribuicoes: contribuicoesEntre(
        moment(values.dataInicialOne, "DD/MM/YYYY").format("YYYY-MM-DD"),
        moment(values.dataFinalOne, "DD/MM/YYYY").format("YYYY-MM-DD")
      ),
    };

    const vinculoTwo = {
      ...currentItem,
      contaComoCarencia: values.carenciaTwo,
      inicio: moment(values.dataInicialTwo, "DD-MM-YYYY").format("YYYY-MM-DD"),
      fim: moment(values.dataFinalTwo, "DD-MM-YYYY").format("YYYY-MM-DD"),
      nomeDeExibicao: values.nomeDeExibicaoTwo,
      atividadeEspecial: values.tipoAtividadeTwo,
      key: uuidv4(),
      contribuicoes: contribuicoesEntre(
        moment(values.dataInicialTwo, "DD-MM-YYYY").format("YYYY-MM-DD"),
        moment(values.dataFinalTwo, "DD-MM-YYYY").format("YYYY-MM-DD")
      ),
    };
    const newVinculos = [...removeOriginalVinculo, vinculoOne, vinculoTwo].sort(
      (a, b) => moment(a.inicio) - moment(b.inicio)
    );
    setVinculos(newVinculos);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    divideVinculo(values);
    setOpenDividirVinculo(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <DividirVinculoModalContainer>
      <Form
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        name="dividirVinculo"
      >
        <Row gutter={[57, 0]}>
          <Col span={12}>
            <Row gutter={[32, 0]}>
              <Col span={24}>
                <Form.Item
                  name="nomeDeExibicaoOne"
                  initialValue={currentItem.nomeDeExibicao}
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira um nome para o vínculo",
                    },
                  ]}
                >
                  <FieldInput
                    placeholder="Atividade do vínculo original"
                    label="Atividade do vínculo original"
                    required
                    data-testid="input-atividade-vinculo-original"
                    id="input-atividade-vinculo-original"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Label>
                  Data de início
                  <Form.Item
                    name="dataInicialOne"
                    initialValue={
                      isValidDate(currentItem.inicio) &&
                      dateFormatYYYYMMDDToDDMMYYYY(currentItem.inicio)
                    }
                    style={{ marginTop: 8 }}
                    onKeyUp={(event) => onKeyUpDatepickerMask(event)}
                  >
                    <DatePicker
                      prefix="/"
                      disabled
                      size="large"
                      data-testid="input-data-inicial-atividade-original"
                      id="input-data-inicial-atividade-original"
                    />
                  </Form.Item>
                </Label>
              </Col>
              <Col span={12}>
                <Label>
                  {
                    <Text type="span" color="red">
                      *{" "}
                    </Text>
                  }
                  Data de fim
                  <Form.Item
                    name="dataFinalOne"
                    initialValue={
                      isValidDate(currentItem.inicio) &&
                      dateFormatYYYYMMDDToDDMMYYYY(currentItem.inicio).add(
                        1,
                        "days"
                      )
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor insira uma data final para o vínculo",
                      },
                    ]}
                    style={{ marginTop: 8 }}
                    onKeyUp={(event) => onKeyUpDatepickerMask(event)}
                  >
                    <DatePicker
                      prefix="/"
                      disabledDate={(current) => disabledDate(current)}
                      size="large"
                      data-testid="input-data-final-atividade-original"
                      id="input-data-final-atividade-original"
                    />
                  </Form.Item>
                </Label>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={currentItem.atividadeEspecial}
                  name="tipoAtividadeOne"
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira um tipo de atividade",
                    },
                  ]}
                >
                  <Select
                    label={
                      <div style={{ display: "flex", gap: "0px 2px" }}>
                        <Text type="span" color="red">
                          *
                        </Text>
                        <Text color={theme.color.neutral[600]}>
                          Tipo de atividade
                        </Text>
                      </div>
                    }
                    placeholder="Selecione"
                    options={atividadeEspecial}
                    data-testid="select-tipo-atividade-original"
                    id="select-tipo-atividade-original"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Label>
                  Contar na carência
                  <Form.Item
                    initialValue={currentItem.contaComoCarencia}
                    name="carenciaOne"
                    style={{ marginTop: 10 }}
                    valuePropName="checked"
                  >
                    <Switch
                      defaultChecked={currentItem.contaComoCarencia}
                      data-testid="switch-carencia-vinculo-original"
                      id="switch-carencia-vinculo-original"
                    />
                  </Form.Item>
                </Label>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[32, 0]}>
              <Col span={24}>
                <Form.Item
                  name="nomeDeExibicaoTwo"
                  initialValue={currentItem.nomeDeExibicao}
                  rules={[{ required: true }]}
                >
                  <FieldInput
                    placeholder="Digite a atividade"
                    label="Atividade do segundo vínculo criado"
                    required
                    data-testid="input-atividade-segundo-vinculo"
                    id="input-atividade-segundo-vinculo"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Label>
                  {
                    <Text type="span" color="red">
                      *{" "}
                    </Text>
                  }
                  Data de início
                  <Form.Item
                    name="dataInicialTwo"
                    rules={[{ required: true }]}
                    style={{ marginTop: 8 }}
                    initialValue={
                      isValidDate(currentItem.fim) &&
                      dateFormatYYYYMMDDToDDMMYYYY(currentItem.fim).subtract(
                        1,
                        "days"
                      )
                    }
                    onKeyUp={(event) => onKeyUpDatepickerMask(event)}
                  >
                    <DatePicker
                      prefix="/"
                      disabledDate={(current) => disabledDate(current)}
                      size="large"
                      data-testid="input-data-inicial-atividade-secundaria"
                      id="input-data-inicial-atividade-secundaria"
                    />
                  </Form.Item>
                </Label>
              </Col>
              <Col span={12}>
                <Label>
                  Data de fim
                  <Form.Item
                    name="dataFinalTwo"
                    initialValue={
                      isValidDate(currentItem.fim) &&
                      dateFormatYYYYMMDDToDDMMYYYY(currentItem.fim)
                    }
                    style={{ marginTop: 8 }}
                    onKeyUp={(event) => onKeyUpDatepickerMask(event)}
                  >
                    <DatePicker
                      prefix="/"
                      disabled
                      size="large"
                      data-testid="input-data-final-atividade-secundaria"
                      id="input-data-final-atividade-secundaria"
                    />
                  </Form.Item>
                </Label>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={currentItem.atividadeEspecial}
                  name="tipoAtividadeTwo"
                  rules={[{ required: true }]}
                >
                  <Select
                    label={
                      <div style={{ display: "flex", gap: "0px 2px" }}>
                        <Text type="span" color="red">
                          *
                        </Text>
                        <Text color={theme.color.neutral[600]}>
                          Tipo de atividade
                        </Text>
                      </div>
                    }
                    placeholder="Selecione"
                    options={atividadeEspecial}
                    data-testid="select-tipo-atividade-secundaria"
                    id="select-tipo-atividade-secundaria"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Label>
                  Contar na carência
                  <Form.Item
                    initialValue={currentItem.contaComoCarencia}
                    name="carenciaTwo"
                    style={{ marginTop: 10 }}
                  >
                    <Switch
                      defaultChecked={currentItem.contaComoCarencia}
                      data-testid="switch-carencia-vinculo-secundaria"
                      id="switch-carencia-vinculo-secundaria"
                    />
                  </Form.Item>
                </Label>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <ButtonsContainer>
              <Button
                data-testid="button-cancelar-divisao-vinculo"
                id="button-cancelar-divisao-vinculo"
                onClick={() => setOpenDividirVinculo(false)}
              >
                Cancelar
              </Button>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  data-testid="button-confirmar-divisao-vinculo"
                  id="button-confirmar-divisao-vinculo"
                >
                  Dividir vínculo
                </Button>
              </Form.Item>
            </ButtonsContainer>
          </Col>
        </Row>
      </Form>
    </DividirVinculoModalContainer>
  );
}

export default DividirVinculoModal;
