import { Button, PrevPlatformContext, Text } from "@prev/ui-kit";
import React, { useContext } from "react";
import styled from "styled-components";

const GerarSalariosModalContainer = styled.div``;

const WarningExisteFiltro = styled.div`
  background-color: ${(props) => props.theme.color.light.grayish.blue};
  border: 1px solid ${(props) => props.theme.color.bright.blue};
  padding: 8px 15px;
  margin: 8px 0px;
  border-radius: 4px;
`;

function GerarSalariosModal({
  valorPreenchimento,
  setValorPreenchimento,
  substituirCnis,
  setSubstituirCnis,
  existeFiltro,
}) {
  const { theme } = useContext(PrevPlatformContext);

  const propsButtonNoSelected = {
    style: {
      border: `1px solid ${theme.color.neutral[600]}`,
    },
    color: theme.color.neutral[100],
    textcolor: theme.color.neutral[600],
  };

  const propsButtonSelected = {
    color: "brand-secondary",
  };

  return (
    <GerarSalariosModalContainer>
      {(existeFiltro.contribuicoesInferioresAoPiso ||
        existeFiltro.contribuicoesSuperioresAoTeto) && (
        <WarningExisteFiltro>
          <Text>
            Existe um filtro selecionado e as alterações a baixo se aplicarão
            somente para ele.
          </Text>
        </WarningExisteFiltro>
      )}
      <Text emphasys color={theme.color.neutral[1000]}>
        Preencher valores dos salários de contruibuição com:
      </Text>
      <div style={{ display: "flex", gap: 8, marginTop: 17, marginBottom: 25 }}>
        <Button
          {...(valorPreenchimento === "zero"
            ? propsButtonSelected
            : propsButtonNoSelected)}
          onClick={() => setValorPreenchimento("zero")}
          data-testid="button-zerar-salarios"
          id="button-zerar-salarios"
        >
          Zero (R$ 0,00)
        </Button>
        <Button
          {...(valorPreenchimento === "piso"
            ? propsButtonSelected
            : propsButtonNoSelected)}
          onClick={() => setValorPreenchimento("piso")}
          data-testid="button-gerar-salario-minimo"
          id="button-gerar-salario-minimo"
        >
          Salário mínimo da época
        </Button>
        <Button
          {...(valorPreenchimento === "teto"
            ? propsButtonSelected
            : propsButtonNoSelected)}
          onClick={() => setValorPreenchimento("teto")}
          data-testid="button-gerar-salario-teto"
          id="button-gerar-salario-teto"
        >
          Teto previdenciário da época
        </Button>
      </div>
      <Text emphasys color={theme.color.neutral[1000]}>
        Como o salários de cotribuição importados do CNIS:
      </Text>
      <div style={{ display: "flex", gap: 8, marginTop: 16, marginBottom: 16 }}>
        <Button
          {...(substituirCnis ? propsButtonNoSelected : propsButtonSelected)}
          onClick={() => setSubstituirCnis(false)}
          data-testid="button-manter-salarios-originais-cnis"
          id="button-manter-salarios-originais-cnis"
        >
          Manter salários do CNIS
        </Button>
        <Button
          {...(substituirCnis ? propsButtonSelected : propsButtonNoSelected)}
          onClick={() => setSubstituirCnis(true)}
          data-testid="button-substituir-salarios-originais-cnis"
          id="button-substituir-salarios-originais-cnis"
        >
          Substituir salários do CNIS
        </Button>
      </div>
      <Text color={theme.color.neutral[1000]}>
        Essa alteração afeta todos as contribuições e não pode ser desfeita a
        menos que seja feita uma nova importação de CNIS.
      </Text>
    </GerarSalariosModalContainer>
  );
}

export default GerarSalariosModal;
