import { tiposDeBeneficio } from "../constants";

export const getTipoBeneficioDesc = (tipo) => {
  const tipos = tiposDeBeneficio;
  const retorno = tipos.find((i) => i.value === tipo);

  if (retorno) {
    return retorno.desc;
  } else {
    return "NAO ENCONTRADO";
  }
};
