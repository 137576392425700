import React, { useEffect, useState } from 'react'
import { Button, LoadingContext, SegmentContext } from '@prev/ui-kit'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { convertePeticao } from '../services/adapter'
import {
  generatePdfPeticao,
  uploadPeticaoInicial
} from '../services/PeticaoService'
import { FluxoBeneficioContext } from '../context/FluxoBeneficioProvider'
import {
  PETICAO_CONCESSAO_UUID,
  PETICAO_REESTABELECIMENTO_UUID
} from '../constants'
import { useRef } from 'react'
import styled from 'styled-components'
import {
  deleteArquivo,
  listarArquivosDoBeneficio,
  verificaCartaExistenteEDeleta
} from '../services/uploadFilesService'
import '../styles/tinyMceCss.css'

const Footer = styled.div`
  width: 100vw;
  display: block;
  align-content: center;
  padding-right: 40px;
  height: 64px;
  position: fixed;
  bottom: 0;
  box-shadow: 0px 1px 15px rgba(99, 99, 99, 0.2);
  background-color: white;
  justify-content: center;
  align-items: center;
`

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
`

const contentStyles = `.enderecamento {
    text-align: justify !important;
    padding-left: 0px !important;
    font-size: 12pt !important;
    text-transform: uppercase !important;
    text-indent: 0 !important;
  }
  
  .qualificacao {
    text-align: justify !important;
    padding-left: 300px !important;
    font-size: 12pt !important;
    text-indent: 0 !important;
  }
  .nomeacao {
    text-align: center !important;
    font-size: 18pt !important;
    font-weight: bold !important;
    margin-top: 24pt !important;
    margin-bottom: 24pt !important;
    padding-left: 300px !important;
  }
  .ementa {
    text-align: justify !important;
    padding-left: 300px !important;
    font-size: 10pt !important;
    text-indent: 0 !important;
    line-height: 1.3 !important;
  }
  .topico1 {
    text-align: left !important;
    padding-left: 60px !important;
    font-size: 16pt !important;
    padding-left: 48px !important;
    padding-top: 40px !important;
  }
  .topico2 {
    text-align: left !important;
    padding-left: 60px !important;
    font-size: 14pt !important;
    padding-left: 48px !important;
    padding-top: 30px !important;
  }
  .topico3 {
    text-align: left !important;
    padding-left: 60px !important;
    font-size: 12pt !important;
    padding-left: 48px !important;
  }
  .topico4 {
    text-align: left !important;
    padding-left: 60px !important;
    font-size: 12pt !important;
    padding-left: 48px !important;
  }
  .citacao {
    line-height: 1.3 !important;
    text-align: justifY !important;
    padding-left: 120px !important;
    font-size: 10pt !important;
    margin: 0px !important;
    text-indent: 0 !important;
  }
  .dataassinatura {
    text-align: center !important;
    font-size: 12pt !important;
    text-indent: 0 !important;
  }
  .body_editor {
    width: 100vw !important;
    left: auto !important;
    margin: 4vw !important;
    max-width: 900px!important;
  }
`

const styleFormats = [
  {
    title: 'Texto normal',
    block: 'p',
    format: 'textonormal',
    classes: 'textonormal'
  },
  {
    title: 'Nome da ação',
    block: 'h2',
    format: 'nomeacao',
    classes: 'nomeacao'
  },
  {
    title: 'Endereçamento',
    block: 'p',
    format: 'enderecamento',
    classes: 'enderecamento'
  },
  {
    title: 'Ementa Petição',
    block: 'p',
    format: 'ementa',
    classes: 'ementa'
  },
  {
    title: 'Qualificação',
    block: 'p',
    format: 'qualificacao',
    classes: 'qualificacao'
  },
  {
    title: 'Tópico 1',
    block: 'h2',
    format: 'topico1',
    classes: 'topico1'
  },
  {
    title: 'Tópico 2',
    block: 'h3',
    format: 'topico2',
    classes: 'topico2'
  },
  {
    title: 'Tópico 3',
    block: 'h4',
    format: 'topico3',
    classes: 'topico3'
  },
  {
    title: 'Tópico 4',
    block: 'h5',
    format: 'topico4',
    classes: 'topico4'
  },
  {
    title: 'Citação',
    block: 'p',
    format: 'citacao',
    classes: 'citacao'
  },
  {
    title: 'Data, local e assinatura',
    block: 'p',
    format: 'dataassinatura',
    classes: 'dataassinatura'
  }
]

const EditarDocumento = () => {
  const navigate = useNavigate()
  const {
    beneficio,
    informacoesComplementares,
    resultadoComunicado,
    calculoBeneficio,
    tipoFluxo,
    userInfo,
    setValorRmi,
    parsePeticao,
    setParsePeticao
  } = useContext(FluxoBeneficioContext)
  const { loading, loaded } = useContext(LoadingContext)
  const [payload, setPayload] = useState()
  const Editor = useRef()
  const { analytics, identified } = useContext(SegmentContext)
  const [timesEdited, setTimesEdited] = useState(0)

  useEffect(() => {
    if (
      tipoFluxo === 'CONCESSAO' &&
      resultadoComunicado &&
      beneficio &&
      calculoBeneficio &&
      informacoesComplementares
    ) {
      createPayloadConcessao()
    }
    if (
      tipoFluxo === 'RESTABELECIMENTO' &&
      beneficio &&
      informacoesComplementares
    ) {
      createPayloadRestabelecimento()
    }
  }, [
    resultadoComunicado,
    beneficio,
    calculoBeneficio,
    informacoesComplementares,
    tipoFluxo
  ])

  useEffect(() => {
    if (!beneficio) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (analytics && identified)
      analytics.track('Entrou no editor no fluxo de benefício por incapacidade')
  }, [analytics, identified])

  useEffect(() => {
    if (timesEdited === 100) {
      analytics.track('Editou a petição no fluxo de benefício por incapacidade')
    }
  }, [timesEdited])

  useEffect(() => {
    if (payload) converterPeticao()
  }, [payload])

  const createPayloadRestabelecimento = () => {
    const object = {
      idDoBeneficio: beneficio.uuid,
      idEscritorio: userInfo.escritorio.id,
      cpfDoCliente: informacoesComplementares.cpf,
      idDaPeticao: PETICAO_REESTABELECIMENTO_UUID,
      ocupacao: {
        ocupacao: informacoesComplementares.ocupacao,
        descricao_sumaria: informacoesComplementares.descricaoSumaria,
        condicoes_gerais_exercicio:
          informacoesComplementares.condicoesGeraisDoExercicio
      },
      doenca: [
        ...informacoesComplementares.doencas.map((e) => ({
          codigo: e.split('-')[0].trim(),
          nome: e.split('-')[1].trim()
        }))
      ]
    }
    setPayload((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(object)) setPayload(object)
    })
  }

  const createPayloadConcessao = () => {
    const object = {
      idDoComunicadoDeDecisao: resultadoComunicado.uuid,
      idDoBeneficio: beneficio.uuid,
      idDoCalculo: calculoBeneficio.id,
      cpfDoCliente: informacoesComplementares.cpf,
      ocupacao: {
        ocupacao: informacoesComplementares.ocupacao,
        descricao_sumaria: informacoesComplementares.descricaoSumaria,
        condicoes_gerais_exercicio:
          informacoesComplementares.condicoesGeraisDoExercicio
      },
      doenca: [
        ...informacoesComplementares.doencas.map((e) => ({
          codigo: e.split('-')[0].trim(),
          nome: e.split('-')[1].trim()
        }))
      ],
      idDaPeticao: PETICAO_CONCESSAO_UUID,
      idEscritorio: userInfo.escritorio.id
    }
    setPayload((prev) => {
      if (prev !== object) setPayload(object)
    })
  }

  const converterPeticao = async () => {
    try {
      loading()
      verificaCartaExistenteEDeleta({
        cpf: informacoesComplementares.cpf,
        tipoArquivoDocumento: 'PETICAO_INICIAL',
        uuidBeneficio: beneficio.uuid
      })
      const peticao = await convertePeticao({
        ...payload
      })
      setParsePeticao(peticao)
      setValorRmi(peticao.rma.valorCausa)
      loaded()
    } catch (err) {
      console.log(err)
    }
  }

  const uploadPeticao = async (peticao) => {
    const clienteCpf = informacoesComplementares.cpf
    const idBeneficio = beneficio.uuid
    await uploadPeticaoInicial(
      peticao,
      clienteCpf,
      idBeneficio,
      tipoFluxo === 'CONCESSAO' ? 'Concessao' : 'Restabelecimento',
      beneficio.numeroDoBeneficio
    )
  }

  const addCounter = (event) => {
    const isLetter = 'Key'
    const isDigit = 'Digit'
    const startsWithKey = event?.code?.startsWith(isLetter)
    const startsWithDigit = event?.code?.startsWith(isDigit)
    if (startsWithKey || startsWithDigit) {
      setTimesEdited((prev) => prev + 1)
    }
  }

  if (!parsePeticao?.conteudo && !Editor?.current?.getContent()) return null

  return (
    <main>
      {/*<TinyMceEditor*/}
      {/*  toolbarMode={"wrap"}*/}
      {/*  styleFormats={styleFormats}*/}
      {/*  contentStyles={contentStyles}*/}
      {/*  initialContent={parsePeticao.conteudo}*/}
      {/*  toolbarHeight={"34px"}*/}
      {/*  editorBaseUrl="https://prevhml.app/mf-fluxo-beneficio/assets/tinymce"*/}
      {/*  ref={Editor}*/}
      {/*  footerHeight={64}*/}
      {/*  headerHeight={64}*/}
      {/*  onEditorChange={(event) => addCounter(event)}*/}
      {/*  width={"100vw"}*/}
      {/*/>*/}
      <Footer>
        <FooterButtons>
          <Button
            size="large"
            onClick={async () => {
              loading()
              const pdf = await generatePdfPeticao({
                filename: 'Peticão inicial.pdf',
                html: Editor.current.getContent(),
                params: null
              })
              await uploadPeticao(pdf)
              loaded()
              navigate('/upload-files')
            }}
          >
            Continuar
          </Button>
        </FooterButtons>
      </Footer>
    </main>
  )
}

export default EditarDocumento
