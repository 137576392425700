// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tox-edit-area,
.tox-toolbar,
#tinymce_ifr {
  width: 100vw !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/tinyMceCss.css"],"names":[],"mappings":"AAAA;;;EAGE,uBAAuB;AACzB","sourcesContent":[".tox-edit-area,\n.tox-toolbar,\n#tinymce_ifr {\n  width: 100vw !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
