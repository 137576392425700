import { LoadingContext } from "@prev/ui-kit";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { typeDocs } from "../constants";
import {
  deleteArquivo,
  listarArquivosDoBeneficio,
  mobileUploadFile,
} from "../services/uploadFilesService";
import { getCliente, getUsuarioMobile } from "../services/user";
import { convertBase64ToFile } from "../utils/ConvertFiles";

const useScanner = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [infoUserQrcode, setInfoUserQrcode] = useState(null);
  const [arquivos, setArquivos] = useState([]);
  const { loading, loaded } = useContext(LoadingContext);
  const [selectedType, setSelectedType] = useState(null);
  const [error, setError] = useState(null);
  const [firstVisit, setFirstVisit] = useState(true);
  const [socketMessage, setSocketMessage] = useState(null);
  const [socketMobileLoading, setSocketMobileLoading] = useState({
    DOCUMENTO_PESSOAL_NAO_CATEGORIZADO: false,
    COMPROVANTE_DE_RESIDENCIA: false,
    PROCURACAO: false,
    DOCUMENTO_MEDICO_NAO_CATEGORIZADO: false,
    DECLARACAO_DE_HIPOSUFICIENCIA: false,
    CARTA_DE_INDEFERIMENTO: false,
    DOCUMENTOS_COMPLEMENTARES: false,
  });

  const parseQrCode = async (result) => {
    try {
      if (result?.text) {
        loading();
        const codeResult = window.atob(result?.text);
        const parsedInfo = JSON.parse(codeResult);
        const getTokenUser = await getUsuarioMobile({
          tokenMobile: parsedInfo.accessToken,
          cpf: parsedInfo.cpf,
        });
        if (getTokenUser && getTokenUser.error) {
          setError(getTokenUser.error?.response?.data);
          return loaded();
        }
        const parsedToken = window.atob(getTokenUser);
        localStorage.setItem("access_token_prev", parsedToken.split(" ")[1]);
        const cliente = await getCliente(parsedInfo.cpf);
        if (cliente && cliente.error) {
          setError(cliente.error?.response?.data);
          return loaded();
        }
        setInfoUserQrcode({
          cpf: parsedInfo.cpf,
          uuidBeneficio: parsedInfo.uuidBeneficio,
          token: parsedToken.split(" ")[1],
          ...cliente,
        });
        loaded();
        return true;
      }
      return;
    } catch (err) {
      loaded();
      return false;
    }
  };

  useEffect(() => {
    if (infoUserQrcode) {
      getArquivos();
    }
  }, [infoUserQrcode]);

  const getArquivos = async () => {
    const response = await listarArquivosDoBeneficio({
      cpf: infoUserQrcode?.cpf,
      uuidBeneficio: infoUserQrcode?.uuidBeneficio,
    });
    if (response && response.error?.response.status === 401) {
      setActiveStep(6);
      return loaded();
    }
    if (response && response.error) {
      setError(response.error.response.data);
      return loaded();
    }
    const grouped = groupTypes(response);
    setArquivos(grouped);
  };

  const enviarArquivo = async (base64) => {
    loading();
    const file = convertBase64ToFile(base64);
    const fileType = file.type.split("/")[1];
    const numeroArquivo = arquivos[selectedType]?.length
      ? arquivos[selectedType].length + 1
      : 1;
    const payload = {
      file,
      cpf: infoUserQrcode?.cpf,
      uuidBeneficio: infoUserQrcode?.uuidBeneficio,
      tipoDocumento: selectedType,
      numeroArquivo,
    };
    const response = await mobileUploadFile(payload);
    if (response && response.error?.response.status === 401) {
      setActiveStep(6);
      return loaded();
    }
    if (!response?.error) {
      await getArquivos();
      setActiveStep(3);
      setSocketMessage({
        evento: "NOVO_DOCUMENTO_MOBILE",
        payload: {
          nomeArquivo: `${selectedType.toLowerCase()}-${numeroArquivo}.${fileType}`,
          tipoDocumento: selectedType,
        },
      });
      return loaded();
    }
    if (response && response.error) {
      setError(response.error?.response?.data);
      return loaded();
    }
  };

  const deletarArquivo = async (id, nomeArquivo = "") => {
    const payload = {
      uuidArquivo: id,
    };
    const response = await deleteArquivo(payload);
    if (!response) {
      setSocketMessage({
        evento: "DELETOU_DOCUMENTO_MOBILE",
        payload: {
          nomeArquivo,
          tipoDocumento: selectedType,
        },
      });
      return getArquivos();
    }
    if (response.error?.response.status === 401) {
      setActiveStep(6);
      return loaded();
    }
    if (response.error) {
      setError(response.error?.response?.data);
      return loaded();
    }
  };

  const groupTypes = (arquivos) => {
    const types = {};
    if (arquivos) {
      arquivos.forEach((arquivo) => {
        if (!types[arquivo.tipoArquivoDocumento]) {
          return (types[arquivo.tipoArquivoDocumento] = [arquivo]);
        }
        types[arquivo.tipoArquivoDocumento].push(arquivo);
      });
    }
    return types;
  };

  const findMissingGroup = () => {
    let missingGroups = typeDocs.filter((group) => !arquivos[group]);
    let mathRandom = Math.floor(Math.random() * missingGroups.length);
    while (allGroups[mathRandom] === selectedType && missingGroups.length > 1) {
      mathRandom = Math.floor(Math.random() * missingGroups.length);
    }
    setSelectedType(missingGroups[mathRandom]);
    setActiveStep(2);
  };

  return {
    infoUserQrcode,
    setInfoUserQrcode,
    parseQrCode,
    arquivos,
    selectedType,
    setSelectedType,
    activeStep,
    setActiveStep,
    deletarArquivo,
    enviarArquivo,
    findMissingGroup,
    error,
    setError,
    getArquivos,
    setArquivos,
    firstVisit,
    setFirstVisit,
    socketMessage,
    setSocketMessage,
    socketMobileLoading,
    setSocketMobileLoading,
  };
};

export default useScanner;
