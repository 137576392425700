import React from "react";
import styled from "styled-components";
import { Text, Button } from "@prev/ui-kit";
import Logo from "../../images/logoScanner.png";
import { UploadOutlined } from "@ant-design/icons";

const Wapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: ${(props) => props.theme.color.neutral[100]};
`;

const Container = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  padding: 0 22px;
  margin: ${(props) => (props.margin ? props.margin : "")};
  justify-content: ${(props) => (props.alignX ? props.alignX : "")};
`;

function CheckInQRCode({ setOpenQrCode }) {
  
  return (
    <>
      <Wapper>
        <Container alignX="center" margin="16px 0 30px 0">
          <img width="cover" src={Logo} alt="logo previdenciarista" />
        </Container>

        <Container margin="0 0 78px 0">
          <Text size="lg" emphasys style={{ lineHeight: "26px" }}>
            Para prosseguir com a digitalização dos
            documentos, siga as instruções abaixo:
          </Text>
        </Container>

        <Container margin="0 0 72px 0">
          <Text style={{ textAlign: "center" }} size="base">
            Clique no botão abaixo e mire a câmera do seu celular, no QR code da
            etapa de envio de documentos na versão web.
          </Text>
        </Container>

        <Container alignX="center">
          <Button
            style={{ width: "177px", height: "40px" }}
            aria-label="Action Button"
            id="button-escanear-codigo"
            data-testid="button-escanear-codigo"
            icon={<UploadOutlined />}
            onClick={() => setOpenQrCode(true)}
          >
            Escanear o código
          </Button>
        </Container>
      </Wapper>
    </>
  );
}

export default CheckInQRCode;
