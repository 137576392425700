import { LoadingContext, SegmentContext } from "@prev/ui-kit";
import moment from "moment";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import {
  calculadoraBeneficio,
  criaComunicadoDecisao,
  criarBeneficio,
  getConcomitanciasPeriodoFinal,
} from "../services/CalculoService";
import { getSegmentObject } from "../services/segment";
import { getUsuario } from "../services/user";

const useFluxoBeneficio = () => {
  const [arquivoCnis, setArquivoCnis] = useState([]);
  const [arquivoComunicado, setArquivoComunicado] = useState([]);
  const [arquivoCartaConcessao, setArquivoCartaConcessao] = useState([]);
  // Informações para pauload fo fluxo de beneficio restabelecimento
  const [parseCartaDeConcessao, setParseCartaDeConcessao] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.parseCartaDeConcessao
  );

  // Informações para payload do fluxo de benefício concessao
  const [cnisOriginal, setCnisOriginal] = useState();
  const [parseCnis, setParseCnis] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.parseCnis
  );
  const [parseComunicado, setParseComunicado] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.parseComunicado
  );

  const [estrategiaDoCalculo, setEstrategiaDoCalculo] = useState(
    "SOMAR_CONCOMITANCIA"
  );
  const [calculoBeneficio, setCalculoBeneficio] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.calculoBeneficio
  );
  const [informacoesComplementares, setInformacoesComplementares] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.informacoesComplementares
  );
  const [beneficio, setBeneficio] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.beneficio
  );
  const [resultadoComunicado, setResultadoComunicado] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.resultadoComunicado
  );
  const [valorRmi, setValorRmi] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.valorRmi
  );
  // Estados
  const [tipoFluxo, setTipoFluxo] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.tipoFluxo
  );
  const [listaConcomitancias, setListaConcomitancias] = useState([]);
  const [openModalConcomitancias, setOpenModalConcomitancias] = useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.userInfo
  );
  const [currentItem, setCurrentItem] = useState();
  const [filesPdf, setFilesPdf] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.filesPdf
  );
  const [error, setError] = useState();
  const [parsePeticao, setParsePeticao] = useState();

  //UI-KI
  const { loading, loaded } = useContext(LoadingContext);
  const { analytics, identified } = useContext(SegmentContext);

  const setVinculos = (vinculos) => {
    setParseCnis((prevState) => ({
      ...prevState,
      vinculos,
    }));
  };

  const getInfoUsuario = async () => {
    const user = await getUsuario();
    setUserInfo(user);
  };

  useEffect(() => {
    if (userInfo && analytics && !identified) {
      analytics.identify(userInfo.usuario.uuid, getSegmentObject(userInfo));
    }
  }, [userInfo]);

  useEffect(() => {
    const fluxoStorage = {
      parseCnis,
      parseComunicado,
      parseCartaDeConcessao,
      beneficio,
      resultadoComunicado,
      tipoFluxo,
      calculoBeneficio,
      informacoesComplementares,
      userInfo,
      valorRmi,
      filesPdf,
    };
    localStorage.setItem("fluxo", JSON.stringify(fluxoStorage));
  }, [
    parseCnis,
    parseComunicado,
    parseCartaDeConcessao,
    informacoesComplementares,
    tipoFluxo,
    beneficio,
    resultadoComunicado,
    calculoBeneficio,
    userInfo,
    valorRmi,
    filesPdf,
  ]);

  useEffect(() => {
    getInfoUsuario();
  }, []);

  useEffect(() => {
    if (tipoFluxo && analytics) {
      const fluxo =
        tipoFluxo === "CONCESSAO" ? "Concessao" : "Restabelecimento";
      analytics.track(
        `Entrou em ${fluxo} no fluxo de benefício por incapacidade`
      );
    }
  }, [tipoFluxo]);

  const fazCalculo = async () => {
    try {
      loading();
      if (checkConcomitancias() && !openModalConcomitancias) {
        loaded();
        return {
          concomitancia: true,
        };
      }
      const dadosFormatados = {
        data: {
          ...parseCnis,
          estrategiaDoCalculo,
          segurado: parseCnis.segurado,
        },
      };
      const response = await calculadoraBeneficio(dadosFormatados);
      setCalculoBeneficio(response);
      loaded();
      if (!response) return { error: true };
      return response;
    } catch (err) {
      setError(err);
      return {
        error: true,
        message:
          err.error_description || "Ocorreu um erro ao realizar o cálculo",
      };
    }
  };

  const checkConcomitancias = () => {
    try {
      const { vinculos } = parseCnis;
      if (vinculos && vinculos.length) {
        const concomitancias = getConcomitanciasPeriodoFinal(vinculos);
        const concomitanciaMaisDeUma = concomitancias.filter(
          (concomitancia) => concomitancia.vinculos.length > 1
        );
        if (
          concomitanciaMaisDeUma.length &&
          moment(parseCnis.dataDoFimDoCalculo).isBefore("2019-06-18")
        ) {
          setListaConcomitancias(
            concomitancias.filter(
              (concomitancia) => concomitancia.concomitancia
            )
          );
          setOpenModalConcomitancias(true);
          return true;
        } else {
          return false;
        }
      }
    } catch (err) {
      setError(err);
      return {
        error: true,
        message:
          err.error_description || "Ocorreu um erro ao checar concomitâncias",
      };
    }
  };

  const postBeneficio = async (payload) => {
    try {
      loading();
      const response = await criarBeneficio({
        data: payload,
      });
      setBeneficio(response);
      loaded();
      return response;
    } catch (err) {
      setError(err);
      loaded();
      return {
        error: true,
        message: err.error_description || "Ocorreu um erro ao gerar beneficio",
      };
    }
  };

  const postComunicado = async (payload) => {
    try {
      loading();
      const response = await criaComunicadoDecisao({
        data: payload,
      });
      setResultadoComunicado(response);
      loaded();
      return response;
    } catch (err) {
      setError(err);
      loaded();
      return {
        error: true,
        message:
          err.error_description ||
          "Ocorreu um erro ao gerar comunicado de decisão",
      };
    }
  };

  return {
    tipoFluxo,
    setTipoFluxo,
    parseCnis,
    setParseCnis,
    parseComunicado,
    setParseComunicado,
    informacoesComplementares,
    setInformacoesComplementares,
    setCalculoBeneficio,
    beneficio,
    setBeneficio,
    setVinculos,
    setCnisOriginal,
    cnisOriginal,
    fazCalculo,
    listaConcomitancias,
    openModalConcomitancias,
    setOpenModalConcomitancias,
    estrategiaDoCalculo,
    setEstrategiaDoCalculo,
    postBeneficio,
    postComunicado,
    resultadoComunicado,
    calculoBeneficio,
    parseCartaDeConcessao,
    setParseCartaDeConcessao,
    userInfo,
    setResultadoComunicado,
    currentItem,
    setCurrentItem,
    setFilesPdf,
    filesPdf,
    loading,
    loaded,
    error,
    valorRmi,
    setValorRmi,
    parsePeticao,
    setParsePeticao,
    arquivoCnis,
    setArquivoCnis,
    arquivoComunicado,
    setArquivoComunicado,
    arquivoCartaConcessao,
    setArquivoCartaConcessao,
  };
};

export default useFluxoBeneficio;
