import { Form, Row, Col } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";
import {
  Button,
  DatePicker,
  FieldInput,
  Label,
  Text,
  PrevPlatformContext,
  validateCpf,
  Ajuda,
} from "@prev/ui-kit";
import {
  dateFormatYYYYMMDDToDDMMYYYY,
  isValidDate,
} from "../../functions/momentFunctions";
import moment from "moment";
import { FluxoBeneficioContext } from "../../context/FluxoBeneficioProvider";
import { PlusCircleOutlined } from "@ant-design/icons";
import onKeyUpDatepickerMask from "../../utils/masks/maskDatePicker";
import { textosAjuda } from "../../constants";

const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 22px;
`;

function HeaderForm({ setOpenNovoVinculo }) {
  const { parseCnis, setParseCnis } = useContext(FluxoBeneficioContext);
  const { theme } = useContext(PrevPlatformContext);
  const [form] = Form.useForm();

  const propsButtonNoSelected = {
    style: {
      border: `1px solid ${theme.color.neutral[600]}`,
    },
    color: theme.color.neutral[100],
    textcolor: theme.color.neutral[600],
  };

  const propsButtonSelected = {
    color: "brand-secondary",
  };

  const HeaderCalculoCnisContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  `;

  return (
    <>
      <HeaderCalculoCnisContainer>
        <div>
          <Text emphasys size="lg">
            Dados do segurado
          </Text>
          <Label size="sm">
            <Text type="span" color="red">
              *
            </Text>{" "}
            Campos de preenchimento obrigatório
          </Label>
        </div>
        <div style={{ display: "flex", gap: 24 }}>
          <Button
            onClick={() => setOpenNovoVinculo(true)}
            size="large"
            icon={<PlusCircleOutlined />}
            data-testid="button-adicionar-novo-vinculo"
            id="button-adicionar-novo-vinculo"
          >
            Adicionar novo vínculo
          </Button>
        </div>
      </HeaderCalculoCnisContainer>
      <Form form={form} name="client">
        <InputsContainer>
          <Row style={{ width: "100%" }} gutter={[24, 0]}>
            <Col span={8}>
              <Form.Item
                initialValue={parseCnis?.segurado.nome}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira um nome completo válido",
                    min: 10,
                  },
                ]}
                onBlur={(event) =>
                  setParseCnis((prevState) => ({
                    ...prevState,
                    segurado: {
                      ...prevState.segurado,
                      nome: event.target.value,
                    },
                  }))
                }
              >
                <FieldInput
                  required
                  label="Nome Completo"
                  data-testid="input-nome-completo-cliente"
                  id="input-nome-completo-cliente"
                  placeholder="Nome Completo"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Label>
                <Text type="span" color="red">
                  *
                </Text>{" "}
                CPF
                <Form.Item
                  style={{ marginTop: 8 }}
                  name="cpf"
                  initialValue={parseCnis?.segurado.cpf}
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira um cpf válido",
                      validator: (_, value) => {
                        if (value) {
                          if (validateCpf(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("CPF não confere");
                          }
                        }
                      },
                    },
                  ]}
                  onChange={(event) => {
                    if (validateCpf(event.target.value)) {
                      setParseCnis((prevState) => ({
                        ...prevState,
                        segurado: {
                          ...prevState.segurado,
                          cpf: event.target.value,
                        },
                      }));
                    }
                  }}
                >
                  <FieldInput.Cpf
                    placeholder="000.000.000-00"
                    data-testid="input-cpf-cliente"
                    id="input-cpf-cliente"
                    legacy={true}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col span={4}>
              <Label>
                <Text type="span" color="red">
                  *
                </Text>{" "}
                Nascimento
                <Form.Item
                  initialValue={
                    isValidDate(parseCnis?.segurado.dataDeNascimento) &&
                    dateFormatYYYYMMDDToDDMMYYYY(
                      parseCnis?.segurado.dataDeNascimento
                    )
                  }
                  style={{ marginTop: 8 }}
                  name="nascimento"
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira uma data de nascimento válida",
                    },
                  ]}
                  onKeyUp={(value) => onKeyUpDatepickerMask(value)}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    size="large"
                    placeholder="DD/MM/AAAA"
                    prefix="/"
                    onChange={(date) =>
                      setParseCnis((prevState) => ({
                        ...prevState,
                        segurado: {
                          ...prevState.segurado,
                          dataDeNascimento:
                            date && moment(date).format("YYYY-MM-DD"),
                        },
                      }))
                    }
                    disabledDate={(current) =>
                      current && current > moment().subtract(1, "day")
                    }
                    data-testid="input-data-nascimento-cliente"
                    id="input-data-nascimento-cliente"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col span={4}>
              <Label>
                <div style={{ display: "flex" }}>
                  <Text type="span" color="red">
                    *
                  </Text>{" "}
                  Data do requerimento
                  <Ajuda
                    page="fluxoBeneficioIncapacidade"
                    element="dataCalculo"
                    itens={textosAjuda}
                  />
                </div>
                <Form.Item
                  style={{ marginTop: 8 }}
                  name="dataCalculo"
                  initialValue={
                    isValidDate(parseCnis?.dataDoFimDoCalculo) &&
                    dateFormatYYYYMMDDToDDMMYYYY(parseCnis.dataDoFimDoCalculo)
                  }
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira uma data de cálculo válida",
                    },
                    {
                      validator: (_, value) => {
                        if (value <= moment("2019-11-13").endOf("day")) {
                          return Promise.reject(
                            `Informe uma data a partir de 13/11/2019 (EC 103/19)`
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  onKeyUp={(value) => onKeyUpDatepickerMask(value)}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    size="large"
                    placeholder="DD/MM/AAAA"
                    prefix="/"
                    onChange={(date) =>
                      setParseCnis((prevState) => ({
                        ...prevState,
                        dataDoFimDoCalculo:
                          date && moment(date).format("YYYY-MM-DD"),
                      }))
                    }
                    data-testid="input-data-calculo-cliente"
                    id="input-data-calculo-cliente"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col span={4}>
              <Label>
                <div style={{ display: "flex" }}>
                  <Text type="span" color="red">
                    *
                  </Text>{" "}
                  Sexo
                  <Ajuda
                    page="fluxoBeneficioIncapacidade"
                    element="sexo"
                    itens={textosAjuda}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <Button
                    onClick={() => {
                      setParseCnis((prevState) => ({
                        ...prevState,
                        segurado: {
                          ...prevState.segurado,
                          sexo: "MASCULINO",
                        },
                      }));
                    }}
                    size="large"
                    {...(parseCnis?.segurado.sexo === "MASCULINO"
                      ? propsButtonSelected
                      : propsButtonNoSelected)}
                    data-testid="button-genero-masculino"
                    id="button-genero-masculino"
                  >
                    Masculino
                  </Button>
                  <Button
                    onClick={() => {
                      setParseCnis((prevState) => ({
                        ...prevState,
                        segurado: {
                          ...prevState.segurado,
                          sexo: "FEMININO",
                        },
                      }));
                    }}
                    size="large"
                    {...(parseCnis?.segurado.sexo === "FEMININO"
                      ? propsButtonSelected
                      : propsButtonNoSelected)}
                    data-testid="button-genero-feminino"
                    id="button-genero-feminino"
                  >
                    Feminino
                  </Button>
                </div>
              </Label>
            </Col>
          </Row>
        </InputsContainer>
      </Form>
    </>
  );
}

export default HeaderForm;
