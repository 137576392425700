import { MoreOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  EditableLabel,
  PrevPlatformContext,
  Select,
  TagWithRemover,
  Text,
} from "@prev/ui-kit";
import { Col, Dropdown, Form, Menu, Progress, Row } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import styled from "styled-components";
import { atividadeEspecial, categorias } from "../../constants";
import {
  dateFormatYYYYMMDDToDDMMYYYY,
  isValidDate,
} from "../../functions/momentFunctions";
import { getProfissoes } from "../../services/CalculoService";
import onKeyUpDatepickerMask from "../../utils/masks/maskDatePicker";

const BodyColContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 78px;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

function VinculoRender({
  vinculo,
  setCurrentItem,
  setOpenDividirVinculo,
  atualizaNomeVinculo,
  atualizaPeriodoContribuicao,
  adicionaProfissaoVinculo,
  adicionaCategoriaVinculo,
  atualizaTipoAtividadeVinculo,
  atualizaCarenciaVinculo,
  deletarVinculo,
}) {
  const { theme } = useContext(PrevPlatformContext);
  const [listaProfissao, setListaProfissao] = useState([]);
  const [isLoadingProfissaoFetch, setIsLoadingProfissaoFetch] = useState(false);
  const [avisoDeletar, setAvisoDeletar] = useState(false);
  const [progressDeletar, setProgressDeletar] = useState(0);

  const dropdownMenu = (item) => (
    <Menu
      items={[
        {
          label: (
            <Text
              onClick={() => {
                setCurrentItem({
                  ...item,
                  type: "CONTRIBUICOES",
                });
              }}
              color={theme.color.neutral[1000]}
              data-testid="ver-contribuicoes-click"
              id="ver-contribuicoes-click"
            >
              Ver contribuições
            </Text>
          ),
        },
        {
          label: (
            <Text
              onClick={() => {
                setCurrentItem({
                  ...item,
                  type: "DIVIDIR",
                });
                setOpenDividirVinculo(true);
              }}
              color={theme.color.neutral[1000]}
              data-testid="dividir-vinculo-click"
              id="dividir-vinculo-click"
            >
              Dividir vínculo
            </Text>
          ),
        },
        {
          label: (
            <Text
              onClick={() => {
                setProgressDeletar(0);
                setAvisoDeletar(true);
              }}
              color={theme.color.neutral[1000]}
              data-testid="excluir-vinculo-click"
              id="excluir-vinculo-click"
            >
              Excluir
            </Text>
          ),
        },
      ]}
    />
  );

  const profissoesResolver = async (value) => {
    const response = await getProfissoes(value);
    if (response) {
      const formatResponse = response.map((profissao) => ({
        value: profissao.titulo,
        label: profissao.titulo,
      }));
      setListaProfissao(formatResponse);
      setIsLoadingProfissaoFetch(false);
    } else {
      setIsLoadingProfissaoFetch(false);
    }
  };

  const profissaoHandler = (value) => {
    if (value.length > 3) {
      setIsLoadingProfissaoFetch(true);
      profissoesResolver(value);
    }
  };

  const debouncedCallback = useMemo(() => debounce(profissaoHandler, 300), []);

  useEffect(() => {
    return () => {
      debouncedCallback.cancel();
    };
  }, []);

  useEffect(() => {
    if (avisoDeletar) {
      if (progressDeletar < 100) {
        setTimeout(() => {
          setProgressDeletar((prevState) => prevState + 2);
        }, 50);
      }
    }
  }, [avisoDeletar, progressDeletar]);

  useEffect(() => {
    if (progressDeletar === 100 && avisoDeletar)
      deletarVinculo(vinculo, setAvisoDeletar);
  }, [progressDeletar]);

  return (
    <div>
      <Row style={{ borderBottom: `1px solid ${theme.color.neutral[400]}` }}>
        <Col span={10}>
          <BodyColContainer style={{ padding: "0 10px" }}>
            <div>
              <EditableLabel
                onValueChange={(value) => atualizaNomeVinculo(value, vinculo)}
                value={vinculo.nomeDeExibicao}
                id="editable-label-nome-exibicao"
                data-testid="editable-label-nome-exibicao"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  {vinculo.profissoes?.length ? (
                    <TagWithRemover
                      onRemove={() => adicionaProfissaoVinculo("", vinculo)}
                    >
                      {vinculo.profissoes[0]}
                    </TagWithRemover>
                  ) : (
                    <Select.Calculo
                      showSearch
                      placeholder="Escolher profissão"
                      options={listaProfissao}
                      onSearch={debouncedCallback}
                      loading={isLoadingProfissaoFetch}
                      onSelect={(profissao) =>
                        adicionaProfissaoVinculo(profissao, vinculo)
                      }
                      data-testid="select-vinculo-profissao"
                      id="select-vinculo-profissao"
                      filterOption={false}
                    />
                  )}
                </Col>
                <Col span={12}>
                  {vinculo.categorias?.length ? (
                    <TagWithRemover
                      onRemove={() => adicionaCategoriaVinculo("", vinculo)}
                    >
                      {
                        categorias.find(
                          (categoria) =>
                            categoria.value === vinculo.categorias[0]
                        )?.label
                      }
                    </TagWithRemover>
                  ) : (
                    <Select.Calculo
                      placeholder="Escolher categoria"
                      options={categorias}
                      onSelect={(categoria) =>
                        adicionaCategoriaVinculo(categoria, vinculo)
                      }
                      data-testid="select-vinculo-categoria"
                      id="select-vinculo-categoria"
                    />
                  )}
                </Col>
              </Row>
            </div>
          </BodyColContainer>
        </Col>
        <Col span={6}>
          <BodyColContainer>
            <Form>
              <Form.Item
                initialValue={
                  isValidDate(vinculo.inicio) &&
                  isValidDate(vinculo.fim) && [
                    dateFormatYYYYMMDDToDDMMYYYY(vinculo.inicio),
                    dateFormatYYYYMMDDToDDMMYYYY(vinculo.fim),
                  ]
                }
                name="date-picker"
                onKeyUp={(event) => onKeyUpDatepickerMask(event)}
                style={{ margin: 0 }}
              >
                <DatePicker.Calculo
                  prefix="/"
                  range
                  allowClear={false}
                  data-testid="input-vinculo-data-periodo"
                  id="input-vinculo-data-periodo"
                  onChange={(dateRange) =>
                    atualizaPeriodoContribuicao(dateRange, vinculo)
                  }
                />
              </Form.Item>
            </Form>
          </BodyColContainer>
        </Col>
        <Col span={4}>
          <BodyColContainer>
            <Select.Calculo
              placeholder="NORMAL"
              options={atividadeEspecial}
              value={vinculo.atividadeEspecial}
              onSelect={(atividade) =>
                atualizaTipoAtividadeVinculo(atividade, vinculo)
              }
              data-testid="select-vinculo-atividade-especial"
              id="select-vinculo-atividade-especial"
            />
          </BodyColContainer>
        </Col>
        <Col span={2}>
          <BodyColContainer>
            <Select.Calculo
              style={{ width: 66 }}
              placeholder="Sim"
              value={vinculo.contaComoCarencia}
              options={[
                { value: true, label: "Sim" },
                { value: false, label: "Não" },
              ]}
              onSelect={(carencia) =>
                atualizaCarenciaVinculo(carencia, vinculo)
              }
              data-testid="select-vinculo-carencia"
              id="select-vinculo-carencia"
            />
          </BodyColContainer>
        </Col>
        <Col span={2}>
          {avisoDeletar ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <Progress
                percent={progressDeletar}
                showInfo={false}
                status="exception"
              />
              <Button onClick={() => setAvisoDeletar(false)}>
                Reverter Exclusão
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Dropdown
                overlay={dropdownMenu(vinculo)}
                placement="bottomRight"
                arrow
                data-testid="dropdown-manipula-vinculo"
                id="dropdown-manipula-vinculo"
              >
                <MoreOutlined
                  style={{
                    fontSize: 25,
                    color: theme.color.brand.primary,
                  }}
                />
              </Dropdown>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default VinculoRender;
