import {
  API_HOST_PREV_DASHBOARD_V2,
  IAPI_HOST_PREV_DASHBOARD_V2,
} from "../constants";
import {
  authenticatedPostFile,
  authenticatedGet,
  authenticatedGetFile,
  authenticatedDelete,
} from "./adapter";

const toCamel = (s) => {
  const salve = s.toLowerCase();
  return salve.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const deleteArquivo = async ({ uuidArquivo }) => {
  return authenticatedDelete({
    host: API_HOST_PREV_DASHBOARD_V2,
    path: `/clientes-arquivos/${uuidArquivo}`,
  });
};

export async function mobileUploadFile({
  file,
  tipoDocumento,
  cpf,
  uuidBeneficio,
  numeroArquivo,
}) {
  return uploadFile({ file, tipoDocumento, cpf, uuidBeneficio, numeroArquivo });
}

export const listarArquivosDoBeneficio = async ({
  cpf,
  uuidBeneficio,
  tipoArquivoDocumento,
}) => {
  const query = `${cpf}?${
    uuidBeneficio ? `uuidBeneficio=${uuidBeneficio}` : ""
  }${
    tipoArquivoDocumento ? `&tipoArquivoDocumento=${tipoArquivoDocumento}` : ""
  }`;
  return authenticatedGet({
    path: `/clientes-arquivos/${query}`,
    host: API_HOST_PREV_DASHBOARD_V2,
  });
};

export const formatFileName = (fileName) => {
  const fileNameParts = fileName.split(".");
  const fileNameWithoutExtension = fileNameParts.slice(0, -1).join(".");
  const fileExtension = fileNameParts.slice(-1)[0];
  return `${fileNameWithoutExtension
    .replace(".", "_")
    .replace(" ", "")}.${fileExtension}`;
};

export const uploadFile = async (
  { file, tipoDocumento, cpf, uuidBeneficio, numeroArquivo },
  onCallbackProgress
) => {
  cpf.replace(/[^\d]/g, "");
  const formInstance = new FormData();
  const fileType = file.type.split("/")[1];
  formInstance.append(
    "arquivo",
    file,
    numeroArquivo
      ? `${tipoDocumento.toLowerCase()}-${numeroArquivo}.${fileType}`
      : formatFileName(file.name)
  );

  formInstance.append("tipoDocumento", tipoDocumento);

  formInstance.append("uuidBeneficio", uuidBeneficio);

  return await authenticatedPostFile({
    path: `/clientes-arquivos/${cpf}`,
    data: formInstance,
    host: IAPI_HOST_PREV_DASHBOARD_V2,
    config: {
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onCallbackProgress) onCallbackProgress(percentCompleted);
      },
    },
  });
};

export async function obterQrCode({ cpf, uuidBeneficio }) {
  return authenticatedGet({
    host: API_HOST_PREV_DASHBOARD_V2,
    path: `/clientes-arquivos/${cpf}/qrcode?uuidBeneficio=${uuidBeneficio}`,
  });
}

export async function downloadArquivo(uuidArquivo) {
  return authenticatedGetFile({
    host: IAPI_HOST_PREV_DASHBOARD_V2,
    path: `/clientes-arquivos/${uuidArquivo}/download`,
  });
}

export async function downloadArquivoZip({ cpf, uuidBeneficio }) {
  return authenticatedGetFile({
    host: IAPI_HOST_PREV_DASHBOARD_V2,
    path: `/clientes-arquivos/${cpf}/zip?uuidBeneficio=${uuidBeneficio}`,
  });
}

export async function obterListaArquivosPdf({ cpf, uuidBeneficio }) {
  return authenticatedGet({
    host: API_HOST_PREV_DASHBOARD_V2,
    path: `/clientes-arquivos/${cpf}/pdf?uuidBeneficio=${uuidBeneficio}`,
  });
}

export const verificaCartaExistenteEDeleta = async ({
  cpf,
  uuidBeneficio,
  tipoArquivoDocumento,
}) => {
  const listaArquivos = await listarArquivosDoBeneficio({
    cpf,
    tipoArquivoDocumento,
  });

  if (listaArquivos && listaArquivos.length) {
    const existePeticao = listaArquivos.filter(
      (arquivo) =>
        arquivo.tipoArquivoDocumento === tipoArquivoDocumento &&
        arquivo.uuidBeneficio === uuidBeneficio
    );
    if (existePeticao.length)
      await Promise.all(
        existePeticao.map(async (e) => {
          await deleteArquivo({
            uuidArquivo: e.id,
          });
        })
      );
  }
};
