import { Button, Text } from "@prev/ui-kit";
import { Col } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";
import failed from "../icons/failed.svg";

const ErrorPage = () => {
  const {
    setTipoFluxo,
    setParseCnis,
    setParseComunicado,
    setBeneficio,
    setParseCartaDeConcessao,
    setInformacoesComplementares,
    error,
    setArquivoCnis,
    setArquivoComunicado,
    setArquivoCartaConcessao,
  } = useContext(FluxoBeneficioContext);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("fluxo");
    setParseCnis();
    setParseComunicado();
    setTipoFluxo("");
    setBeneficio("");
    setParseCartaDeConcessao();
    setInformacoesComplementares("");
    if (!error) {
      navigate("/");
    }
  }, []);

  return (
    <Col
      style={{
        width: "100vw",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <img
        className="corners"
        style={{
          marginBottom: "20px",
        }}
        src={failed}
        alt="Status icon"
        id="status-icon"
        data-testid="status-icon"
      />
      <Text style={{ fontSize: "24px", fontWeight: "bold", color: "#000" }}>
        {error?.response?.data?.error_description ||
          "Ocorreu um erro inesperado. Tente novamente."}
      </Text>
      <Button
        style={{ cursor: "pointer" }}
        size="large"
        onClick={() => {
          setArquivoCnis([]);
          setArquivoComunicado([]);
          setArquivoCartaConcessao([]);
          navigate("/");
        }}
      >
        Recomeçar
      </Button>
    </Col>
  );
};

export default ErrorPage;
