import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { QrReader } from "react-qr-reader";
import IconScanner from "../../icons/iconQrcode.svg";
import IconClose from "../../icons/Close.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ScannerContext } from "../../context/ScannerProvider";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(189, 189, 189, 0.9);
`;

const Header = styled.div`
  width: 100%;
  padding: 1rem;
  position: absolute;
  z-index: 2;
`;

const Content = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
`;

const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContainerText = styled.div`
  position: absolute;
  margin-top: 22rem;
  z-index: 9999;
  color: ${({ theme }) => theme.color.neutral[100]};
`;

export const QrCode = ({ openQrCode, setOpenQrCode }) => {
  const { parseQrCode } = useContext(ScannerContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); 

  const handleParams = async () => {
    const token = searchParams.get("token")
    if(token) {
      const redirect = await parseQrCode({
        text: token,
      })
      if(redirect) {
        navigate('/arquivos')
      }
    }
  }

  useEffect(() => {
    handleParams()
  }, [])

  const handleScan = async (result) => {
    const token = result?.text.split("=")[1]
    const redirect = await parseQrCode({
      text: token
    });
    if (redirect) {
      navigate('/arquivos');
      return;
    }
    return;
  }

  return (
    openQrCode && (
      <>
        <Wrapper>
          <Header>
            <img onClick={() => setOpenQrCode(false)} src={IconClose} />
          </Header>
          <Content>
            <ContainerImage>
              <img src={IconScanner} style={{
                width: "70%",
              }} />
            </ContainerImage>
            <ContainerText>
              <p>Encaixe o QR code na área marcada</p>
            </ContainerText>
          </Content>
          <QrReader
            onResult={handleScan}
            constraints={{ facingMode: "environment", autoGainControl: true }}
            scanDelay={500}
            videoContainerStyle={
              {
                width: "100%", 
                height: "100%", 
                position: "absolute", 
                left: "50%", 
                marginLeft: "-50%", 
                objectFit: "cover", 
                objectPosition: "center"
              }
            }
            videoStyle={
              {
                width: "100%", 
                height: "100%", 
                position: "absolute", 
                left: "50%", 
                marginLeft: "-50%", 
                objectFit: "cover", 
                objectPosition: "center"
              }
            }
          />
        </Wrapper>
      </>
    )
  );
};
