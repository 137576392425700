import { createContext } from "react";
import useFluxoBeneficio from "../hooks/useFluxoBeneficio";

export const FluxoBeneficioContext = createContext({});

const FluxoBeneficioProvider = ({ children }) => {
  const hookFluxoBeneficio = useFluxoBeneficio();
  return (
    <FluxoBeneficioContext.Provider value={hookFluxoBeneficio}>
      {children}
    </FluxoBeneficioContext.Provider>
  );
};

export default FluxoBeneficioProvider;
