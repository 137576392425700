import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  CurrencyInput,
  PrevPlatformContext,
  Text,
} from "@prev/ui-kit";
import { Col, Row } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const GridContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.color.neutral[
      props.checked || props.editandoValorContribuicao ? 200 : 100
    ]};
  border-bottom: 2px solid ${(props) => props.theme.color.neutral[400]};
`;
const MinimoEMaximoContainer = styled.div`
  display: flex;
  gap: 10px;
  :hover {
    cursor: pointer;
  }
`;

function ContribuicaoRender({
  contribuicao,
  onContribuicaoSelecionada,
  isChecked,
  atualizaValorContribuicao,
  onPressTab,
  onPressShiftTab,
  setContribuicaoEmEdicao,
  contribuicaoEmEdicao,
}) {
  const { theme } = useContext(PrevPlatformContext);
  const [contribuicaoFloat, setContribuicaoFloat] = useState(
    contribuicao.salarioDeContribuicao
  );
  const editandoValorContribuicao =
    contribuicaoEmEdicao === contribuicao.competencia;

  const inputRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (editandoValorContribuicao) {
      inputRef.current.focus();
    }
  }, [contribuicaoEmEdicao]);

  useEffect(() => {
    setContribuicaoFloat(contribuicao.salarioDeContribuicao);
  }, [contribuicao.salarioDeContribuicao]);

  const atualizarValor = () => {
    atualizaValorContribuicao(contribuicaoFloat, contribuicao.competencia);
    setContribuicaoEmEdicao("");
  };

  const renderTag = () => {
    let formatText;

    if (contribuicao.salarioDeContribuicao < contribuicao.piso) {
      formatText = "Contribuição abaixo do mínimo";
    }

    if (contribuicao.salarioDeContribuicao > contribuicao.teto) {
      formatText = "Contribuição superior ao teto";
    }

    const response = formatText ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 9,
            backgroundColor: theme.color.light.grayish.orange,
            borderRadius: 16,
            alignItems: "center",
            justifyContent: "center",
            width: 227,
            height: 24,
          }}
        >
          <InfoCircleOutlined
            style={{ color: theme.color.functional.warning, fontSize: 14 }}
          />
          <Text color={theme.color.neutral[1000]} size="sm">
            {formatText}
          </Text>
        </div>
      </div>
    ) : null;
    return response;
  };

  const formataCompetencia = () => {
    const splitedCompetencia = contribuicao.competencia.split("-");
    return `${splitedCompetencia[1]}/${splitedCompetencia[0]}`;
  };

  return (
    <GridContainer
      checked={isChecked}
      editandoValorContribuicao={editandoValorContribuicao}
    >
      <Row align="middle">
        <Col style={{ paddingLeft: 16 }} span={3}>
          <Text color={theme.color.neutral[1000]}>{formataCompetencia()}</Text>
        </Col>
        <Col span={1}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              onChange={(e) =>
                onContribuicaoSelecionada(
                  e.target.checked,
                  contribuicao.competencia
                )
              }
              checked={isChecked}
              data-testid="checkbox-seleciona-contribuicao"
              id="checkbox-seleciona-contribuicao"
            />
          </div>
        </Col>
        <Col span={3}>
          {editandoValorContribuicao ? (
            <form ref={formRef}>
              <CurrencyInput
                ref={inputRef}
                decimalSeparator=","
                groupSeparator="."
                decimalScale={2}
                prefix={contribuicao.simboloDaMoeda}
                value={contribuicaoFloat}
                color={theme.color.neutral[1000]}
                onValueChange={(value, name, floatValue) =>
                  setContribuicaoFloat(floatValue.float)
                }
                onKeyDown={(e) => {
                  if (e.key === "Tab" && !e.shiftKey) {
                    e.preventDefault();
                    onPressTab(contribuicao.competencia, contribuicaoFloat);
                    formRef.current.reset();
                  }
                  if (e.shiftKey && e.key === "Tab") {
                    e.preventDefault();
                    onPressShiftTab(
                      contribuicao.competencia,
                      contribuicaoFloat
                    );
                    formRef.current.reset();
                  }
                }}
                onBlur={() => atualizarValor()}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    atualizarValor();
                  }
                }}
                data-testid="input-valor-contribuicao"
                id="input-valor-contribuicao"
              />
            </form>
          ) : (
            <Text
              onClick={() => {
                setContribuicaoEmEdicao(contribuicao.competencia);
              }}
              style={{ cursor: "pointer" }}
              data-testid="input-display-valor-contribuicao"
              id="input-display-valor-contribuicao"
            >
              {contribuicao.simboloDaMoeda}{" "}
              {contribuicao.salarioDeContribuicao.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
            </Text>
          )}
        </Col>
        <Col span={7}>{renderTag()}</Col>
        <Col span={4}>
          <MinimoEMaximoContainer
            onClick={() =>
              atualizaValorContribuicao(
                contribuicao.piso,
                contribuicao.competencia
              )
            }
            data-testid="click-usar-minimo-contribuicao"
            id="click-usar-minimo-contribuicao"
          >
            <ArrowDownOutlined
              style={{
                color: theme.color.brand.primary,
                fontSize: 13,
              }}
            />
            <Text type="span" color={theme.color.neutral[1000]}>
              Usar mínimo
            </Text>
          </MinimoEMaximoContainer>
        </Col>
        <Col span={4}>
          <MinimoEMaximoContainer
            onClick={() =>
              atualizaValorContribuicao(
                contribuicao.teto,
                contribuicao.competencia
              )
            }
            data-testid="click-usar-teto-contribuicao"
            id="click-usar-teto-contribuicao"
          >
            <ArrowUpOutlined
              style={{
                color: theme.color.brand.primary,
                fontSize: 13,
              }}
            />
            <Text type="span" color={theme.color.neutral[1000]}>
              Usar teto
            </Text>
          </MinimoEMaximoContainer>
        </Col>
        <Col span={2}>
          <Button
            onClick={() => {
              setContribuicaoEmEdicao(contribuicao.competencia);
            }}
            variant="link"
            color="brand-secondary"
            data-testid="button-editar-valor-contribuicao"
            id="button-editar-valor-contribuicao"
          >
            Editar
          </Button>
        </Col>
      </Row>
    </GridContainer>
  );
}

export default ContribuicaoRender;
