import React from "react";
import styled from "styled-components";
import { Text, Button, Label } from "@prev/ui-kit";
import IconSuccess from "../../icons/iconSuccess.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { ScannerContext } from "../../context/ScannerProvider";

const Wapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.color.neutral[100]};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0 22px;
  margin: ${(props) => (props.margin ? props.margin : "")};
  justify-content: ${(props) => (props.alignX ? props.alignX : "")};
  flex-direction: ${(props) => (props.direction ? props.direction : "")};
`;

const ContainerSucess = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export function DocumentsSent() {
  const navigate = useNavigate()
  const {  setActiveStep, setInfoUserQrcode, setArquivos } = useContext(ScannerContext)

  useEffect(() => {
    return () => {
      localStorage.removeItem('access_token_prev')
      setActiveStep(0)
      setInfoUserQrcode()
      setArquivos([])
    }
  }
  , [])

  return (
    <Wapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ContainerSucess>
          <Container alignX="center" margin="0 0 30px 0">
            <img src={IconSuccess} alt="" />
          </Container>
          <Container direction="column" margin="0 0 78px 0">
            <Text
              size="lg"
              emphasys
              style={{ lineHeight: "26px", marginBottom: "8px" }}
            >
              Processo concluído com sucesso!
            </Text>
            <Label style={{ textAlign: "center" }}>
              Mostraremos o resumo do processo em seu computador, ou se não
              tiver acesso a ele no momento, você pode encontrar na área de
              clientes da plataforma.
            </Label>
          </Container>

          <Container alignX="center">
            <Button
              style={{ maxWidth: "180px" }}
              aria-label="Action Button"
              id="button-ok"
              data-testid="button-ok"
              onClick={() => navigate('/')}
            >
              Ok, entendi
            </Button>
          </Container>
        </ContainerSucess>
      </div>
    </Wapper>
  );
}
