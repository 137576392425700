import React from "react";
import styled from "styled-components";
import { Text, Button, Label } from "@prev/ui-kit";
import IconOps from "../../icons/iconOps.svg";
import { CameraOutlined } from "@ant-design/icons";
import iconClose from "../../icons/Close.svg";
import { useContext } from "react";
import { ScannerContext } from "../../context/ScannerProvider";

const Wapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.color.neutral[100]};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0 22px;
  margin: ${(props) => (props.margin ? props.margin : "")};
  justify-content: ${(props) => (props.alignX ? props.alignX : "")};
  flex-direction: ${(props) => (props.direction ? props.direction : "")};
`;

const HeaderStatus = styled.div`
  width: 100%;
  height: 4rem;
  background-color: ${({ theme }) => theme.color.brand.primary};
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 999;
`;

const ContainerIconClose = styled.div`
  width: 100%;
  margin-left: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ContainerSucess = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

function ErrorMobile() {
  const { setError, setActiveStep, setSelectedType} = useContext(ScannerContext);

  return (
    <Wapper>
      <HeaderStatus>
        <ContainerIconClose onClick={() => {
          setError(null);
          setSelectedType(null);
          setActiveStep(0);
        }}>
          <img src={iconClose} alt="Icone de fechar" />
        </ContainerIconClose>
      </HeaderStatus>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ContainerSucess>
          <Container alignX="center" margin="0 0 30px 0">
            <img src={IconOps} alt="icone error" />
          </Container>
          <Container direction="column" margin="0 0 78px 0">
            <Text
              size="lg"
              emphasys
              style={{ lineHeight: "26px", marginBottom: "8px" }}
            >
              Ops! Algo deu errado.
            </Text>
            <Label style={{ textAlign: "center" }}>
              Clique no botão abaixo e tente novamente.
            </Label>
          </Container>

          <Container alignX="center">
            <Button
              style={{ maxWidth: "216px" }}
              aria-label="Action Button"
              id="button-escanear-codigo"
              data-testid="button-escanear-codigo"
              icon={<CameraOutlined />}
              onClick={() => {
                setError(null);
                setActiveStep(2);
              }}
            >
              Tente novamente.
            </Button>
          </Container>
        </ContainerSucess>
      </div>
    </Wapper>
  );
}

export default ErrorMobile;
