import { API_HOST_PREV_DASHBOARD_V2 } from "../constants"
import { authenticatedGet, unauthenticatedGet  } from "./adapter"

export const getUsuario = async () => {
    const user = await authenticatedGet({
        path: "/info",
        host: API_HOST_PREV_DASHBOARD_V2,
    })
    return user
};

export const getUsuarioMobile = async ({tokenMobile, cpf}) => {
    const user = await unauthenticatedGet({
        path: `/clientes-arquivos/${cpf}/token?accessToken=${tokenMobile}`,
        host: API_HOST_PREV_DASHBOARD_V2,
    })
    return user
};

export const getCliente = async (cpf) => {
    const user = await authenticatedGet({
        path: `/clientes/${cpf}`,
        host: API_HOST_PREV_DASHBOARD_V2,
    })
    return user
}
