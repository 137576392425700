import moment from "moment";

export const dateFormatYYYYMMDDToDDMMYYYY = (date) => {
  const formatToString = moment(date, "YYYY-MM-DD");
  const formatToMomentAgain = moment(formatToString, "DD-MM-YYYY");
  return formatToMomentAgain;
};

export const isValidDate = (stringDate) => {
  if (stringDate) return moment(stringDate).isValid();
  return false;
};
