import {
  Ajuda,
  Button,
  Modal,
  PrevPlatformContext,
  Text,
  TextArea,
  validateCpf,
} from "@prev/ui-kit";
import { Badge, Col, Row, Tooltip } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { textosAjuda } from "../../constants";
import { FluxoBeneficioContext } from "../../context/FluxoBeneficioProvider";
import useVinculosSegurados from "../../hooks/useVinculosSegurados";
import AdicionarNovoVinculoModal from "./AdicionarNovoVinculoModal";
import Contribuicoes from "./Contribuicoes";
import DividirVinculoModal from "./DividirVinculoModal";
import HeaderForm from "./HeaderForm";
import Modalconcomitancia from "./ModalConcomitancia";
import VinculoRender from "./VinculoRender";

const GridAtividadesContainer = styled.div`
  width: 100%;
  min-height: 500px;
`;

const HeaderGrid = styled.div`
  max-width: 1240px;
  min-height: 60px;
  background-color: ${(props) => props.theme.color.neutral[200]};
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0 9px;
  padding: 0 10px;
  text-align: center;
  & .anticon-info-circle {
    font-size: 12px;
  }
`;

const BodyGrid = styled.div`
  max-width: 1240px;
  min-height: 600px;
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
`;

const FooterButtons = styled.div`
  height: 64px;
  display: flex;
  gap: 12px 24px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;
  position: fixed;
  bottom: 0;
  width: 1280px;
  z-index: 11;
`;

function GridAtividades() {
  const navigate = useNavigate();
  const { theme } = useContext(PrevPlatformContext);
  const {
    setParseCnis,
    parseCnis,
    setVinculos,
    cnisOriginal,
    openModalConcomitancias,
    fazCalculo,
    setOpenModalConcomitancias,
    listaConcomitancias,
    estrategiaDoCalculo,
    setEstrategiaDoCalculo,
    currentItem,
    setCurrentItem,
  } = useContext(FluxoBeneficioContext);
  const {
    openDividirVinculo,
    setOpenDividirVinculo,
    openNovoVinculo,
    setOpenNovoVinculo,
    atualizaNomeVinculo,
    atualizaPeriodoContribuicao,
    adicionaProfissaoVinculo,
    adicionaCategoriaVinculo,
    atualizaTipoAtividadeVinculo,
    atualizaCarenciaVinculo,
    deletarVinculo,
    updateVinculos,
  } = useVinculosSegurados(parseCnis, setVinculos, currentItem, setCurrentItem);

  const [openModalCnisJson, setOpenModalCnisJson] = useState(false);
  const [cnisJson, setCnisJson] = useState();
  const [pendenciasCliente, setPendenciasCliente] = useState([]);
  const [pendenciasChecked, setPendenciasChecked] = useState(true);

  const checarDatasVinculos = (cnis) => {
    let datasInvalidas = [];

    cnis.vinculos.forEach((vinculo) => {
      if (!vinculo.inicio || !vinculo.fim)
        datasInvalidas.push(
          `Data inválida no vínculo de nome: "${vinculo.nomeDeExibicao}"`
        );
    });
    return datasInvalidas;
  };

  const resumoPendencias = (cnis) => {
    let resumo = [];
    if (!cnis.segurado.nome) {
      resumo.push("Informe o nome do segurado");
    }
    if (!cnis.segurado.cpf || !validateCpf(cnis.segurado.cpf)) {
      resumo.push("Informe um cpf válido do segurado");
    }
    if (!cnis.segurado.dataDeNascimento) {
      resumo.push("Informe a data de nascimento do segurado");
    }
    if (!cnis.segurado.sexo) {
      resumo.push("Informe o sexo do segurado");
    }
    if (
      !cnis.dataDoFimDoCalculo ||
      moment(cnis.dataDoFimDoCalculo) <= moment("2019-11-13").endOf("day")
    ) {
      resumo.push("Informe uma data do calculo válida");
    }
    if (!cnis.vinculos || !cnis.vinculos.length) {
      resumo.push(
        "Informe ao menos um vínculo com data de início e data de fim"
      );
    }

    checarDatasVinculos(cnis).forEach((dataInvalida) =>
      resumo.push(dataInvalida)
    );

    setPendenciasCliente(resumo);
    if (resumo.length === 0) {
      setPendenciasChecked(false);
    } else {
      setPendenciasChecked(true);
    }
  };

  useEffect(() => {
    if (parseCnis?.segurado) resumoPendencias(parseCnis);
  }, [parseCnis]);

  if (currentItem && currentItem.type === "CONTRIBUICOES")
    return (
      <Contribuicoes
        currentItem={currentItem}
        atualizaPeriodoContribuicao={atualizaPeriodoContribuicao}
        atualizaNomeVinculo={atualizaNomeVinculo}
        atualizaTipoAtividadeVinculo={atualizaTipoAtividadeVinculo}
        vinculos={parseCnis.vinculos}
        setVinculos={setVinculos}
      />
    );

  return (
    <>
      <HeaderForm setOpenNovoVinculo={setOpenNovoVinculo} />
      <GridAtividadesContainer>
        <HeaderGrid>
          <Row style={{ height: 60 }} align="middle" justify="space-between">
            <Col span={10}>
              <TitleContainer>
                <Text emphasys>Atividade</Text>
                <Ajuda
                  itens={textosAjuda}
                  page="fluxoBeneficioIncapacidade"
                  element="atividade"
                  propsPopover={{ placement: "right" }}
                />
              </TitleContainer>
            </Col>
            <Col span={6}>
              <TitleContainer>
                <Text emphasys>Período</Text>
                <Ajuda
                  itens={textosAjuda}
                  page="fluxoBeneficioIncapacidade"
                  element="periodo"
                />
              </TitleContainer>
            </Col>
            <Col span={4}>
              <TitleContainer>
                <Text emphasys>Tipo de atividade</Text>
                <Ajuda
                  itens={textosAjuda}
                  page="fluxoBeneficioIncapacidade"
                  element="tipoAtividade"
                />
              </TitleContainer>
            </Col>
            <Col span={4}>
              <TitleContainer>
                <Text emphasys>Carência</Text>
                <Ajuda
                  itens={textosAjuda}
                  page="fluxoBeneficioIncapacidade"
                  element="carencia"
                />
              </TitleContainer>
            </Col>
          </Row>
        </HeaderGrid>
        <BodyGrid>
          {parseCnis?.vinculos &&
            parseCnis.vinculos.map((vinculo) => (
              <VinculoRender
                key={vinculo.key}
                vinculo={vinculo}
                setCurrentItem={setCurrentItem}
                setOpenDividirVinculo={setOpenDividirVinculo}
                atualizaCarenciaVinculo={atualizaCarenciaVinculo}
                adicionaCategoriaVinculo={adicionaCategoriaVinculo}
                adicionaProfissaoVinculo={adicionaProfissaoVinculo}
                atualizaNomeVinculo={atualizaNomeVinculo}
                atualizaPeriodoContribuicao={atualizaPeriodoContribuicao}
                atualizaTipoAtividadeVinculo={atualizaTipoAtividadeVinculo}
                deletarVinculo={deletarVinculo}
              />
            ))}
        </BodyGrid>
        <FooterButtons>
          {process.env.REACT_APP_PREV_ENV === "homolog" && (
            <Button
              type="secundary"
              onClick={() => setOpenModalCnisJson(true)}
              style={{ background: "#FF1493", color: "#fff" }}
            >
              CNIS JSON
            </Button>
          )}
          <Button
            onClick={() => {
              setParseCnis(cnisOriginal);
              updateVinculos(cnisOriginal.vinculos);
            }}
            size="large"
            color="error"
          >
            Limpar
          </Button>
          <Tooltip
            title={
              !!pendenciasCliente.length && (
                <div>
                  <Text color={theme.color.neutral[100]}>Pendências</Text>
                  <Text color={theme.color.neutral[100]}>
                    Antes de prosseguir, é preciso corrigir as seguintes
                    pendências:
                  </Text>
                  {pendenciasCliente.map((pendencia) => (
                    <Text key={pendencia} color={theme.color.neutral[100]}>
                      - {pendencia}
                    </Text>
                  ))}
                </div>
              )
            }
            placement="topLeft"
          >
            <Badge count={pendenciasCliente.length} offset={[-237, 0]}>
              <Button
                disabled={pendenciasChecked}
                size="large"
                type="primary"
                onClick={async () => {
                  const response = await fazCalculo();
                  if (response.concomitancia) return;
                  if (response.error) return navigate("/error");
                  if (!response.error) navigate("/informacoes-complementares");
                }}
              >
                Checar pendências e calcular
              </Button>
            </Badge>
          </Tooltip>
        </FooterButtons>
      </GridAtividadesContainer>
      {currentItem && openDividirVinculo && (
        <Modal
          title={
            <div>
              <Text
                style={{ marginBottom: 8 }}
                color={theme.color.neutral[1000]}
              >
                Dividir vínculo
              </Text>
              <Text size="sm" color={theme.color.neutral[600]}>
                <Text type="span" color="red">
                  *
                </Text>{" "}
                Campos de preenchimento obrigatório
              </Text>
            </div>
          }
          width={760}
          visible={openDividirVinculo}
          footer={null}
          onCancel={() => setOpenDividirVinculo(false)}
        >
          <DividirVinculoModal
            setOpenDividirVinculo={setOpenDividirVinculo}
            currentItem={currentItem}
            setVinculos={setVinculos}
            vinculos={parseCnis.vinculos}
          />
        </Modal>
      )}
      {openNovoVinculo && (
        <Modal
          title={
            <div>
              <Text
                style={{ marginBottom: 8 }}
                color={theme.color.neutral[1000]}
              >
                Adicionar novo vínculo
              </Text>
              <Text size="sm" color={theme.color.neutral[600]}>
                <Text type="span" color="red">
                  *
                </Text>{" "}
                Campos de preenchimento obrigatório
              </Text>
            </div>
          }
          visible={openNovoVinculo}
          footer={null}
          onCancel={() => setOpenNovoVinculo(false)}
        >
          <AdicionarNovoVinculoModal
            setOpenNovoVinculo={setOpenNovoVinculo}
            vinculos={parseCnis.vinculos}
            setVinculos={setVinculos}
          />
        </Modal>
      )}
      {openModalCnisJson && (
        <Modal
          visible={openModalCnisJson}
          title="CNIS JSON"
          onCancel={() => setOpenModalCnisJson(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Cancelar"
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
            <TextArea
              style={{ height: 300 }}
              onChange={(e) => setCnisJson(e.target.value)}
              placeholder="Cole aqui o CNIS em formato JSON"
            />
            <Button
              onClick={() => {
                setParseCnis(JSON.parse(cnisJson));
                setOpenModalCnisJson(false);
              }}
            >
              USAR
            </Button>
          </div>
        </Modal>
      )}
      {openModalConcomitancias && (
        <Modalconcomitancia
          openModalConcomitancias={openModalConcomitancias}
          setOpenModalConcomitancias={setOpenModalConcomitancias}
          fazCalculo={fazCalculo}
          listaConcomitancias={listaConcomitancias}
          estrategiaDoCalculo={estrategiaDoCalculo}
          setEstrategiaDoCalculo={setEstrategiaDoCalculo}
          setParseCnis={setParseCnis}
          parseCnis={parseCnis}
        />
      )}
    </>
  );
}

export default GridAtividades;
