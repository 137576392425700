import { Text } from "@prev/ui-kit";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

const ConcomitanciaResolveVinculoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.concomitanciaSelecionada
      ? props.theme.color.brand.secondary
      : props.theme.color.neutral[300]};
`;

function ConcomitanciaResolveVinculo({ vinculos, setParseCnis, parseCnis }) {
  const [concomitanciaSelecionada, setConcomitanciaSelecionada] = useState("");

  const atualizaConcomitancia = (vinculoSelecionado) => {
    const vinculosAtualizados = parseCnis.vinculos.map((vinculo) => {
      const achouVinculoNaListaAtual = vinculos.find(
        (vinc) => vinc.key === vinculo.key
      );
      if (achouVinculoNaListaAtual) {
        if (vinculoSelecionado.key === vinculo.key) {
          const contribuicoesEntreDatas = vinculoSelecionado.contribuicoes.map(
            (contribuicao) => {
              const dataContribuicao = moment(contribuicao.competencia).startOf(
                `month`
              );
              if (
                dataContribuicao.isBetween(
                  moment(vinculoSelecionado.inicio),
                  moment(vinculoSelecionado.fim).add(1, `day`),
                  null,
                  `[]`
                )
              ) {
                return {
                  ...contribuicao,
                  contribuicaoPrincipal: true,
                  atividadePrincipal: true,
                };
              }
              return {
                ...contribuicao,
              };
            }
          );
          return {
            ...vinculo,
            contribuicoes: contribuicoesEntreDatas,
          };
        } else {
          const contribuicoesEntreDatas = vinculo.contribuicoes.map(
            (contribuicao) => {
              const dataContribuicao = moment(contribuicao.competencia).startOf(
                `month`
              );
              if (
                dataContribuicao.isBetween(
                  moment(vinculoSelecionado.inicio),
                  moment(vinculoSelecionado.fim).add(1, `day`),
                  null,
                  `[]`
                )
              ) {
                return {
                  ...contribuicao,
                  contribuicaoPrincipal: false,
                  atividadePrincipal: false,
                };
              }
              return {
                ...contribuicao,
              };
            }
          );
          return {
            ...vinculo,
            contribuicoes: contribuicoesEntreDatas,
          };
        }
      } else {
        return {
          ...vinculo,
        };
      }
    });

    setParseCnis((prevState) => ({
      ...prevState,
      vinculos: vinculosAtualizados,
    }));
    setConcomitanciaSelecionada(vinculoSelecionado.key);
  };

  return vinculos.map((vinculo) => (
    <ConcomitanciaResolveVinculoContainer
      key={vinculo.key}
      onClick={() => atualizaConcomitancia(vinculo)}
      concomitanciaSelecionada={vinculo.key === concomitanciaSelecionada}
    >
      <Text>{vinculo.nomeDeExibicao}</Text>
      <Text>{`(${moment(vinculo.inicio).format("DD/MM/YYYY")} até ${moment(
        vinculo.fim
      ).format("DD/MM/YYYY")})`}</Text>
    </ConcomitanciaResolveVinculoContainer>
  ));
}

export default ConcomitanciaResolveVinculo;
