import React, { useEffect } from "react";
import styled from "styled-components";
import { Card, Text, Button, SegmentContext } from "@prev/ui-kit";
import { Row, Col } from "antd";
import { FileProtectOutlined, FileSyncOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";
import UploadInicial from "./UploadInicial";
import { useState } from "react";
import { getToken } from "../services/adapter";
import { getUsuario } from "../services/user";
import { getSegmentObject } from "../services/segment";

const HomeContainer = styled.div`
  max-width: 1280px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
`;

const HomeBox = styled.div`
  max-width: 504px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardsContainer = styled.div`
  width: 100%;
  margin-top: 48px;
`;

const IconContainer = styled.div`
  height: 48px;
  width: 48px;
  background-color: ${(props) => props.theme.color.light.grayish.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  & .anticon-file-sync > svg,
  & .anticon-file-protect > svg {
    color: ${(props) => props.theme.color.brand.secondary};
    font-size: 26px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

function Home() {
  const {
    setTipoFluxo,
    tipoFluxo,
    setParseCnis,
    setParseComunicado,
    setBeneficio,
    setParseCartaDeConcessao,
    setInformacoesComplementares,
    setArquivoCnis,
    setArquivoComunicado,
    setArquivoCartaConcessao,
  } = useContext(FluxoBeneficioContext);
  const [render, setRender] = useState(false);
  const { analytics, identified } = useContext(SegmentContext);

  useEffect(() => {
    localStorage.removeItem("fluxo");
    setTipoFluxo();
    setInformacoesComplementares();
    setParseCartaDeConcessao();
    setParseCnis();
    setParseComunicado();
    setBeneficio();
    setArquivoCnis([])
    setArquivoComunicado([])
    setArquivoCartaConcessao([])
    setRender(true);
  }, []);

  useEffect(() => {
    if (analytics && identified) {
      analytics.track("Entrou no fluxo de benefício por incapacidade");
    }
  }, [identified, analytics]);

  if (!getToken()) {
    return (window.location.href = "/");
  }

  if (!render) return null;

  if (tipoFluxo) return <UploadInicial />;

  return (
    <HomeContainer>
      <HomeBox>
        <Text size="xl" emphasys>
          Para prosseguir escolha o tipo do cálculo de incapacidade que deseja
        </Text>
        <CardsContainer>
          <Row gutter={48}>
            <Col span={12}>
              <Card style={{ borderRadius: 4 }}>
                <CardContainer>
                  <IconContainer>
                    <FileProtectOutlined />
                  </IconContainer>
                  <Text emphasys>Concessão</Text>
                  <Button
                    onClick={() => setTipoFluxo("CONCESSAO")}
                    data-testid="button-iniciar-concessao"
                    id="button-iniciar-concessao"
                  >
                    Iniciar
                  </Button>
                </CardContainer>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ borderRadius: 4 }}>
                <CardContainer>
                  <IconContainer>
                    <FileSyncOutlined />
                  </IconContainer>
                  <Text emphasys>Restabelecimento</Text>
                  <Button
                    onClick={() => setTipoFluxo("RESTABELECIMENTO")}
                    data-testid="button-iniciar-restabelecimento"
                    id="button-iniciar-restabelecimento"
                  >
                    Iniciar
                  </Button>
                </CardContainer>
              </Card>
            </Col>
          </Row>
        </CardsContainer>
      </HomeBox>
    </HomeContainer>
  );
}

export default Home;
