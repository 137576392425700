import React, { useContext, useState } from "react";
import { Ajuda, Button, Modal, PrevPlatformContext, Text } from "@prev/ui-kit";
import { textosAjuda } from "../../constants";
import moment from "moment";
import ConcomitanciaResolveVinculo from "./ConcomitanciaResolveVinculo";
import { useNavigate } from "react-router-dom";

function Modalconcomitancia({
  openModalConcomitancias,
  setOpenModalConcomitancias,
  fazCalculo,
  listaConcomitancias,
  estrategiaDoCalculo,
  setEstrategiaDoCalculo,
  setParseCnis,
  parseCnis,
}) {
  const { theme } = useContext(PrevPlatformContext);
  const [resolverConcomitancias, setResolverConcomitancias] = useState(false);
  const [indexConcomitanciaAtivo, setIndexConcomitanciaAtivo] = useState(0);
  const navigate = useNavigate();

  const propsButtonNoSelected = {
    style: {
      border: `1px solid ${theme.color.neutral[600]}`,
    },
    color: theme.color.neutral[100],
    textcolor: theme.color.neutral[600],
  };

  const propsButtonSelected = {
    color: "brand-secondary",
  };

  const avancar = async () => {
    if (estrategiaDoCalculo === "SOMAR_CONCOMITANCIA") {
      const response = await fazCalculo();
      setOpenModalConcomitancias(false);
      if (!response.error) navigate("/informacoes-complementares");
    }
    if (estrategiaDoCalculo === "CONFORME_A_LEI") {
      setResolverConcomitancias(true);
    }
  };

  const renderFooterDefaultModal = () => {
    return (
      <div>
        <Button
          color="secondary"
          onClick={() => setOpenModalConcomitancias(false)}
        >
          Cancelar
        </Button>
        <Button disabled={!estrategiaDoCalculo} onClick={() => avancar()}>
          {estrategiaDoCalculo === "CONFORME_A_LEI" ? "Avançar" : "Calcular"}
        </Button>
      </div>
    );
  };

  const renderDefaultModal = (
    <Modal
      visible={openModalConcomitancias}
      title="Configuração concomitância"
      footer={renderFooterDefaultModal()}
      onCancel={() => setOpenModalConcomitancias(false)}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 9 }}>
          <Text>Como calcular atividades concomitantes: </Text>
          <Ajuda
            itens={textosAjuda}
            page="fluxoBeneficioIncapacidade"
            element="comoCalcular"
          />
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            {...(estrategiaDoCalculo === "CONFORME_A_LEI"
              ? propsButtonSelected
              : propsButtonNoSelected)}
            onClick={() => setEstrategiaDoCalculo("CONFORME_A_LEI")}
          >
            De acordo com o INSS
          </Button>
          <Button
            {...(estrategiaDoCalculo === "SOMAR_CONCOMITANCIA"
              ? propsButtonSelected
              : propsButtonNoSelected)}
            onClick={() => setEstrategiaDoCalculo("SOMAR_CONCOMITANCIA")}
          >
            Somar valores concomitantes
          </Button>
        </div>
      </div>
    </Modal>
  );

  const voltarConcomitanciaStep = () => {
    if (indexConcomitanciaAtivo === 0) {
      setResolverConcomitancias(false);
    } else {
      setIndexConcomitanciaAtivo((prevState) => prevState - 1);
    }
  };

  const avancarConcomitanciaStep = async () => {
    if (indexConcomitanciaAtivo < listaConcomitancias.length) {
      setIndexConcomitanciaAtivo((prevState) => prevState + 1);
    } else {
      const response = await fazCalculo();
      setOpenModalConcomitancias(false);
      if (!response.error) navigate("/informacoes-complementares");
    }
  };

  const renderFooterResolverConcomitancias = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={() => setOpenModalConcomitancias(false)}>
          Cancelar
        </Button>
        <div>
          <Button onClick={() => voltarConcomitanciaStep()}>Voltar</Button>
          <Button onClick={() => avancarConcomitanciaStep()}>
            {indexConcomitanciaAtivo === listaConcomitancias.length
              ? "Calcular"
              : "Avançar"}
          </Button>
        </div>
      </div>
    );
  };

  const renderTitleResolverConcomitancias = () => {
    if (indexConcomitanciaAtivo === listaConcomitancias.length) {
      return "Pronto!";
    } else {
      return `Foram encontrados períodos concomitantes: ${
        indexConcomitanciaAtivo + 1
      } de ${listaConcomitancias.length}`;
    }
  };

  const renderEscolhaConcomitancia = (concomitancia) => {
    return (
      <div>
        <Text type="span">
          {`Selecione a atividade principal para o período de: `}{" "}
        </Text>
        <Text type="span" emphasys>
          {moment(concomitancia.inicio).format("DD/MM/Y")}{" "}
        </Text>
        <Text type="span">até </Text>
        <Text type="span" emphasys>
          {moment(concomitancia.fim).format("DD/MM/Y")}
        </Text>
        <div
          style={{
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <ConcomitanciaResolveVinculo
            vinculos={concomitancia.vinculos}
            setParseCnis={setParseCnis}
            parseCnis={parseCnis}
          />
        </div>
      </div>
    );
  };

  const renderStepsConcomitancias = () => {
    if (indexConcomitanciaAtivo < listaConcomitancias.length) {
      return (
        <div>
          {listaConcomitancias.map((concomitancia, index) => {
            return (
              index === indexConcomitanciaAtivo && (
                <div key={concomitancia + index}>
                  {renderEscolhaConcomitancia(concomitancia, index)}
                </div>
              )
            );
          })}
        </div>
      );
    } else {
      return <Text>Você terminou de configurar a concomitância.</Text>;
    }
  };

  const renderModalResolverConcomitancias = (
    <Modal
      width={800}
      visible={openModalConcomitancias}
      title={renderTitleResolverConcomitancias()}
      footer={renderFooterResolverConcomitancias()}
      onCancel={() => setOpenModalConcomitancias(false)}
    >
      {renderStepsConcomitancias()}
    </Modal>
  );

  return resolverConcomitancias
    ? renderModalResolverConcomitancias
    : renderDefaultModal;
}

export default Modalconcomitancia;
