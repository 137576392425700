import React, { useEffect, useContext } from "react";
import { DocumentsSent } from "../../components/scanner/DocumentsSent";
import InitialScanner from "../../components/scanner/InitialScanner";
import { ReadUploads } from "../../components/scanner/ReadUploads";
import Success from "../../components/scanner/Success";
import WebcamCapture from "../../components/scanner/WebCamCapture";
import { ScannerContext } from "../../context/ScannerProvider";
import { useNavigate } from "react-router-dom";
import ErrorMobile from "../../components/scanner/Error";
import ExpiredMobile from "../ExpiredMobile";
import { message } from 'antd';
import useWebSocket from 'react-use-websocket'
import { useRef } from "react";

const { REACT_APP_PREV_SOCKET } = process.env

const Scanner = () => {
  const { activeStep, infoUserQrcode, getArquivos, socketMessage, setSocketMessage, setSocketMobileLoading } = useContext(ScannerContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!infoUserQrcode) {
      navigate("/");
    }
  }, [infoUserQrcode]);
  
  useEffect(() => {
    const viewport_meta = document.getElementById("viewport-meta");
    if (viewport_meta) {
      viewport_meta.setAttribute("content", "width=device-width");
    }
  }, []);

  const didUnmount = useRef(false);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(`${REACT_APP_PREV_SOCKET}?token=${infoUserQrcode?.uuidBeneficio}`,
  {
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 20,
    reconnectInterval: 2500,
    retryOnError: true,
    onReconnectStop: () => {
      message.error("Encontramos problemas com a sincronização dos dados, verifique sua conexão!");
    }
  })

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);
  
  useEffect(() => {
    handleSocketMessage()
  }, [lastJsonMessage])

  const handleSocketMessage = async () => {
    if(lastJsonMessage && lastJsonMessage.evento) {
      if(lastJsonMessage.evento === 'NOVO_DOCUMENTO_DESKTOP') {
        setSocketMobileLoading(prevState => ({
          ...prevState,
          [lastJsonMessage.payload.tipoDocumento]: true
        }))
        await getArquivos();
        message.success(`Arquivo ${lastJsonMessage.payload.nomeArquivo} adicionado com sucesso!`);
        setSocketMobileLoading(prevState => ({
          ...prevState,
          [lastJsonMessage.payload.tipoDocumento]: false
        }))
      }
    if(lastJsonMessage.evento === 'DELETOU_DOCUMENTO_DESKTOP') {
      setSocketMobileLoading(prevState => ({
        ...prevState,
        [lastJsonMessage.payload.tipoDocumento]: true
      }))
      await getArquivos();
      message.success(`Arquivo ${lastJsonMessage.payload.nomeArquivo} deletado com sucesso!`);
      setSocketMobileLoading(prevState => ({
        ...prevState,
        [lastJsonMessage.payload.tipoDocumento]: false
      }))
    }
  }
}

  useEffect(() => {
    if(socketMessage) {
      sendJsonMessage(socketMessage);
      setSocketMessage(null);
    }
  }, [socketMessage])

  return (
    <div>
      {[
        <InitialScanner key={1} />,
        <ReadUploads key={2} />,
        <WebcamCapture key={3} />,
        <Success key={4} />,
        <DocumentsSent key={5} />,
        <ErrorMobile key={6} />,
        <ExpiredMobile key={7} />,
      ][activeStep]}
    </div>
  );
};

export default Scanner;
