import React, { useEffect, useState } from "react";
import CheckInQRCode from "../../components/scanner/CheckInQRCode";
import { QrCode } from "../../components/scanner/QrCode";

export const QrCodeReading = () => {

  useEffect(() => {
    const viewport_meta = document.getElementById("viewport-meta");
    if (viewport_meta) {
      viewport_meta.setAttribute("content", "width=device-width");
    }
  }, []);

  const [openQrCode, setOpenQrCode] = useState(false);

  return (
    <>
      <CheckInQRCode setOpenQrCode={setOpenQrCode} />
      <QrCode openQrCode={openQrCode} setOpenQrCode={setOpenQrCode} />
    </>
  );
};
