import React, { useEffect, useState, useContext, useRef } from "react";
import { Button as ButtonUiKit, Text as TextUiKit } from "@prev/ui-kit";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ScannerContext } from "../../context/ScannerProvider";
import { Row } from "antd";
import { parseTextType } from "../../constants";

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Button = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 5px solid;
  border-color: ${({ theme }) => theme.color.brand.primary};
  background-color: ${({ theme }) => theme.color.neutral[100]};
  margin-bottom: 6rem;
`;

const HeaderStatus = styled.div`
  width: 100%;
  height: 4rem;
  background-color: ${({ theme }) => theme.color.brand.primary};
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 99999999999999999999;
  row-gap: 20px;
  column-gap: 20px;
  text-align: center;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.color.neutral[100]};
  text-align: center;
  margin-top: 1rem;
`;

const ContainerUploadImage = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 999999999;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 2rem;
  gap: 20px;
  position: absolute;

  .UploadFile__FileContainer-sc-zbyto2-2 {
    display: none !important;
  }
`;

const ContainerPreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100vh;
  padding: 1rem;
`;

const WebcamCapture = () => {
  const {
    selectedType,
    setActiveStep,
    enviarArquivo,
    findMissingGroup,
    setSelectedType,
  } = useContext(ScannerContext);
  const [preview, setPreview] = useState("");
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const stripRef = useRef(null);

  useEffect(() => {
    getVideo();
  }, [preview]);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "environment",
          frameRate: {
            min: 24,
            max: 60,
            ideal: 60,
          },
          height: {
            ideal: 720,
          },
        },
        audio: false,
      })
      .then((stream) => {
        const videoTrack = stream.getVideoTracks()[0];
        videoTrack.applyConstraints({
          advanced: [
            {
              focusMode: "auto",
            },
          ],
        });
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");

    const width = video.videoWidth;
    const height = video.videoHeight;
    photo.width = width;
    photo.height = height;

    return setInterval(() => {
      ctx.drawImage(video, 0, 0, width, height);
    }, 200);
  };

  const takePhoto = () => {
    let photo = photoRef.current;
    let strip = stripRef.current;

    console.warn(strip);

    const data = photo.toDataURL("image/jpeg", "0.8");
    setPreview(data);
  };

  return (
    <Container>
      <HeaderStatus>
        <ArrowLeftOutlined
          onClick={() => {
            setPreview("");
            setSelectedType("");
            setActiveStep(0);
          }}
          style={{ fontSize: "20px", color: "white", width: "50px" }}
        />
        <Text style={{ color: "white", width: "100%", marginRight: "50px" }}>
          {parseTextType[selectedType]}
        </Text>
      </HeaderStatus>

      {preview ? (
        <ContainerPreview>
          {preview.split(";")[0].slice(-3) === "pdf" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextUiKit size="xl-emphasys">
                Documento PDF selecionado!
              </TextUiKit>
              <TextUiKit size="base-emphasys">
                Clique para selecionar outro documento!
              </TextUiKit>
            </div>
          ) : (
            <img
              style={{ width: "55%"}}
              src={preview}
              alt="pré-vizualização"
            />
          )}
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              columnGap: "20px",
              rowGap: "20px",
            }}
          >
            <ButtonUiKit
              style={{ width: "200px" }}
              size="large"
              color="primary"
              aria-label="Ação do botão"
              onClick={async () => {
                if (preview) {
                  return enviarArquivo(preview);
                }
                findMissingGroup();
              }}
            >
              Enviar
            </ButtonUiKit>
            <ButtonUiKit
              style={{ width: "200px" }}
              size="large"
              aria-label="Action Button"
              id="button-tirar-foto-novamnete"
              color="secondary"
              data-testid="button-tirar-foto-novamnete"
              onClick={() => setPreview("")}
            >
              Refazer digitalização
            </ButtonUiKit>
          </Row>
        </ContainerPreview>
      ) : (
        <>
          <video
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "50%",
              marginLeft: "-50%",
              objectFit: "cover",
              objectPosition: "center",
            }}
            onCanPlay={paintToCanvas}
            ref={videoRef}
            muted
            playsInline
          />
          <canvas ref={photoRef} style={{ display: "none" }} />
          <div>
            <div ref={stripRef} />
          </div>
        </>
      )}

      {!preview && (
        <ContainerUploadImage>
          <Button
            onClick={takePhoto}
            style={{
              width: "75px",
              height: "75px",
            }}
          ></Button>
        </ContainerUploadImage>
      )}
    </Container>
  );
};

export default WebcamCapture;
