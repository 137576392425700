import React from "react";
import { Button } from "@prev/ui-kit";
import { Row, Col } from "antd";
import Logo from "../images/logo-prev.png";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #566274;
  padding: 0 40px;
`;

function Header() {
  const {
    setParseCnis,
    setParseComunicado,
    setParseCartaDeConcessao,
    setBeneficio,
    setResultadoComunicado,
    setTipoFluxo,
    tipoFluxo,
    currentItem,
    setCurrentItem,
    setFilesPdf,
    setArquivoCnis,
    setArquivoComunicado,
    setArquivoCartaConcessao,
  } = useContext(FluxoBeneficioContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const regrasRedirect = () => {
    if (pathname === "/") {
      if (tipoFluxo) return setTipoFluxo();
      return (location.href = "/");
    }
    if (pathname === "/calculo") {
      if (currentItem) return setCurrentItem();
      setArquivoCnis([]);
      setArquivoComunicado([]);
      setParseCnis();
      setParseComunicado();
      setTipoFluxo();
      return navigate("/");
    }
    if (pathname === "/informacoes-complementares") {
      setParseCartaDeConcessao();
      if (tipoFluxo === "RESTABELECIMENTO") {
        setTipoFluxo();
        setArquivoCartaConcessao([]);
      }
      return navigate(tipoFluxo === "CONCESSAO" ? "/calculo" : "/");
    }
    if (pathname === "/editor") {
      setResultadoComunicado();
      setBeneficio();
      navigate("/informacoes-complementares");
    }
    if (pathname === "/upload-files") {
      navigate("/editor");
    }
  };

  return (
    <HeaderContainer>
      <div style={{ maxWidth: 1280, margin: "auto" }}>
        <Row align="middle" style={{ height: 64 }}>
          <Col span={10}>
            <div style={{ paddingLeft: "30px" }}>
              {!(pathname === "/conclusao" || pathname === "/error") && (
                <Button
                  data-testid="button-voltar-header"
                  id="button-voltar-header"
                  textcolor="#FFFFFF"
                  variant="link"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => regrasRedirect()}
                >
                  Voltar
                </Button>
              )}
            </div>
          </Col>
          <Col span={14}>
            <img
              width={220}
              height={35}
              src={Logo}
              alt="logo previdenciarista"
            />
          </Col>
        </Row>
      </div>
    </HeaderContainer>
  );
}

export default Header;
