import React, { useContext, useEffect, useRef, useState } from "react";
import { Badge, Col, Row, Spin, Tooltip, message } from "antd";
import styled from "styled-components";
import {
  PrevPlatformContext,
  Text,
  Steps,
  UploadFile,
  Button,
  getBase64,
  SegmentContext,
} from "@prev/ui-kit";
import {
  deleteArquivo,
  downloadArquivo,
  listarArquivosDoBeneficio,
  obterListaArquivosPdf,
  obterQrCode,
  uploadFile,
  verificaCartaExistenteEDeleta,
} from "../services/uploadFilesService";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";
import { useNavigate } from "react-router-dom";
import filesSaver from "../utils/filesSaver";
import { LoadingOutlined } from "@ant-design/icons";
import { cpfMask } from "../utils/masks/maskCpf";
import moment from "moment";
import { getTipoBeneficioDesc } from "../utils/tipoBeneficioDescricao";
import { toLocaleStringBR } from "../utils/toLocaleStringBR";
import ReactDOMServer from "react-dom/server";
import RelatorioValorCausa from "../components/RelatorioValorCausa/RelatorioValorCausa";
import { generatePdfPeticao } from "../services/PeticaoService";
import useWebSocket from "react-use-websocket";

const { REACT_APP_PREV_SOCKET } = process.env;

const Footer = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  bottom: 0;
  box-shadow: 0px 1px 15px rgba(99, 99, 99, 0.2);
  background-color: white;
`;

const BodyContainer = styled.div`
  margin: 32px auto;
  max-width: 1280px;
  .ant-steps-item-content {
    word-break: break-word;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    max-width: 350px;
  }
  padding: 0px 16px;
`;

const QrCodeCard = styled.div`
  min-height: 272px;
  width: 100%;
  box-shadow: 0px 1px 15px rgba(99, 99, 99, 0.2);
  position: sticky;
  top: 120px;
  padding: 17px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  margin-bottom: 32px;
`;

const FooterForm = styled.div`
  height: 64px;
  display: flex;
  gap: 12px 24px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;
  position: fixed;
  bottom: 0;
  z-index: 11;
`;

const ENUM_SERVICE_UPLOAD_FILES = {
  DOCUMENTO_PESSOAL_NAO_CATEGORIZADO: "Documentos pessoais",
  COMPROVANTE_DE_RESIDENCIA: "Comprovante de residência",
  PROCURACAO: "Procuração",
  DOCUMENTO_MEDICO_NAO_CATEGORIZADO: "Documento médico",
  DECLARACAO_DE_HIPOSUFICIENCIA: "Declaração de hiposuficiencia",
  CARTA_DE_INDEFERIMENTO: "Carta de indeferimento",
  CARTA_DE_CONCESSAO: "Carta de concessão",
  DOCUMENTOS_COMPLEMENTARES: "Documentos complementares",
};

function UploadFilesPage() {
  const { theme } = useContext(PrevPlatformContext);
  const {
    beneficio,
    setFilesPdf,
    tipoFluxo,
    parseCnis,
    parseCartaDeConcessao,
    loading,
    loaded,
    filesPdf,
    parseComunicado,
    parsePeticao,
    informacoesComplementares,
  } = useContext(FluxoBeneficioContext);
  const { analytics, identified } = useContext(SegmentContext);
  const [dadosSeguradoAtualizado, setDadosSeguradoAtualizado] = useState();
  const [documentosPessoais, setDocumentosPessoais] = useState([]);
  const [residencia, setResidencia] = useState([]);
  const [procuracao, setProcuracao] = useState([]);
  const [hiposuficiencia, setHiposuficiencia] = useState([]);
  const [indeferimento, setIndeferimento] = useState([]);
  const [cartaConcessao, setCartaConcessao] = useState([]);
  const [medicos, setMedicos] = useState([]);
  const [complementares, setComplementares] = useState([]);
  const [documentosPendentes, setDocumentosPendentes] = useState();
  const [qrCode, setQrCode] = useState();
  const [socketLoading, setSocketLoading] = useState({
    DOCUMENTO_PESSOAL_NAO_CATEGORIZADO: false,
    COMPROVANTE_DE_RESIDENCIA: false,
    PROCURACAO: false,
    DOCUMENTO_MEDICO_NAO_CATEGORIZADO: false,
    DECLARACAO_DE_HIPOSUFICIENCIA: false,
    CARTA_DE_INDEFERIMENTO: false,
    DOCUMENTOS_COMPLEMENTARES: false,
    CARTA_DE_CONCESSAO: false,
  });
  const [relatorioValorCausaString, setRelatorioValorCausaString] = useState();

  const didUnmount = useRef(false);

  const { sendJsonMessage, lastJsonMessage, getWebSocket } = useWebSocket(
    `${REACT_APP_PREV_SOCKET}?token=${beneficio.uuid}`,
    {
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false;
      },
      reconnectAttempts: 20,
      reconnectInterval: 2500,
      retryOnError: true,
      onReconnectStop: () => {
        message.error(
          "Encontramos problemas com a sincronização dos dados, verifique sua conexão!"
        );
      },
    }
  );

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const navigate = useNavigate();

  const resolveQrCode = async () => {
    const response = await obterQrCode({
      cpf: beneficio.cliente.cpf,
      uuidBeneficio: beneficio.uuid,
    });
    setQrCode(response);
  };

  const trazListaArquivosEmPdf = async () => {
    try {
      loading({ customTitle: "Aguarde, estamos processando seus arquivos" });
      const response = await obterListaArquivosPdf({
        cpf: dadosSeguradoAtualizado.cpf,
        uuidBeneficio: beneficio.uuid,
      });
      if (response) {
        loaded();
        setFilesPdf(response);
      }
    } catch (err) {
      console.log(err);
      loaded();
      navigate("/error");
    }
  };

  const fileTypes = {
    DOCUMENTO_PESSOAL_NAO_CATEGORIZADO: [
      documentosPessoais,
      setDocumentosPessoais,
    ],
    COMPROVANTE_DE_RESIDENCIA: [residencia, setResidencia],
    PROCURACAO: [procuracao, setProcuracao],
    DOCUMENTO_MEDICO_NAO_CATEGORIZADO: [medicos, setMedicos],
    DECLARACAO_DE_HIPOSUFICIENCIA: [hiposuficiencia, setHiposuficiencia],
    CARTA_DE_INDEFERIMENTO: [indeferimento, setIndeferimento],
    CARTA_DE_CONCESSAO: [cartaConcessao, setCartaConcessao],
    DOCUMENTOS_COMPLEMENTARES: [complementares, setComplementares],
  };

  const listarArquivos = async (param = "") => {
    const arquivosDoBeneficio = await listarArquivosDoBeneficio({
      cpf: beneficio.cliente.cpf,
      uuidBeneficio: beneficio.uuid,
    });
    if (!arquivosDoBeneficio) {
      if (param) fileTypes[param][1]([]);
      setSocketLoading((prev) => ({
        ...prev,
        [param]: false,
      }));
      return;
    }
    const novosArquivos = param
      ? arquivosDoBeneficio.filter(
          (arquivo) => arquivo.tipoArquivoDocumento === param
        )
      : arquivosDoBeneficio;
    if (param) fileTypes[param][1]([]);
    if (novosArquivos) {
      novosArquivos.forEach((arquivo) => {
        if (!fileTypes[arquivo.tipoArquivoDocumento]) return;
        fileTypes[arquivo.tipoArquivoDocumento][1]((prevState) => {
          if (prevState.find((e) => e.uid === arquivo.id)) return prevState;
          return [
            ...prevState,
            {
              file: { name: arquivo.nome, uid: arquivo.id },
              success: true,
              error: false,
              startedUpload: true,
              progress: 100,
              uid: arquivo.id,
            },
          ];
        });
      });
    }
  };

  const atualizaDocumento = ({
    file,
    documentos,
    progress,
    setDocumentos,
    status,
    uuid,
    errorResponse = "",
  }) => {
    const findDocumento = documentos.find(
      (documentoSelecionado) => documentoSelecionado.file.uid === file.uid
    );
    const removeDocumentoAntigo = documentos.filter(
      (documentoSelecionado) => documentoSelecionado.file.uid !== file.uid
    );

    if (uuid) findDocumento.file.uid = uuid;

    if (progress) findDocumento.progress = progress;
    if (status) {
      findDocumento[status] = true;
      findDocumento.progress = 100;
      if (status === "error") {
        findDocumento.errorResponse = errorResponse;
      }
    }
    const documentosAtualizados = [...removeDocumentoAntigo, findDocumento]
      .map((novoDocumento) => ({
        ...novoDocumento,
        startedUpload: true,
      }))
      .sort((a, b) => a.file.name - b.file.name);
    setDocumentos(documentosAtualizados);
  };

  const resolveDocumento = async (fileType, file) => {
    setDocumentosPendentes((prevState) => ({
      ...prevState,
      [fileType]: false,
    }));
    if (file.startedUpload) return;

    await uploadFile(
      {
        file: file.file,
        tipoDocumento: fileType,
        cpf: beneficio.cliente.cpf,
        uuidBeneficio: beneficio.uuid,
      },
      (progress) => {
        atualizaDocumento({
          file,
          progress,
          setDocumentos: fileTypes[fileType][1],
          documentos: fileTypes[fileType][0],
        });
      }
    )
      .then((response) => {
        atualizaDocumento({
          file,
          setDocumentos: fileTypes[fileType][1],
          documentos: fileTypes[fileType][0],
          status: "success",
          uuid: response.id,
        });

        if (analytics && identified) {
          analytics.track(
            "Enviou um arquivo com sucesso pelo computador no fluxo de benefício por incapacidade"
          );
        }
      })
      .catch((e) => {
        atualizaDocumento({
          file,
          setDocumentos: fileTypes[fileType][1],
          documentos: fileTypes[fileType][0],
          uuid: file.uid,
          status: "error",
          errorResponse: e?.response?.data?.error_description,
        });
      });
    const socketMessage = {
      evento: "NOVO_DOCUMENTO_DESKTOP",
      payload: {
        tipoDocumento: fileType,
        nomeArquivo: file.file.name,
      },
    };
    sendJsonMessage(socketMessage);
  };

  const resolveStepStatus = (files) => {
    const filesSomeError = files.some((file) => file.error === true);

    if (!files) return "wait";

    if (files.length === 0) {
      return "process";
    }

    if (files.length > 0 && filesSomeError) {
      return "error";
    }

    if (files.length > 0 && !filesSomeError) {
      return "finish";
    }
  };

  const realizaDownloaIndividual = async (uuidArquivo, fileName) => {
    const response = await downloadArquivo(uuidArquivo);
    filesSaver(response, fileName);
  };

  const previewFile = (file) => {
    // CONDICAO PARA ARQUIVO RECEM UPADO
    if (file.type && file.size && file.lastModified) {
      return getBase64(file);
    } else {
      // CASO TENHA QUE FAZER DOWNLOAD DO BACK-END
      return realizaDownloaIndividual(file.uid, file.name);
    }
  };

  const preparaRelatorioValorCausaConcessao = () => {
    if (
      parseCnis &&
      parsePeticao &&
      parseComunicado &&
      informacoesComplementares
    ) {
      const nome = parseCnis.segurado.nome;
      const cpf = cpfMask(parseCnis.segurado.cpf);
      const dataCalculo = moment(parsePeticao.dataDeCriacao).format(
        "DD/MM/YYYY"
      );
      const nomeDoBeneficio = getTipoBeneficioDesc(
        parseComunicado.tipoBeneficio
      );
      const dataInicioBeneficio = moment(
        informacoesComplementares.dataRequerimentoDoBeneficio
      ).format("DD/MM/YYYY");
      const dataFimAtrasado = moment(
        informacoesComplementares.cessacaoDoBeneficio
      ).format("DD/MM/YYYY");
      const rmi = toLocaleStringBR(parsePeticao.rma.valorRmi);
      const rma = toLocaleStringBR(parsePeticao.rma.valorRmaAtual);
      const listaIndices = parsePeticao.rma.indices;
      const vencidas = toLocaleStringBR(parsePeticao.rma.vencidas);
      const vincendas = toLocaleStringBR(parsePeticao.rma.vincendas);
      const valorTotalCausa = toLocaleStringBR(parsePeticao.rma.valorCausa);

      const componentString = ReactDOMServer.renderToString(
        <RelatorioValorCausa
          nome={nome}
          cpf={cpf}
          dataCalculo={dataCalculo}
          nomeDoBeneficio={nomeDoBeneficio}
          dataInicioBeneficio={dataInicioBeneficio}
          dataFimAtrasado={dataFimAtrasado}
          rmi={rmi}
          rma={rma}
          listaIndices={listaIndices}
          vencidas={vencidas}
          vincendas={vincendas}
          valorTotalCausa={valorTotalCausa}
        />
      );
      setRelatorioValorCausaString(componentString);
    }
  };

  const preparaRelatorioValorCausaRestabelecimento = () => {
    if (parseCartaDeConcessao && parsePeticao && informacoesComplementares) {
      const nome = parseCartaDeConcessao.cliente.nome;
      const cpf = cpfMask(informacoesComplementares.cpf);
      const dataCalculo = moment(parsePeticao.dataDeCriacao).format(
        "DD/MM/YYYY"
      );
      const nomeDoBeneficio = getTipoBeneficioDesc(
        parseCartaDeConcessao.tipoBeneficio
      );
      const dataInicioBeneficio = moment(
        parseCartaDeConcessao.dataInicioDoBeneficio
      ).format("DD/MM/YYYY");
      const dataFimAtrasado = moment(
        informacoesComplementares.cessacaoDoBeneficio
      ).format("DD/MM/YYYY");
      const rmi = toLocaleStringBR(parsePeticao.rma.valorRmi);
      const rma = toLocaleStringBR(parsePeticao.rma.valorRmaAtual);
      const listaIndices = parsePeticao.rma.indices;
      const vencidas = toLocaleStringBR(parsePeticao.rma.vencidas);
      const vincendas = toLocaleStringBR(parsePeticao.rma.vincendas);
      const valorTotalCausa = toLocaleStringBR(parsePeticao.rma.valorCausa);

      const componentString = ReactDOMServer.renderToString(
        <RelatorioValorCausa
          cpf={cpf}
          dataCalculo={dataCalculo}
          dataFimAtrasado={dataFimAtrasado}
          dataInicioBeneficio={dataInicioBeneficio}
          listaIndices={listaIndices}
          nome={nome}
          nomeDoBeneficio={nomeDoBeneficio}
          rma={rma}
          rmi={rmi}
          valorTotalCausa={valorTotalCausa}
          vencidas={vencidas}
          vincendas={vincendas}
        />
      );
      setRelatorioValorCausaString(componentString);
    }
  };

  useEffect(() => {
    if (tipoFluxo === "CONCESSAO") {
      preparaRelatorioValorCausaConcessao();
    }

    if (tipoFluxo === "RESTABELECIMENTO") {
      preparaRelatorioValorCausaRestabelecimento();
    }
  }, [
    parseCnis,
    parsePeticao,
    parseComunicado,
    informacoesComplementares,
    tipoFluxo,
  ]);

  const enviaRelatorioValorCausa = async () => {
    const payLoad = {
      params: {
        footerRight: "[page]/[toPage]",
        footerFontSize: 6,
        "margin.left": "0.5cm",
        "margin.right": "0.5cm",
        "margin.top": "0.5cm",
        "margin.bottom": "0.5cm",
      },
      html: relatorioValorCausaString,
    };

    await verificaCartaExistenteEDeleta({
      cpf: beneficio.cliente.cpf,
      tipoArquivoDocumento: "PDF_RELATORIO_VALOR_CAUSA",
      uuidBeneficio: beneficio.uuid,
    });

    const response = await generatePdfPeticao(payLoad);
    const file = new File(
      [response],
      `relatorio_valorcausa_${beneficio.numeroDoBeneficio}.pdf`,
      { type: "pdf" }
    );
    await uploadFile({
      file: file,
      tipoDocumento: "PDF_RELATORIO_VALOR_CAUSA",
      cpf: beneficio.cliente.cpf,
      uuidBeneficio: beneficio.uuid,
    });
  };

  useEffect(() => {
    if (relatorioValorCausaString) {
      enviaRelatorioValorCausa();
    }
  }, [relatorioValorCausaString, beneficio]);

  useEffect(() => {
    handleSocketMessage(0);
  }, [lastJsonMessage]);

  const handleSocketMessage = async () => {
    if (lastJsonMessage && lastJsonMessage.evento) {
      if (lastJsonMessage.evento === "NOVO_DOCUMENTO_MOBILE") {
        setSocketLoading({
          ...socketLoading,
          [lastJsonMessage.payload.tipoDocumento]: true,
        });
        await listarArquivos(lastJsonMessage.payload.tipoDocumento);
        message.success(
          `Arquivo ${lastJsonMessage.payload.nomeArquivo} recebido com sucesso!`
        );
        setSocketLoading((prev) => ({
          ...prev,
          [lastJsonMessage.payload.tipoDocumento]: false,
        }));
      }
      if (lastJsonMessage.evento === "DELETOU_DOCUMENTO_MOBILE") {
        setSocketLoading({
          ...socketLoading,
          [lastJsonMessage.payload.tipoDocumento]: true,
        });
        await listarArquivos(lastJsonMessage.payload.tipoDocumento);
        message.success(
          `Arquivo ${lastJsonMessage.payload.nomeArquivo} deletado com sucesso!`
        );
        setSocketLoading((prev) => ({
          ...prev,
          [lastJsonMessage.payload.tipoDocumento]: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (tipoFluxo === "CONCESSAO")
      setDadosSeguradoAtualizado(parseCnis.segurado);
    if (tipoFluxo === "RESTABELECIMENTO")
      setDadosSeguradoAtualizado(parseCartaDeConcessao.cliente);

    setFilesPdf(undefined);
    resolveQrCode();
    listarArquivos();
  }, []);

  useEffect(() => {
    if (analytics && identified) {
      analytics.track(
        "Entrou no envio de arquivos pelo computador no fluxo de benefício por incapacidade"
      );
    }
  }, [analytics, identified]);

  useEffect(() => {
    documentosPessoais.forEach((file) =>
      resolveDocumento("DOCUMENTO_PESSOAL_NAO_CATEGORIZADO", file)
    );
  }, [documentosPessoais]);

  useEffect(() => {
    residencia.forEach((file) =>
      resolveDocumento("COMPROVANTE_DE_RESIDENCIA", file)
    );
  }, [residencia]);

  useEffect(() => {
    procuracao.forEach((file) => resolveDocumento("PROCURACAO", file));
  }, [procuracao]);

  useEffect(() => {
    hiposuficiencia.forEach((file) =>
      resolveDocumento("DECLARACAO_DE_HIPOSUFICIENCIA", file)
    );
  }, [hiposuficiencia]);

  useEffect(() => {
    indeferimento.forEach((file) =>
      resolveDocumento("CARTA_DE_INDEFERIMENTO", file)
    );
  }, [indeferimento]);

  useEffect(() => {
    medicos.forEach((file) =>
      resolveDocumento("DOCUMENTO_MEDICO_NAO_CATEGORIZADO", file)
    );
  }, [medicos]);

  useEffect(() => {
    complementares.forEach((file) =>
      resolveDocumento("DOCUMENTOS_COMPLEMENTARES", file)
    );
  }, [complementares]);

  useEffect(() => {
    cartaConcessao.forEach((file) =>
      resolveDocumento("CARTA_DE_CONCESSAO", file)
    );
  }, [cartaConcessao]);

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: "#F26526" }} spin />
  );

  useEffect(() => {
    if (!documentosPessoais.some((item) => item.success))
      setDocumentosPendentes((prevState) => ({
        ...prevState,
        DOCUMENTO_PESSOAL_NAO_CATEGORIZADO: true,
      }));
    if (!residencia.some((item) => item.success))
      setDocumentosPendentes((prevState) => ({
        ...prevState,
        COMPROVANTE_DE_RESIDENCIA: true,
      }));
    if (!procuracao.some((item) => item.success))
      setDocumentosPendentes((prevState) => ({
        ...prevState,
        PROCURACAO: true,
      }));
    if (!hiposuficiencia.some((item) => item.success))
      setDocumentosPendentes((prevState) => ({
        ...prevState,
        DECLARACAO_DE_HIPOSUFICIENCIA: true,
      }));
    if (
      tipoFluxo === "CONCESSAO" &&
      !indeferimento.some((item) => item.success)
    )
      setDocumentosPendentes((prevState) => ({
        ...prevState,
        CARTA_DE_INDEFERIMENTO: true,
      }));
    if (
      tipoFluxo === "RESTABELECIMENTO" &&
      !cartaConcessao.some((item) => item.success)
    )
      setDocumentosPendentes((prevState) => ({
        ...prevState,
        CARTA_DE_CONCESSAO: true,
      }));
    if (!medicos.some((item) => item.success))
      setDocumentosPendentes((prevState) => ({
        ...prevState,
        DOCUMENTO_MEDICO_NAO_CATEGORIZADO: true,
      }));
  }, [
    documentosPessoais,
    residencia,
    procuracao,
    hiposuficiencia,
    indeferimento,
    medicos,
    complementares,
    cartaConcessao,
  ]);

  useEffect(() => {
    if (filesPdf?.length) navigate("/conclusao");
  }, [filesPdf]);

  const temDocumentosPendentes = documentosPendentes
    ? Object.values(documentosPendentes).some((item) => item === true)
    : false;

  return (
    <>
      <BodyContainer>
        <TitleContainer>
          <Text size="lg" emphasys>
            Cálculo de benefício por incapacidade
          </Text>
          <Text color={theme.color.neutral[600]}>
            Envie os arquivos necessários para fundamentar a petição inicial.
            Você pode buscar imagens ou PDFs no seu computador, ou pode
            digitalizar os documentos usando seu celular apontando sua câmera
            para o QRCode ao lado ou acessando o link.
          </Text>
        </TitleContainer>
        <div style={{ paddingBottom: 60 }}>
          <Row gutter={[24, 20]}>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              xl={{ span: 17, order: 0 }}
            >
              <Row gutter={[25, 48]}>
                <Col xs={24} md={12}>
                  <Steps
                    steps={[
                      {
                        title: (
                          <Text color={theme.color.neutral[1000]} emphasys>
                            Documento de Identidade e CPF (frente e verso)
                          </Text>
                        ),
                        description: (
                          <Text color={theme.color.neutral[600]}>
                            Faça o upload do documento ou se preferir use o{" "}
                            <Text
                              emphasys
                              color={theme.color.neutral[600]}
                              type="span"
                            >
                              QR code
                            </Text>{" "}
                            para digitalizá-lo pelo seu celular.
                          </Text>
                        ),
                        status: resolveStepStatus(documentosPessoais),
                      },
                    ]}
                    initial={0}
                  />
                  <div style={{ padding: "20px 0px 40px 0px" }}>
                    {!socketLoading.DOCUMENTO_PESSOAL_NAO_CATEGORIZADO ? (
                      <UploadFile
                        files={documentosPessoais}
                        setFiles={setDocumentosPessoais}
                        showProgressBar
                        multiple
                        accept="application/pdf, image/png, image/jpeg"
                        onDelete={async (file) => {
                          const listaArquivosAtualizada =
                            documentosPessoais.filter(
                              (fileSended) => fileSended.file.uid !== file.uid
                            );
                          await deleteArquivo({ uuidArquivo: file.uid });
                          setDocumentosPessoais(listaArquivosAtualizada);
                          const deleteMessage = {
                            evento: "DELETOU_DOCUMENTO_DESKTOP",
                            payload: {
                              nomeArquivo: file.name,
                              tipoDocumento:
                                "DOCUMENTO_PESSOAL_NAO_CATEGORIZADO",
                            },
                          };
                          sendJsonMessage(deleteMessage);
                        }}
                        onPreview={(file) => previewFile(file.file)}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        <Spin indicator={loadingIcon} />
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <Steps
                    steps={[
                      {
                        title: (
                          <Text color={theme.color.neutral[1000]} emphasys>
                            Comprovante de residência
                          </Text>
                        ),
                        description: (
                          <Text color={theme.color.neutral[600]}>
                            Envie um comprovante de residência recente, de até
                            03 meses.
                          </Text>
                        ),
                        status: resolveStepStatus(residencia),
                      },
                    ]}
                    initial={1}
                  />
                  <div style={{ padding: "20px 0px 40px 0px" }}>
                    {!socketLoading.COMPROVANTE_DE_RESIDENCIA ? (
                      <UploadFile
                        files={residencia}
                        setFiles={setResidencia}
                        showProgressBar
                        multiple
                        accept="application/pdf, image/png, image/jpeg"
                        onDelete={async (file) => {
                          const listaArquivosAtualizada = residencia.filter(
                            (fileSended) => fileSended.file.uid !== file.uid
                          );
                          await deleteArquivo({ uuidArquivo: file.uid });
                          setResidencia(listaArquivosAtualizada);
                          const deleteMessage = {
                            evento: "DELETOU_DOCUMENTO_DESKTOP",
                            payload: {
                              tipoDocumento: "COMPROVANTE_DE_RESIDENCIA",
                              nomeArquivo: file.name,
                            },
                          };
                          sendJsonMessage(deleteMessage);
                        }}
                        onPreview={(file) => previewFile(file.file)}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        <Spin indicator={loadingIcon} />
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <Steps
                    steps={[
                      {
                        title: (
                          <Text color={theme.color.neutral[1000]} emphasys>
                            Procuração e contrato de honorários
                          </Text>
                        ),
                        description: (
                          <Text color={theme.color.neutral[600]}>
                            Envie a procuração assinada pelo cliente e, havendo
                            requisição em apartado, o contrato de honorários.
                          </Text>
                        ),
                        status: resolveStepStatus(procuracao),
                      },
                    ]}
                    initial={2}
                  />
                  <div style={{ padding: "20px 0px 40px 0px" }}>
                    {!socketLoading.PROCURACAO ? (
                      <UploadFile
                        files={procuracao}
                        setFiles={setProcuracao}
                        showProgressBar
                        multiple
                        accept="application/pdf, image/png, image/jpeg"
                        onDelete={async (file) => {
                          const listaArquivosAtualizada = procuracao.filter(
                            (fileSended) => fileSended.file.uid !== file.uid
                          );
                          await deleteArquivo({ uuidArquivo: file.uid });
                          setProcuracao(listaArquivosAtualizada);
                          const deleteMessage = {
                            evento: "DELETOU_DOCUMENTO_DESKTOP",
                            payload: {
                              tipoDocumento: "PROCURACAO",
                              nomeArquivo: file.name,
                            },
                          };
                          sendJsonMessage(deleteMessage);
                        }}
                        onPreview={(file) => previewFile(file.file)}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        <Spin indicator={loadingIcon} />
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <Steps
                    steps={[
                      {
                        title: (
                          <Text color={theme.color.neutral[1000]} emphasys>
                            Declaração de hiposuficiência
                          </Text>
                        ),
                        description: (
                          <Text color={theme.color.neutral[600]}>
                            Se pedir Assistência Judiciária Gratuita, envie aqui
                            a declaração de hipossuficiência.{" "}
                            <a
                              href={`/editarPeticaoAdvogadoSemCalculo/35299/${beneficio?.cliente.cpf}`}
                              target="_blank"
                            >
                              Ver modelo
                            </a>
                          </Text>
                        ),
                        status: resolveStepStatus(hiposuficiencia),
                      },
                    ]}
                    initial={3}
                  />
                  <div style={{ padding: "20px 0px 40px 0px" }}>
                    {!socketLoading.DECLARACAO_DE_HIPOSUFICIENCIA ? (
                      <UploadFile
                        files={hiposuficiencia}
                        setFiles={setHiposuficiencia}
                        showProgressBar
                        multiple
                        accept="application/pdf, image/png, image/jpeg"
                        onDelete={async (file) => {
                          const listaArquivosAtualizada =
                            hiposuficiencia.filter(
                              (fileSended) => fileSended.file.uid !== file.uid
                            );
                          await deleteArquivo({ uuidArquivo: file.uid });
                          setHiposuficiencia(listaArquivosAtualizada);
                          const deleteMessage = {
                            evento: "DELETOU_DOCUMENTO_DESKTOP",
                            payload: {
                              tipoDocumento: "DECLARACAO_DE_HIPOSUFICIENCIA",
                              nomeArquivo: file.name,
                            },
                          };
                          sendJsonMessage(deleteMessage);
                        }}
                        onPreview={(file) => previewFile(file.file)}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        <Spin indicator={loadingIcon} />
                      </div>
                    )}
                  </div>
                </Col>
                {tipoFluxo === "CONCESSAO" && (
                  <Col xs={24} md={12}>
                    <Steps
                      steps={[
                        {
                          title: (
                            <Text color={theme.color.neutral[1000]} emphasys>
                              Carta de indeferimento
                            </Text>
                          ),
                          description: (
                            <Text color={theme.color.neutral[600]}>
                              Esse é o comunicado que notifica seu cliente do
                              indeferimento do pedido administrativo.
                            </Text>
                          ),
                          status: resolveStepStatus(indeferimento),
                        },
                      ]}
                      initial={4}
                    />
                    <div style={{ padding: "20px 0px 40px 0px" }}>
                      {!socketLoading.CARTA_DE_INDEFERIMENTO ? (
                        <UploadFile
                          files={indeferimento}
                          setFiles={setIndeferimento}
                          showProgressBar
                          multiple
                          accept="application/pdf, image/png, image/jpeg"
                          onDelete={async (file) => {
                            const listaArquivosAtualizada =
                              indeferimento.filter(
                                (fileSended) => fileSended.file.uid !== file.uid
                              );
                            await deleteArquivo({ uuidArquivo: file.uid });
                            setIndeferimento(listaArquivosAtualizada);
                            const deleteMessage = {
                              evento: "DELETOU_DOCUMENTO_DESKTOP",
                              payload: {
                                tipoDocumento: "CARTA_DE_INDEFERIMENTO",
                                nomeArquivo: file.name,
                              },
                            };
                            sendJsonMessage(deleteMessage);
                          }}
                          onPreview={(file) => previewFile(file.file)}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            height: "100%",
                          }}
                        >
                          <Spin indicator={loadingIcon} />
                        </div>
                      )}
                    </div>
                  </Col>
                )}
                {tipoFluxo === "RESTABELECIMENTO" && (
                  <Col xs={24} md={12}>
                    <Steps
                      steps={[
                        {
                          title: (
                            <Text color={theme.color.neutral[1000]} emphasys>
                              Carta de concessão
                            </Text>
                          ),
                          description: (
                            <Text color={theme.color.neutral[600]}>
                              {/* Esse é o comunicado que notifica seu cliente do
                              indeferimento do pedido administrativo. */}
                            </Text>
                          ),
                          status: resolveStepStatus(cartaConcessao),
                        },
                      ]}
                      initial={4}
                    />
                    <div style={{ padding: "20px 0px 40px 0px" }}>
                      {!socketLoading.CARTA_DE_CONCESSAO ? (
                        <UploadFile
                          files={cartaConcessao}
                          setFiles={setCartaConcessao}
                          showProgressBar
                          multiple
                          accept="application/pdf, image/png, image/jpeg"
                          onDelete={async (file) => {
                            const listaArquivosAtualizada =
                              cartaConcessao.filter(
                                (fileSended) => fileSended.file.uid !== file.uid
                              );
                            await deleteArquivo({ uuidArquivo: file.uid });
                            setCartaConcessao(listaArquivosAtualizada);
                            const deleteMessage = {
                              evento: "DELETOU_DOCUMENTO_DESKTOP",
                              payload: {
                                tipoDocumento: "CARTA_DE_CONCESSAO",
                                nomeArquivo: file.name,
                              },
                            };
                            sendJsonMessage(deleteMessage);
                          }}
                          onPreview={(file) => previewFile(file.file)}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            height: "100%",
                          }}
                        >
                          <Spin indicator={loadingIcon} />
                        </div>
                      )}
                    </div>
                  </Col>
                )}
                <Col xs={24} md={12}>
                  <Steps
                    steps={[
                      {
                        title: (
                          <Text color={theme.color.neutral[1000]} emphasys>
                            Documentos médicos
                          </Text>
                        ),
                        description: (
                          <Text color={theme.color.neutral[600]}>
                            Envie atestados e laudos médicos com CID10 que
                            comprovem a incapacidade do seu cliente.
                          </Text>
                        ),
                        status: resolveStepStatus(medicos),
                      },
                    ]}
                    initial={5}
                  />
                  <div style={{ padding: "20px 0px 40px 0px" }}>
                    {!socketLoading.DOCUMENTO_MEDICO_NAO_CATEGORIZADO ? (
                      <UploadFile
                        files={medicos}
                        setFiles={setMedicos}
                        showProgressBar
                        multiple
                        accept="application/pdf, image/png, image/jpeg"
                        onDelete={async (file) => {
                          const listaArquivosAtualizada = medicos.filter(
                            (fileSended) => fileSended.file.uid !== file.uid
                          );
                          await deleteArquivo({ uuidArquivo: file.uid });
                          setMedicos(listaArquivosAtualizada);
                          const deleteMessage = {
                            evento: "DELETOU_DOCUMENTO_DESKTOP",
                            payload: {
                              tipoDocumento:
                                "DOCUMENTO_MEDICO_NAO_CATEGORIZADO",
                              nomeArquivo: file.name,
                            },
                          };
                          sendJsonMessage(deleteMessage);
                        }}
                        onPreview={(file) => previewFile(file.file)}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        <Spin indicator={loadingIcon} />
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <Steps
                    steps={[
                      {
                        title: (
                          <Text color={theme.color.neutral[1000]} emphasys>
                            Documentos complementares
                          </Text>
                        ),
                        description: (
                          <Text color={theme.color.neutral[600]}>
                            Envie outros documentos importantes para o caso,
                            como Processo Administrativo, fotos, etc.
                          </Text>
                        ),
                        status: resolveStepStatus(complementares),
                      },
                    ]}
                    initial={6}
                  />
                  <div style={{ padding: "20px 0px 40px 0px" }}>
                    {!socketLoading.DOCUMENTOS_COMPLEMENTARES ? (
                      <UploadFile
                        files={complementares}
                        setFiles={setComplementares}
                        showProgressBar
                        multiple
                        accept="application/pdf, image/png, image/jpeg"
                        onDelete={async (file) => {
                          const listaArquivosAtualizada = complementares.filter(
                            (fileSended) => fileSended.file.uid !== file.uid
                          );
                          await deleteArquivo({ uuidArquivo: file.uid });
                          setComplementares(listaArquivosAtualizada);
                          const deleteMessage = {
                            evento: "DELETOU_DOCUMENTO_DESKTOP",
                            payload: {
                              tipoDocumento: "DOCUMENTOS_COMPLEMENTARES",
                              nomeArquivo: file.name,
                            },
                          };
                          sendJsonMessage(deleteMessage);
                        }}
                        onPreview={(file) => previewFile(file.file)}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        <Spin indicator={loadingIcon} />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 0 }}
              md={{ span: 24, order: 0 }}
              xl={{ span: 6, order: 1 }}
            >
              <div style={{ width: "100%", height: "100%" }}>
                {/* card QRCODE */}
                <QrCodeCard>
                  <Text size="lg" emphasys>
                    Digitalize e envie os documentos do seu cliente pelo celular
                  </Text>
                  <Text color={theme.color.neutral[600]}>
                    Basta entrar no link abaixo no navegador do seu celular, e
                    apontar a camera para o QR code.
                  </Text>
                  <Text color={theme.color.brand.primary}>prev.app/enviar</Text>
                  <div style={{ marginTop: 17, marginBottom: 46 }}>
                    {qrCode && (
                      <img
                        width={180}
                        height={180}
                        src={qrCode}
                        alt="qr code"
                      />
                    )}
                  </div>
                </QrCodeCard>
              </div>
            </Col>
            <Col>
              <FooterForm>
                <Button disabled type="primary">
                  Cancelar
                </Button>
                {documentosPendentes && (
                  <Tooltip
                    title={
                      temDocumentosPendentes ? (
                        <div>
                          <Text color={theme.color.neutral[100]}>
                            Pendências
                          </Text>
                          <Text color={theme.color.neutral[100]}>
                            Antes de prosseguir, é preciso corrigir as seguintes
                            pendências:
                          </Text>
                          {Object.entries(documentosPendentes).map(
                            (pendencia) => {
                              if (pendencia[1] === true) {
                                return (
                                  <Text
                                    key={pendencia[0]}
                                    color={theme.color.neutral[100]}
                                  >
                                    - {ENUM_SERVICE_UPLOAD_FILES[pendencia[0]]}
                                  </Text>
                                );
                              }
                              return null;
                            }
                          )}
                        </div>
                      ) : null
                    }
                    placement="topLeft"
                  >
                    <Badge
                      offset={[-60, 0]}
                      count={
                        Object.values(documentosPendentes).filter(
                          (item) => item === true
                        ).length
                      }
                    >
                      <Button
                        type="primary"
                        disabled={temDocumentosPendentes}
                        onClick={() => trazListaArquivosEmPdf()}
                      >
                        Enviar
                      </Button>
                    </Badge>
                  </Tooltip>
                )}
              </FooterForm>
            </Col>
          </Row>
        </div>
      </BodyContainer>
      <Footer />
    </>
  );
}

export default UploadFilesPage;
