import {
  PrevPlatformContext,
  Text,
  Steps,
  UploadFile,
  SegmentContext,
  Modal,
} from "@prev/ui-kit";
import { Row, Col } from "antd";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";
import {
  buildVinculosParaCnis,
  generateNewColorConcomitancia,
  uploadCartaDeConcessao,
  uploadCnis,
  uploadComunicadodeDecisao,
} from "../services/CalculoService";
import { v4 as uuidv4 } from "uuid";
import { getTipoBeneficioDesc } from "../utils/tipoBeneficioDescricao";

const UploadInicialContainer = styled.div`
  max-width: 1280px;
  margin: auto;
  .ant-steps-item-content {
    word-break: break-word;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    max-width: 400px;
  }
  padding: 0px 16px;
`;

const tiposAceitos = [
  "APOSENTADORIA_POR_INVALIDEZ",
  "APOSENTADORIA_INCAPACIDADE_PERMANENTE",
  "APOSENTADORIA_POR_INCAPACIDADE_PERMANENTE",
  "INCAPACIDADE",
  "AUXILIO_DOENCA",
  "AUXILIO_INCAPACIDADE_TEMPORARIA",
  "AUXILIO_ACIDENTE",
  "AUXILIO_ACIDENTE_PREVIDENCIARIO",
  "AUXILIO_DOENCA_ACIDENTE",
  "AUXILIO_INCAPACIDADE_TEMPORARIA_ACIDENTE",
  "APOSENTADORIA_POR_INVALIDEZ_ACIDENTE",
  "APOSENTADORIA_INCAPACIDADE_PERMANENTE_ACIDENTE",
];

function UploadInicial() {
  const navigate = useNavigate();
  const { theme } = useContext(PrevPlatformContext);
  const {
    parseCnis,
    parseComunicado,
    setParseCnis,
    setParseComunicado,
    setCnisOriginal,
    tipoFluxo,
    setParseCartaDeConcessao,
    parseCartaDeConcessao,
    arquivoCnis,
    setArquivoCnis,
    arquivoComunicado,
    setArquivoComunicado,
    arquivoCartaConcessao,
    setArquivoCartaConcessao,
  } = useContext(FluxoBeneficioContext);
  const { analytics, identified } = useContext(SegmentContext);
  const [tipoInvalido, setTipoInvalido] = useState({
    visible: false,
  });

  const realizaParseCnis = async (bin, uploadProgress) => {
    try {
      const response = await uploadCnis(bin, uploadProgress);
      const cnisResult = {
        ...response,
        editando: false,
        estrategiaDoCalculo: "SOMAR_CONCOMITANCIA",
        checkedQualidade: false,
        loaded: true,
        vinculos: buildVinculosParaCnis(response.vinculos).map((item) => {
          return {
            ...item,
            key: uuidv4(),
            color: generateNewColorConcomitancia(),
          };
        }),
      };
      if (!response.error && analytics && identified)
        analytics.track(
          "Enviou um CNIS com sucesso no fluxo de benefício por incapacidade"
        );
      setParseCnis(cnisResult);
      setCnisOriginal(cnisResult);
    } catch (err) {
      console.log(err);
    }
  };

  const realizaParseComunicado = async (bin, uploadProgress) => {
    try {
      const response = await uploadComunicadodeDecisao(bin, uploadProgress);
      if (!response.error && analytics && identified)
        analytics.track(
          "Enviou um Comunicado de Decisão com sucesso no fluxo de benefício por incapacidade"
        );
      if (tiposAceitos.includes(response.tipoBeneficio)) {
        setParseComunicado(response);
      } else {
        setArquivoComunicado((prevState) => [
          {
            ...prevState[0],
            error: true,
            success: false,
            errorResponse: "Tipo de benefício inválido para essa categoria",
          },
        ]);
        setTipoInvalido({
          tipo: getTipoBeneficioDesc(response.tipoBeneficio),
          visible: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const realizaParseCartaConcessao = async (bin, uploadProgress) => {
    try {
      const response = await uploadCartaDeConcessao(bin, uploadProgress);
      if (!response.error && analytics && identified)
        analytics.track(
          "Enviou uma Carta de Concessão com sucesso no fluxo de benefício por incapacidade"
        );

      if (tiposAceitos.includes(response.tipoBeneficio)) {
        setParseCartaDeConcessao(response);
      } else {
        setArquivoCartaConcessao((prevState) => [
          {
            ...prevState[0],
            error: true,
            success: false,
            errorResponse: "Tipo de benefício inválido para essa categoria",
          },
        ]);
        setTipoInvalido({
          tipo: getTipoBeneficioDesc(response.tipoBeneficio),
          visible: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (parseCnis && parseComunicado) {
      return navigate("/calculo");
    }
    if (parseCartaDeConcessao) {
      return navigate("/informacoes-complementares");
    }
  }, [parseCnis, parseComunicado, parseCartaDeConcessao]);

  if (tipoFluxo === "RESTABELECIMENTO")
    return (
      <UploadInicialContainer>
        <div>
          <Text style={{ marginBottom: 8, marginTop: 30 }} emphasys size="xl">
            Cálculo de benefício por incapacidade - Restabelecimento
          </Text>
          <Text style={{ marginBottom: 30 }} color={theme.color.neutral[600]}>
            Envio do CNIS e comunicado de decisão
          </Text>
          <Text style={{ marginBottom: 42 }} emphasys size="lg">
            Para iniciar o processo envie a carta de concessão do benefício
          </Text>
        </div>
        <div>
          <Row>
            <Col span={24}>
              <div style={{ margin: 16 }}>
                <Steps
                  steps={[
                    {
                      title: (
                        <Text color={theme.color.neutral[1000]} emphasys>
                          Upload da carta de concessão do benefício
                        </Text>
                      ),
                      description: (
                        <Text color={theme.color.neutral[600]}>
                          Envie o arquivo no formulário abaixo. A carta de
                          concessão pode ser obtida no MeuINSS.{" "}
                          <a
                            href="https://ajuda.previdenciarista.com/article/304-como-obter-carta-de-concessao-beneficio"
                            target="_blank"
                            data-testid="button-redirect-dica-extrair-carta-concessao"
                            id="button-redirect-dica-extrair-carta-concessao"
                          >
                            Veja aqui como extrair a Carta de Concessão no MeuINSS.
                          </a>
                        </Text>
                      ),
                      status: "process",
                    },
                  ]}
                  initial={0}
                />
              </div>
              <div
                style={{ maxWidth: 408, maxHeight: 152, margin: "16px 56px" }}
              >
                <UploadFile
                  data-testid="upload-file-carta-concessao"
                  id="upload-file-carta-concessao"
                  accept="application/pdf"
                  files={arquivoCartaConcessao}
                  setFiles={setArquivoCartaConcessao}
                  showProgressBar
                  onChange={({ file }) => {
                    const data = {
                      filename: file.name,
                      file: file,
                    };
                    realizaParseCartaConcessao(data, setArquivoCartaConcessao);
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        {tipoInvalido.visible && tipoInvalido?.tipo && (
          <Modal
            visible={tipoInvalido.visible}
            title="Atenção"
            cancelButtonProps={{ style: { display: "none" } }}
            okText="Entendi"
            onOk={() =>
              setTipoInvalido((prevState) => ({ ...prevState, visible: false }))
            }
          >
            <Text>
              Esta carta de concessão pertence a um benefício programável (
              {tipoInvalido.tipo}). Para utilizar esta ferramenta, é preciso
              enviar a carta de concessão de um benefício por incapacidade.
            </Text>
          </Modal>
        )}
      </UploadInicialContainer>
    );
  if (tipoFluxo === "CONCESSAO")
    return (
      <UploadInicialContainer>
        <div>
          <Text style={{ marginBottom: 8, marginTop: 30 }} emphasys size="xl">
            Cálculo de benefício por incapacidade - Concessão
          </Text>
          <Text style={{ marginBottom: 30 }} color={theme.color.neutral[600]}>
            Envio do CNIS e comunicado de decisão
          </Text>
          <Text style={{ marginBottom: 42 }} emphasys size="lg">
            Para iniciar o cálculo envie um CNIS e o comunicado de decisão de
            indeferimento
          </Text>
        </div>
        <div style={{ marginBottom: 100 }}>
          <Row>
            <Col span={12}>
              <div style={{ margin: 16 }}>
                <Steps
                  steps={[
                    {
                      title: (
                        <Text color={theme.color.neutral[1000]} emphasys>
                          Upload do CNIS do cliente
                        </Text>
                      ),
                      description: (
                        <Text color={theme.color.neutral[600]}>
                          Envie o documento em PDF no formulário abaixo.{" "}
                          <a
                            href="https://ajuda.previdenciarista.com/article/144-como-obter-o-cnis-do-meuinss"
                            target="_blank"
                            data-testid="button-redirect-dica-extrair-cnis"
                            id="button-redirect-dica-extrair-cnis"
                          >
                            Veja aqui como extrair o CNIS em PDF no MeuINSS.
                          </a>
                        </Text>
                      ),
                      status: "process",
                    },
                  ]}
                  initial={0}
                />
              </div>
              <div
                style={{ maxWidth: 408, maxHeight: 152, margin: "16px 56px" }}
              >
                <UploadFile
                  data-testid="upload-file-cnis-cliente"
                  id="upload-file-cnis-cliente"
                  accept="application/pdf"
                  files={arquivoCnis}
                  setFiles={setArquivoCnis}
                  onChange={async ({ file }) => {
                    const data = {
                      filename: file.name,
                      file: file,
                    };
                    await realizaParseCnis(data, setArquivoCnis);
                  }}
                  showProgressBar
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{ margin: 16 }}>
                <Steps
                  steps={[
                    {
                      title: (
                        <Text color={theme.color.neutral[1000]} emphasys>
                          Upload do comunicado de decisão de indeferimento
                        </Text>
                      ),
                      description: (
                        <Text color={theme.color.neutral[600]}>
                          Envie abaixo a carta comunicando o indeferimento do
                          pedido de benefício.{" "}
                          <a
                            href="https://ajuda.previdenciarista.com/article/305-como-obter-carta-indeferimento-comunicado-decisao"
                            target="_blank"
                            data-testid="button-redirect-dica-extrair-comunicado-decisao"
                            id="button-redirect-dica-extrair-comunicado-decisao"
                          >
                            Veja aqui como extrair o Comunicado de Decisão no MeuINSS.
                          </a>
                        </Text>
                      ),
                      status: "process",
                    },
                  ]}
                  initial={1}
                />
              </div>
              <div
                style={{ maxWidth: 408, maxHeight: 152, margin: "16px 56px" }}
              >
                <UploadFile
                  data-testid="upload-file-comunicado-decisao-concessao"
                  id="upload-file-comunicado-decisao-concessao"
                  accept="application/pdf"
                  files={arquivoComunicado}
                  setFiles={setArquivoComunicado}
                  onChange={async ({ file }) => {
                    const data = {
                      filename: file.name,
                      file: file,
                    };
                    await realizaParseComunicado(data, setArquivoComunicado);
                  }}
                  showProgressBar
                />
              </div>
            </Col>
          </Row>
        </div>
        {tipoInvalido.visible && tipoInvalido?.tipo && (
          <Modal
            visible={tipoInvalido.visible}
            title="Atenção"
            cancelButtonProps={{ style: { display: "none" } }}
            okText="Entendi"
            onOk={() =>
              setTipoInvalido((prevState) => ({ ...prevState, visible: false }))
            }
          >
            <Text>
              Esta carta comunicando o indeferimento pertence a um benefício
              programável ({tipoInvalido.tipo}). Para utilizar esta ferramenta,
              é preciso enviar a carta comunicando o indeferimento de um
              benefício por incapacidade.
            </Text>
          </Modal>
        )}
      </UploadInicialContainer>
    );
}

export default UploadInicial;
