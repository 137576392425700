// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexCenter {
  display: flex;
  align-items: center;
}

.flex {
  -webkit-flex: 1; /* Safari 6.1+ */
  -ms-flex: 1; /* IE 10 */
  flex: 1;
}

.flexContainer {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/RelatorioValorCausa/RelatorioValorCausa.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe,EAAE,gBAAgB;EACjC,WAAW,EAAE,UAAU;EACvB,OAAO;AACT;;AAEA;EACE,aAAa;AACf","sourcesContent":[".flexCenter {\n  display: flex;\n  align-items: center;\n}\n\n.flex {\n  -webkit-flex: 1; /* Safari 6.1+ */\n  -ms-flex: 1; /* IE 10 */\n  flex: 1;\n}\n\n.flexContainer {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
