import {
  API_HOST,
  API_HOST_PREV_DASHBOARD_V2,
  API_HOST_V2,
  IAPI_HOST_SEARCH_V3,
} from "../constants";
import qs from "qs";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
  authenticatedGet,
  authenticatedPost,
  authenticatedPostFile,
  authenticatedPutFile,
} from "./adapter";
import { verificaCartaExistenteEDeleta } from "./uploadFilesService";

export function formatDateToFetch({ de, ate }) {
  const deFormated = de.split("-");
  const ateFormated = ate.split("-");
  const result = {
    de: `${deFormated[0]}-${deFormated[1]}`,
    ate: `${ateFormated[0]}-${ateFormated[1]}`,
  };
  return result;
}

export async function uploadCnis(props, setCnisFile) {
  const host = API_HOST_V2;
  const path = "/utils/parser/cnis/calculo";
  const config = {
    onUploadProgress: (event) => {
      let progress = parseInt(Math.round(event.loaded * 100) / event.total);
      setCnisFile((prevState) => {
        const formatedFile = {
          ...prevState[0],
          progress,
        };
        return [formatedFile];
      });
    },
  };

  let data = new FormData();

  data.append("cnis", props.file, props.filename);

  const response = await authenticatedPutFile({ path, host, data, config });
  if (response.error) {
    setCnisFile((prevState) => {
      const formatedFile = {
        ...prevState[0],
        success: false,
        error: true,
        errorResponse: response.error?.response?.data?.error_description,
      };
      return [formatedFile];
    });
    throw response.error;
  } else {
    setCnisFile((prevState) => {
      const formatedFile = {
        ...prevState[0],
        success: true,
        error: false,
        errorResponse: "",
      };
      return [formatedFile];
    });
    return response;
  }
}

export async function uploadComunicadodeDecisao(props, setComunicadoDeDecisao) {
  const host = API_HOST_PREV_DASHBOARD_V2;
  const path = "/beneficios/comunicado-de-decisao-parser";
  const config = {
    onUploadProgress: (event) => {
      let progress = parseInt(Math.round(event.loaded * 100) / event.total);
      setComunicadoDeDecisao((prevState) => {
        const formatedFile = {
          ...prevState[0],
          progress,
        };
        return [formatedFile];
      });
    },
  };

  let data = new FormData();

  data.append("comunicadoDeDecisao", props.file, props.filename);

  const response = await authenticatedPutFile({ path, host, data, config });

  if (response.error) {
    setComunicadoDeDecisao((prevState) => {
      const formatedFile = {
        ...prevState[0],
        success: false,
        error: true,
        errorResponse: response.error?.response?.data?.error_description,
      };
      return [formatedFile];
    });
    throw response.error;
  } else {
    setComunicadoDeDecisao((prevState) => {
      const formatedFile = {
        ...prevState[0],
        success: true,
        error: false,
        errorResponse: "",
      };
      return [formatedFile];
    });
    return response;
  }
}

export async function uploadCartaDeConcessao(props, setCartaConcessao) {
  const host = API_HOST_PREV_DASHBOARD_V2;
  const path = "/beneficios/carta-de-concessao-parser";
  const config = {
    onUploadProgress: (event) => {
      let progress = parseInt(Math.round(event.loaded * 100) / event.total);
      setCartaConcessao((prevState) => {
        const formatedFile = {
          ...prevState[0],
          progress,
        };
        return [formatedFile];
      });
    },
  };

  let data = new FormData();

  data.append("cartaDeConcessao", props.file, props.filename);

  const response = await authenticatedPutFile({ path, host, data, config });

  if (response.error) {
    setCartaConcessao((prevState) => {
      const formatedFile = {
        ...prevState[0],
        success: false,
        error: true,
        errorResponse: response.error?.response?.data?.error_description,
      };
      return [formatedFile];
    });
    throw response.error;
  } else {
    setCartaConcessao((prevState) => {
      const formatedFile = {
        ...prevState[0],
        success: true,
        error: false,
        errorResponse: "",
      };
      return [formatedFile];
    });
    return response;
  }
}

const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const buildVinculosParaCnis = (vinculos) => {
  return vinculos.map((item, index) => {
    if (!item.fim && item.contribuicoes.length === 0) {
      console.error(
        "Data fim null e contribuicoes null, index = " + index,
        item
      );
    }
    if (!item.fim && item.contribuicoes.length) {
      const lastElement = item.contribuicoes[item.contribuicoes.length - 1];
      const lastDay = daysInMonth(
        lastElement.competencia.substring(0, 4),
        lastElement.competencia.substring(5, 8)
      );
      return {
        ...item,
        fimGerado: true,
        fim: lastElement.competencia + "-" + lastDay,
      };
    }

    return {
      ...item,
      // ...getQualidadeSegurado(item, proximo)
    };
  });
};

function HSLtoRGB(h, s, l) {
  let r, g, b;

  const rd = (a) => {
    return Math.floor(Math.max(Math.min(a * 256, 255), 0));
  };

  const hueToRGB = (m, n, o) => {
    if (o < 0) o += 1;
    if (o > 1) o -= 1;
    if (o < 1 / 6) return m + (n - m) * 6 * o;
    if (o < 1 / 2) return n;
    if (o < 2 / 3) return m + (n - m) * (2 / 3 - o) * 6;
    return m;
  };

  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;

  r = hueToRGB(p, q, h + 1 / 3);
  g = hueToRGB(p, q, h);
  b = hueToRGB(p, q, h - 1 / 3);

  return [rd(r), rd(g), rd(b)];
}

export const generateNewColorConcomitancia = () => {
  const hBase = Math.random();
  const newH = Math.floor(hBase * 360);
  const newL = Math.floor(Math.random() * 16) + 75;

  const [r, g, b] = HSLtoRGB(hBase, 1, newL * 0.01);

  return `rgb(${r}, ${g}, ${b})`;
};

export async function getSalariosDeContribuicaoPorPeriodo(params) {
  const queryString = qs.stringify(params, {
    addQueryPrefix: true,
    skipNulls: true,
    indices: false,
  });
  const host = API_HOST;
  const path = `/utils/salariosDeContribuicao/regras${queryString}`;

  const response = await authenticatedGet({ host, path });
  if (response.error) throw response.error;
  return response;
}

export const getAllCurrentItemsYear = (currentItem) => {
  const { contribuicoes } = currentItem;

  const allYears = contribuicoes?.map((date) => date.competencia.split("-")[0]);
  const uniqueYears = [...new Set(allYears)];

  return uniqueYears;
};

export const getContribuicoesAutomaticas = (vinculo) => {
  const mesInicial = moment(vinculo.inicio).startOf("month");
  const mesFinal = moment(vinculo.fim).startOf("month");

  let listaContribuicoes = [];

  const comecoMoment = mesInicial;

  while (comecoMoment <= mesFinal) {
    const findVinculo = vinculo.contribuicoes.find(
      (contribuicao) =>
        contribuicao.competencia === comecoMoment.format("YYYY-MM")
    );
    if (findVinculo) {
      listaContribuicoes.push(findVinculo);
    } else {
      listaContribuicoes.push({
        tipo: "REMUNERACAO",
        competencia: comecoMoment.format("YYYY-MM"),
        salarioDeContribuicao: 0,
        dataDoPagamento: null,
        contribuicao: null,
        atividadePrincipal: true,
      });
    }
    comecoMoment.add(1, "month");
  }
  return listaContribuicoes;
};

export const uploadCnisResolver = async (data, setCnisFile) => {
  const response = await uploadCnis(data, setCnisFile);
  const cnisResult = {
    ...response,
    editando: false,
    estrategiaDoCalculo: "SOMAR_CONCOMITANCIA",
    checkedQualidade: false,
    loaded: true,
    vinculos: buildVinculosParaCnis(response.vinculos).map((item) => {
      return {
        ...item,
        key: uuidv4(),
        color: generateNewColorConcomitancia(),
      };
    }),
  };
  return cnisResult;
};

export const uploadComunicadodeDecisaoResolver = async (
  data,
  setComunicadoDeDecisao
) => {
  const response = await uploadComunicadodeDecisao(
    data,
    setComunicadoDeDecisao
  );
  return response;
};

export async function getProfissoes(value) {
  const param = {
    q: value,
  };

  const queryString = qs.stringify(param, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  const host = IAPI_HOST_SEARCH_V3;
  const path = `/profissoes/app-search${queryString}`;

  const response = await authenticatedGet({ host, path });
  if (!response) return;

  var resposta = response;

  //Tentando mitigar as mudanças do serviço prev-search-api-v3 reformatando o serviço novo para a estrutura antiga
  if (response.results) {
    resposta = response.results;
    resposta = resposta
      .map(({ _meta, ...rest }) => rest)
      .map((item) => {
        const newItem = {};
        for (const key in item) {
          if (item[key] && item[key].raw) {
            newItem[key] = item[key].raw;
          } else {
            newItem[key] = item[key];
          }
        }
        return newItem;
      });
  }

  return resposta;
}

export async function getDoencas(value) {
  const param = {
    q: value,
  };

  const queryString = qs.stringify(param, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  const host = API_HOST_PREV_DASHBOARD_V2;
  const path = `/utils/cid10/buscar${queryString}`;

  const response = await authenticatedGet({ host, path });
  if (!response) return;
  return response;
}

export async function calculadoraBeneficio(props) {
  const host = API_HOST_PREV_DASHBOARD_V2;
  const path = "/calculadoras/beneficios";
  const data = { ...props.data, fluxoBeneficioPorIncapacidade: true };
  const response = await authenticatedPost({ host, path, data });

  if (response.error) throw response.error;
  return response;
}

export const getConcomitanciasPeriodoFinal = (vinculos, noTrava94) => {
  const _vinculos = vinculos;
  const periodoArray = [];
  let datas = [];

  for (let i in _vinculos) {
    datas.push(_vinculos[i].inicio);
    datas.push(_vinculos[i].fim);
  }

  //tira 1994 e orderna por menor
  datas = datas.sort((a, b) => new Date(a) - new Date(b));

  if (!noTrava94) {
    datas.filter((data) => {
      return moment(data).isAfter("1994-07-01T01:14:00Z");
    });
  }

  let menorData = datas[0];
  while (menorData) {
    periodoArray.push(menorData);
    menorData = datas.find(
      (data) => data !== menorData && new Date(data) > new Date(menorData)
    );
  }

  const periodoComplex = periodoArray.reduce((accumulator, current, index) => {
    if (index > 0) {
      const inicio = periodoArray[index - 1];
      const fim = moment(current).subtract(1, "day").format("Y-MM-DD");
      const vinculos = _vinculos.filter((vinculo) => {
        if (
          moment(fim).isBetween(
            moment(vinculo.inicio),
            moment(vinculo.fim),
            null,
            []
          )
        ) {
          return true;
        }
      });
      const final = {
        inicio,
        fim,
        vinculos,
        concomitancia: vinculos.length > 1,
      };
      return [...accumulator, final];
    }
    return accumulator;
  }, []);

  return periodoComplex;
};

export const criarBeneficio = async (props) => {
  const host = API_HOST_PREV_DASHBOARD_V2;
  const path = `/beneficios`;
  const data = props.data;

  const response = await authenticatedPost({ host, path, data });
  if (response.error) throw response.error;
  return response;
};

export async function criaComunicadoDecisao(props) {
  const host = API_HOST_PREV_DASHBOARD_V2;
  const path = "/beneficios/comunicado-de-decisao";
  const data = { ...props.data };
  const response = await authenticatedPost({ host, path, data });

  if (response.error) throw response.error;
  return response;
}
