import React, { useContext } from "react";
import styled from "styled-components";
import { Text, Button, Label, SegmentContext } from "@prev/ui-kit";
import { Row, Spin } from "antd";
import Logo from "../../images/logoScanner.png";
import {
  CameraOutlined,
  CheckCircleTwoTone,
  FolderOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { ScannerContext } from "../../context/ScannerProvider";
import { useEffect } from "react";
import { parseTextType, typeDocs } from "../../constants";
import { LoadingOutlined } from "@ant-design/icons";

const Wapper = styled.div`
  box-sizing: border-box;
  max-width: 1280px;
  margin: auto;
  padding: 0;
  background: ${(props) => props.theme.color.neutral[100]};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: ${(props) => (props.margin ? props.margin : "")};
  justify-content: ${(props) => (props.alignX ? props.alignX : "")};
  flex-direction: ${(props) => (props.direction ? props.direction : "")};
`;

const WapperDocks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 16px;
  pointer-events: ${({ check }) => (check ? "none" : "")};
  opacity: 0.7;
  border-bottom: 1px solid #dddddc;
  padding: 0 22px;
  padding-bottom: 16px;
`;

const BallDocs = styled.div`
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: ${({ check }) => (check ? "" : "1px solid #5fa1d8")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

function InitialScanner() {
  const {
    infoUserQrcode,
    arquivos,
    setSelectedType,
    setActiveStep,
    firstVisit,
    setFirstVisit,
    socketMobileLoading,
  } = useContext(ScannerContext);
  const { analytics, identified } = useContext(SegmentContext);

  useEffect(() => {
    if (analytics && identified && firstVisit) {
      analytics.track(
        "Entrou no envio de arquivos pelo celular no fluxo de benefício por incapacidade"
      );
      setFirstVisit(false);
    }
  }, [analytics, identified]);

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 15, color: "#F26526" }} spin />
  );

  return (
    <Wapper>
      <Container alignX="center" margin="16px 0 30px 0">
        <img width="cover" src={Logo} alt="logo previdenciarista" />
      </Container>
      <Container direction="column" margin="0 0 78px 0">
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 22px",
          }}
        >
          <Text size="lg" emphasys style={{ lineHeight: "26px" }}>
            Pronto, agora temos as informações necessárias para prosseguirmos.
          </Text>
          <Label style={{ fontSize: "12px", fontHeiht: 600, marginTop: "8px" }}>
            NOME DO CLIENTE
            <Text>{infoUserQrcode?.nome || ""}</Text>
          </Label>

          <Label
            style={{
              fontSize: "12px",
              fontHeiht: 600,
              marginTop: "8px",
              marginBottom: "19px",
            }}
          >
            CPF DO CLIENTE
            <Text>{infoUserQrcode?.cpf || "-"}</Text>
          </Label>

          <Text size="lg" emphasys style={{ lineHeight: "26px" }}>
            Lista de documentos para digitalizar:
          </Text>
        </Row>
        {typeDocs.map((value, index) => (
          <WapperDocks
            onClick={() => {
              setSelectedType(value);
              if (arquivos[value]?.length) {
                return setActiveStep(1);
              }
              setActiveStep(2);
            }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <Row style={{ display: "flex", flexWrap: "nowrap" }}>
                <BallDocs check={arquivos[value]?.length || false}>
                  {arquivos[value]?.length ? (
                    <CheckCircleTwoTone
                      style={{ fontSize: "22px" }}
                      twoToneColor="#00FF00"
                    />
                  ) : (
                    index + 1
                  )}
                </BallDocs>
                <Text size="base" emphasys style={{ lineHeight: "22px" }}>
                  {parseTextType[value]}
                </Text>
              </Row>
            </Row>
            {arquivos[value]?.length && (
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Row>
                  {socketMobileLoading[value] ? (
                    <Spin indicator={loadingIcon} />
                  ) : (
                    <FolderOutlined
                      style={{ fontSize: "21px", color: "#F26526" }}
                    />
                  )}
                  <Text
                    style={{
                      lineHeight: "18px",
                      marginLeft: "9px",
                      color: arquivos[value]?.length ? "black" : "red",
                    }}
                    size="sm"
                  >
                    {arquivos[value]?.length > 1
                      ? `${arquivos[value]?.length}  Documentos enviados`
                      : `${arquivos[value]?.length}  Documento enviado`}
                  </Text>
                </Row>
                <RightOutlined />
              </Row>
            )}
          </WapperDocks>
        ))}
      </Container>
      <div style={{ width: "100%", position: "fixed", bottom: 0 }}>
        <Button
          style={{ width: "100%", borderRadius: "0px" }}
          aria-label="Action Button"
          id="button-escanear-codigo"
          data-testid="button-escanear-codigo"
          icon={<CameraOutlined />}
          onClick={() => setActiveStep(4)}
        >
          Finalizar
        </Button>
      </div>
    </Wapper>
  );
}

export default InitialScanner;
