import {
  Button,
  DatePicker,
  FieldInput,
  Label,
  PrevPlatformContext,
  Select,
  Switch,
  Text,
} from "@prev/ui-kit";
import { Col, Form, Row } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { atividadeEspecial } from "../../constants";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import onKeyUpDatepickerMask from "../../utils/masks/maskDatePicker";

const AdicionarNovoVinculoContainer = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0px 24px;
`;

function AdicionarNovoVinculoModal({
  setOpenNovoVinculo,
  vinculos,
  setVinculos,
}) {
  const { theme } = useContext(PrevPlatformContext);
  const [disableDataFim, setDisableDataFim] = useState(true);
  const [dataInicial, setDataInicial] = useState(new moment().endOf("day"));

  const criaContribuicoesEntre = (de, ate) => {
    const contribuicoes = [];
    let dateIndex = moment(de);

    while (
      dateIndex.isBefore(ate) ||
      dateIndex.get("month") === ate.get("month")
    ) {
      const contribuicaoFormat = {
        atividadePrincipal: true,
        competencia: dateIndex.format("YYYY-MM"),
        contribuicao: null,
        dataDoPagamento: null,
        salarioDeContribuicao: 0,
        tipo: "REMUNERACAO",
      };

      contribuicoes.push(contribuicaoFormat);
      dateIndex = dateIndex.add(1, "month");
    }

    return contribuicoes;
  };

  const disabledInitialDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const disabledFinalDate = (current) => {
    return (
      (current && current < dataInicial) ||
      (current && current > moment().endOf("day"))
    );
  };

  const onFinish = (values) => {
    const novoVinculo = {
      alertas: [],
      atividadeEspecial: "NORMAL",
      categorias: [],
      cnpjCeiCpf: "",
      contaComoCarencia: values.carencia,
      contribuicoes: criaContribuicoesEntre(
        values.dataInicial,
        values.dataFinal
      ),
      fim: moment(values.dataFinal).format("YYYY-MM-DD"),
      inicio: moment(values.dataInicial).format("YYYY-MM-DD"),
      key: uuidv4(),
      nit: "",
      nomeDeExibicao: values.nomeDeExibicao,
      origem: values.nomeDeExibicao,
      professor: false,
      rural: false,
      tipoDeFiliadoNoVinculo: "",
    };

    const novosVinculos = [...vinculos, novoVinculo].sort(
      (a, b) => moment(a.inicio) - moment(b.inicio)
    );

    setVinculos(novosVinculos);
    setOpenNovoVinculo(false);
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
  };

  return (
    <AdicionarNovoVinculoContainer>
      <Form
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        name="adicionaNovoVinculo"
      >
        <Row gutter={[32, 0]}>
          <Col span={24}>
            <Form.Item
              name="nomeDeExibicao"
              rules={[
                {
                  required: true,
                  message: "Por favor insira um nome para o vínculo",
                },
              ]}
            >
              <FieldInput
                placeholder="Atividade do vínculo original"
                label="Atividade do vínculo original"
                required
                id="input-atividade-novo-vinculo"
                data-testid="input-atividade-novo-vinculo"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label>
              {
                <Text type="span" color="red">
                  *{" "}
                </Text>
              }
              Data de início
              <Form.Item
                style={{ marginTop: 8 }}
                name="dataInicial"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira uma data inicial para o vínculo",
                  },
                ]}
                onKeyUp={(event) => onKeyUpDatepickerMask(event)}
              >
                <DatePicker
                  prefix="/"
                  disabledDate={disabledInitialDate}
                  style={{ width: "100%" }}
                  size="large"
                  onChange={(data) => {
                    if (!data) {
                      setDisableDataFim(true);
                    } else {
                      setDisableDataFim(false);
                    }
                    setDataInicial(moment(data).endOf("day"));
                  }}
                  id="input-data-inicial-novo-vinculo"
                  data-testid="input-data-inicial-novo-vinculo"
                />
              </Form.Item>
            </Label>
          </Col>
          <Col span={12}>
            <Label>
              {
                <Text type="span" color="red">
                  *{" "}
                </Text>
              }
              Data de fim
              <Form.Item
                style={{ marginTop: 8 }}
                name="dataFinal"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira uma data final para o vínculo",
                  },
                ]}
                onKeyUp={(event) => onKeyUpDatepickerMask(event)}
              >
                <DatePicker
                  disabled={disableDataFim}
                  prefix="/"
                  disabledDate={disabledFinalDate}
                  style={{ width: "100%" }}
                  size="large"
                  id="input-data-final-novo-vinculo"
                  data-testid="input-data-final-novo-vinculo"
                />
              </Form.Item>
            </Label>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tipoAtividade"
              rules={[
                {
                  required: true,
                  message: "Por favor insira um tipo de atividade",
                },
              ]}
            >
              <Select
                label={
                  <div style={{ display: "flex", gap: "0px 2px" }}>
                    <Text type="span" color="red">
                      *
                    </Text>
                    <Text color={theme.color.neutral[600]}>
                      Tipo de atividade
                    </Text>
                  </div>
                }
                placeholder="Selecione"
                options={atividadeEspecial}
                id="select-tipo-atividade-novo-vinculo"
                data-testid="select-tipo-atividade-novo-vinculo"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label>
              Contar na carência
              <Form.Item
                name="carencia"
                style={{ marginTop: 10 }}
                initialValue={false}
                valuePropName="checked"
              >
                <Switch
                  id="switch-carencia-atividade-novo-vinculo"
                  data-testid="switch-carencia-atividade-novo-vinculo"
                />
              </Form.Item>
            </Label>
          </Col>
          <Col span={24}>
            <ButtonsContainer>
              <Button
                id="button-cancelar-adicionar-novo-vinculo"
                data-testid="button-cancelar-adicionar-novo-vinculo"
                onClick={() => setOpenNovoVinculo(false)}
              >
                Cancelar
              </Button>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="button-adicionar-novo-vinculo"
                  data-testid="button-adicionar-novo-vinculo"
                >
                  Adicionar
                </Button>
              </Form.Item>
            </ButtonsContainer>
          </Col>
        </Row>
      </Form>
    </AdicionarNovoVinculoContainer>
  );
}

export default AdicionarNovoVinculoModal;
