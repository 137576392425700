import React, { useContext, useEffect } from "react";
import { Popconfirm, Spin } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { Button, PrevPlatformContext, Text } from "@prev/ui-kit";
import styled from "styled-components";
import Logo from "../../images/logoScanner.png";
import IconBack from "../../icons/iconVoltar.svg";
import FileIcon from "../../icons/iconArquivo.svg";
import TrashIcon from "../../icons/iconLixeira.svg";
import { ScannerContext } from "../../context/ScannerProvider";
import { parseTextType } from "../../constants";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
`;

const ContainerButton = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 110px;
  padding-top: 10px;
`;

const ContainerDocuments = styled.div`
  width: 100%;
  max-height: calc(100% - (110px - 40px - 85px));
  display: flex;
  flex-direction: column;
  padding-bottom: 130px;
`;

const ContainerImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerIconBack = styled.div`
  margin-left: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  height: 30px;
`;

const ContainerContent = styled.div`
  padding: 1rem;
  margin-top: 2rem;
  height: 85px;
`;

const Documents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dddddc;
`;

export const ReadUploads = () => {
  const {
    arquivos,
    selectedType,
    setActiveStep,
    deletarArquivo,
    socketMobileLoading,
  } = useContext(ScannerContext);
  const { theme } = useContext(PrevPlatformContext);

  useEffect(() => {
    if (!arquivos[selectedType]?.length) setActiveStep(0);
  }, [arquivos]);

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: "#F26526" }} spin />
  );

  return (
    <Container>
      <Header>
        <ContainerIconBack>
          <img
            src={IconBack}
            alt="Icone de voltar"
            onClick={() => setActiveStep(0)}
          />
        </ContainerIconBack>
        <ContainerImage alignX="center" margin="16px 0 30px 0">
          <img width="cover" src={Logo} alt="logo previdenciarista" />
        </ContainerImage>
      </Header>

      <ContainerContent>
        <Text size="lg" emphasys>
          Meus uploads
        </Text>
        <Text emphasys>{parseTextType[selectedType]}</Text>
      </ContainerContent>
      <ContainerDocuments>
        {!socketMobileLoading[selectedType] &&
          arquivos[selectedType]?.length &&
          arquivos[selectedType].map((arquivo, index) => (
            <Documents key={index}>
              <div style={{ display: "flex" }}>
                <img
                  style={{ marginRight: "12px" }}
                  src={FileIcon}
                  alt="Icone de arquivo"
                />
                <Text>{arquivo.nome}</Text>
              </div>
              <Popconfirm
                title={<Text>Tem certeza que deseja excluir?</Text>}
                onConfirm={() => deletarArquivo(arquivo.id, arquivo.nome)}
                okText={"Sim"}
                cancelText={"Não"}
              >
                <img src={TrashIcon} alt="Icone de Lixeira" />
              </Popconfirm>
            </Documents>
          ))}
        {socketMobileLoading[selectedType] && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "20px",
            }}
          >
            <Spin indicator={loadingIcon} />
          </div>
        )}
      </ContainerDocuments>
      <ContainerButton
        style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Text
          color={theme.color.neutral[600]}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Caso ache necessário incluir mais documentos nesta pasta, clique no
          botão abaixo.
        </Text>
        <Button
          style={{ width: "100%", borderRadius: "0px" }}
          aria-label="Action Button"
          id="button-escanear-codigo"
          data-testid="button-escanear-codigo"
          icon={<CameraOutlined />}
          onClick={() => setActiveStep(2)}
        >
          Digitalizar mais documentos
        </Button>
      </ContainerButton>
    </Container>
  );
};
