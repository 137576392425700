import parser from "html-react-parser";

const { REACT_APP_PREV_ENV } = process.env;

export const API_HOST = `${process.env.REACT_APP_PREV_API}/v1`;
export const API_HOST_V2 = `${process.env.REACT_APP_PREV_API}/v2`;
export const API_HOST_PREV_DASHBOARD_V2 = `${process.env.REACT_APP_PREV_API}/dashboard/v2`;
export const IAPI_HOST_PREV_DASHBOARD_V2 = `${process.env.REACT_APP_PREV_IAPI}/dashboard/v2`;
export const IAPI_HOST_SEARCH_V3 = `${process.env.REACT_APP_PREV_IAPI}/v3/search`;

//Mesmos IDs de petição em produção e homologação. Cuidar para não alterar
export const PETICAO_CONCESSAO_UUID = "7bef9137-ebc8-42bd-bee5-8697f9e60b21";
export const PETICAO_REESTABELECIMENTO_UUID = "35b30bde-2979-411b-ae72-a553facda7ca";

export const textosAjuda = window?.previdenciarista?.ajuda;

export const tiposDeBeneficio = [
  {
    value: "APOSENTADORIA_RURAL",
    desc: "Aposentadoria rural",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO",
    desc: "Aposentadoria por tempo de contribuição",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS",
    desc: "Aposentadoria por tempo de contribuição pontos",
  },
  {
    value: "APOSENTADORIA_POR_IDADE_RURAL",
    desc: "Aposentadoria por idade (Rural)",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PROFESSOR",
    desc: "Aposentadoria por Tempo de Contribuição - Professor",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS_PROFESSOR",
    desc: "Aposentadoria por Tempo de Contribuição - Professor (Pontos)",
  },
  {
    value: "APOSENTADORIA_POR_IDADE_DEFICIENCIA",
    desc: "Aposentadoria por Idade da Pessoa com Deficiência",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_DEFICIENCIA",
    desc: "Aposentadoria por Tempo de Contribuição da Pessoa com Deficiência",
  },
  { value: "APOSENTADORIA_POR_IDADE", desc: "Aposentadoria por idade" },
  { value: "APOSENTADORIA_ESPECIAL", desc: "Aposentadoria especial" },
  { value: "APOSENTADORIA_POR_INVALIDEZ", desc: "Aposentadoria por invalidez" },
  {
    value: "APOSENTADORIA_POR_INCAPACIDADE",
    desc: "Aposentadoria por incapacidade",
  },
  {
    value: "APOSENTADORIA_POR_INCAPACIDADE_PERMANENTE",
    desc: "Aposentadoria por incapacidade permanente",
  },

  { value: "AUXILIO_DOENCA", desc: "Auxílio-Doença" },
  { value: "AUXILIO_ACIDENTE", desc: "Auxílio-Acidente" },
  { value: "ASSISTENCIAL", desc: "Assistencial" },
  { value: "PENSAO_POR_MORTE", desc: "Pensão por morte" },
  { value: "SALARIO_MATERNIDADE", desc: "Salário maternidade" },
  { value: "AUXILIO_RECLUSAO", desc: "Auxílio reclusão" },
];

export const atividadeEspecial = [
  {
    value: "NORMAL",
    label: "NORMAL",
  },
  {
    value: "ESPECIAL_25",
    label: "ESPECIAL 25",
  },
  {
    value: "ESPECIAL_20",
    label: "ESPECIAL 20",
  },
  {
    value: "ESPECIAL_15",
    label: "ESPECIAL 15",
  },
  {
    value: "PROFESSOR",
    label: "PROFESSOR",
  },
  {
    value: "RURAL",
    label: "RURAL",
  },
];

export const categorias = [
  { value: "TRABALHADOR_RURAL", label: "Rural" },
  { value: "SERVICO_MILITAR", label: "Militar" },
  { value: "SEGURADO_FACULTATIVO", label: "Segurado facultativo" },
  { value: "BENEFICIO_POR_INCAPACIDADE", label: "Benefício por incapacidade" },
  // { value: "AUXILIO_DOENCA", label: "Auxílio doença" },
  // { value: "AUXILIO_ACIDENTE", label: "Auxílio acidente" },
  // { value: "APOSENTADORIA_INVALIDEZ", label: "Aposetadoria por invalidez" },
  { value: "ESCOLA_TECNICA", label: "Escola Técnica" },
  { value: "SERVICO_PUBLICO", label: "Serviço público (CTC)" },
  { value: "PESSOA_COM_DEFICIENCIA", label: "Pessoa com deficiência" },
  { value: "TRABALHO_NO_EXTERIOR", label: "Trabalho no exterior" },
  { value: "CONTRIBUINTE_INDIVIDUAL", label: "Contribuinte individual" },
  { value: "GPS_NAO_COMPUTADO", label: "GPS não computado" },
  { value: "ATIVIDADE_ESPECIAL", label: "Atividade Especial" },
  {
    value: "INCAPACIDADE_PARA_TRABALHO",
    label: "Incapacidade para o Trabalho",
  },
  { value: "ATIVIDADE_CONCOMITANTE", label: "Atividades Concomitantes" },
  {
    value: "CONTRATO_DE_TRABALHO_COM_ERRO_NO_CNIS",
    label: "Contrato de - trabalho com erro no CNIS",
  },
];

export const tiposArquivos = [
  { tipoEnum: "DECLARACAO", descricao: "Declaração" },
  { tipoEnum: "TERMO", descricao: "Termo" },
  { tipoEnum: "CONTRATO", descricao: "Contrato" },
  { tipoEnum: "CERTIDAO", descricao: "Certidão" },
  { tipoEnum: "ATESTADO", descricao: "Atestado" },
  { tipoEnum: "APOLICE_DE_SEGURO", descricao: "Apólice de Seguro" },
  { tipoEnum: "ATESTADO_MEDICO", descricao: "Atestado médico" },
  { tipoEnum: "BLOCO_DO_PRODUTOR_RURAL", descricao: "Bloco do produtor rural" },
  { tipoEnum: "BOLETIM_DE_OCORRENCIA", descricao: "Boletim de Ocorrência" },
  { tipoEnum: "CNH", descricao: "CNH" },
  {
    tipoEnum: "CNIS_CADASTRO_NACIONAL_DE_INFORMACOES_SOCIAIS",
    descricao: "CNIS - Cadastro Nacional de Informações Sociais",
  },
  { tipoEnum: "CPF", descricao: "CPF" },
  { tipoEnum: "CADUNICO", descricao: "CadUnico" },
  { tipoEnum: "CARTA_DE_CONCESSAO", descricao: "Carta de concessão" },
  {
    tipoEnum: "CARTEIRA_DE_TRABALHO",
    descricao: "Carteira de Trabalho (CTPS)",
  },
  {
    tipoEnum: "CERTIDAO_DA_RECEITA_FEDERAL",
    descricao: "Certidão da Receita Federal",
  },
  { tipoEnum: "CERTIDAO_DE_IMOVEIS", descricao: "Certidão de Imóveis" },
  { tipoEnum: "CERTIDAO_DE_OBITO", descricao: "Certidão de Obito" },
  {
    tipoEnum: "CERTIDAO_DE_TEMPO_DE_CONTRIBUICAO_CTC",
    descricao: "Certidão de Tempo de Contribuição - CTC",
  },
  { tipoEnum: "CERTIDAO_DE_CASAMENTO", descricao: "Certidão de casamento" },
  { tipoEnum: "CERTIDAO_DE_NASCIMENTO", descricao: "Certidão de nascimento" },
  { tipoEnum: "CERTIDAO_DO_INCRA", descricao: "Certidão do INCRA" },
  {
    tipoEnum: "CERTIFICADO_DE_RESERVISTA",
    descricao: "Certificado de Reservista",
  },
  {
    tipoEnum: "COMPROVANTE_DE_RENDIMENTOS",
    descricao: "Comprovante de rendimentos",
  },
  {
    tipoEnum: "COMPROVANTE_DE_RESIDENCIA",
    descricao: "Comprovante de residência",
  },
  {
    tipoEnum: "COMUNICADO_DE_ACIDENTE_DE_TRABALHO_CAT",
    descricao: "Comunicado de Acidente de Trabalho - CAT",
  },
  { tipoEnum: "CONTRACHEQUE", descricao: "Contracheque" },
  {
    tipoEnum: "CONTRATO_DE_ARRENDAMENTO",
    descricao: "Contrato de Arrendamento",
  },
  { tipoEnum: "CONTRATO_DE_CONVIDENCIA", descricao: "Contrato de Convidência" },
  { tipoEnum: "CONTRATO_DE_TRABALHO", descricao: "Contrato de trabalho" },
  {
    tipoEnum: "DUT_DATA_DO_ULTIMO_DIA_DE_TRABALHO",
    descricao: "DUT - Data do Ultimo Dia de Trabalho",
  },
  {
    tipoEnum: "DECLARACAO_DE_FILIACAO",
    descricao: "Declaração de Filiação (Sindicato Rural)",
  },
  {
    tipoEnum: "DECLARACAO_DE_IMPOSTO_TERRITORIAL_RURAL",
    descricao: "Declaração de Imposto Territorial Rural",
  },
  {
    tipoEnum: "DECLARACAO_DE_IMPOSTO_DE_RENDA",
    descricao: "Declaração de Imposto de Renda",
  },
  { tipoEnum: "DIPLOMA", descricao: "Diploma" },
  { tipoEnum: "ESCRITURA_PUBLICA", descricao: "Escritura Pública" },
  {
    tipoEnum: "ESCRITURA_PUBLICA_DE_UNIAO_ESTAVEL",
    descricao: "Escritura Pública de União Estável",
  },
  {
    tipoEnum: "EXTRATO_ANALITICO_DA_CAIXA_ECONOMICA_FEDERAL",
    descricao: "Extrato Analítico da Caixa Econômica Federal",
  },
  { tipoEnum: "FICHA_DE_CRIADOR", descricao: "Ficha de Criador" },
  { tipoEnum: "FICHA_DE_PRODUTOR_RURAL", descricao: "Ficha de Produtor Rural" },
  { tipoEnum: "FOTOS", descricao: "Fotos" },
  {
    tipoEnum: "GUIA_DE_PREVIDENCIA_SOCIAL",
    descricao: "Guia de Previdência Social (GPS)",
  },
  { tipoEnum: "HISTORICO_ESCOLAR", descricao: "Histórico Escolar" },
  {
    tipoEnum: "INSS_ART29NB_CONSULTA_INFORMACOES_DE_REVISAO",
    descricao: "INSS - ART29NB - Consulta Informações de Revisão",
  },
  {
    tipoEnum: "INSS_BLOCRE_BLOQUEIO_DE_CREDITOS",
    descricao: "INSS - BLOCRE - Bloqueio de Créditos",
  },
  { tipoEnum: "INSS_CONBAS", descricao: "INSS - CONBAS" },
  { tipoEnum: "INSS_CONBER", descricao: "INSS - CONBER" },
  { tipoEnum: "INSS_CONCAD", descricao: "INSS - CONCAD" },
  { tipoEnum: "INSS_CONCAL", descricao: "INSS - CONCAL" },
  { tipoEnum: "INSS_CONCAR", descricao: "INSS - CONCAR" },
  { tipoEnum: "INSS_CONCRI", descricao: "INSS - CONCRI" },
  { tipoEnum: "INSS_CONCRV", descricao: "INSS - CONCRV" },
  { tipoEnum: "INSS_CONEST", descricao: "INSS - CONEST" },
  { tipoEnum: "INSS_CONEXI", descricao: "INSS - CONEXI" },
  { tipoEnum: "INSS_CONHAB", descricao: "INSS - CONHAB" },
  { tipoEnum: "INSS_CONIND", descricao: "INSS - CONIND" },
  { tipoEnum: "INSS_CONINF", descricao: "INSS - CONINF" },
  { tipoEnum: "INSS_CONOME", descricao: "INSS - CONOME" },
  { tipoEnum: "INSS_CONPAB", descricao: "INSS - CONPAB" },
  { tipoEnum: "INSS_CONPES", descricao: "INSS - CONPES" },
  { tipoEnum: "INSS_CONPRI", descricao: "INSS - CONPRI" },
  { tipoEnum: "INSS_CONPRO", descricao: "INSS - CONPRO" },
  { tipoEnum: "INSS_CONREAJ", descricao: "INSS - CONREAJ" },
  { tipoEnum: "INSS_CONREV", descricao: "INSS - CONREV" },
  { tipoEnum: "INSS_CONRMI", descricao: "INSS - CONRMI" },
  { tipoEnum: "INSS_CONSPROC", descricao: "INSS - CONSPROC" },
  { tipoEnum: "INSS_DEPENDENTES", descricao: "INSS - DEPENDENTES" },
  { tipoEnum: "INSS_DERIVADO", descricao: "INSS - DERIVADO" },
  { tipoEnum: "INSS_DESDOBRADO", descricao: "INSS - DESDOBRADO" },
  { tipoEnum: "INSS_HISAB", descricao: "INSS - HISAB" },
  { tipoEnum: "INSS_HISCAL", descricao: "INSS - HISCAL" },
  { tipoEnum: "INSS_HISCNIS", descricao: "INSS - HISCNIS" },
  { tipoEnum: "INSS_HISCP", descricao: "INSS - HISCP" },
  { tipoEnum: "INSS_HISCRE", descricao: "INSS - HISCRE" },
  { tipoEnum: "INSS_HISMED", descricao: "INSS - HISMED" },
  { tipoEnum: "INSS_HISOCR", descricao: "INSS - HISOCR" },
  { tipoEnum: "INSS_INFBEN", descricao: "INSS - INFBEN" },
  { tipoEnum: "INSS_INSTITUIDOR", descricao: "INSS - INSTITUIDOR" },
  { tipoEnum: "INSS_IRSMNB", descricao: "INSS - IRSMNB" },
  { tipoEnum: "INSS_MOVCON", descricao: "INSS - MOVCON" },
  { tipoEnum: "INSS_PA", descricao: "INSS - PA" },
  { tipoEnum: "INSS_PESBLO", descricao: "INSS - PESBLO" },
  { tipoEnum: "INSS_PESCPF", descricao: "INSS - PESCPF" },
  { tipoEnum: "INSS_PESCRE", descricao: "INSS - PESCRE" },
  { tipoEnum: "INSS_PESNIT", descricao: "INSS - PESNIT" },
  { tipoEnum: "INSS_PESNOM", descricao: "INSS - PESNOM" },
  { tipoEnum: "INSS_PROCREC", descricao: "INSS - PROCREC" },
  { tipoEnum: "INSS_REPRESENTANTE", descricao: "INSS - REPRESENTANTE" },
  { tipoEnum: "INSS_REVDIF", descricao: "INSS - REVDIF" },
  { tipoEnum: "INSS_REVINF", descricao: "INSS - REVINF" },
  { tipoEnum: "INSS_REVSIT", descricao: "INSS - REVSIT" },
  { tipoEnum: "INSS_TITULAR", descricao: "INSS - TITULAR" },
  { tipoEnum: "IDENTIDADE", descricao: "Identidade (RG)" },
  { tipoEnum: "LAUDO_LTCAT", descricao: "Laudo - LTCAT" },
  { tipoEnum: "LAUDO_PCMAT", descricao: "Laudo - PCMAT" },
  { tipoEnum: "LAUDO_PCMSO", descricao: "Laudo - PCMSO" },
  { tipoEnum: "LAUDO_PGR", descricao: "Laudo - PGR" },
  { tipoEnum: "LAUDO_PPRA", descricao: "Laudo - PPRA" },
  { tipoEnum: "LAUDO_MEDICO", descricao: "Laudo médico" },
  { tipoEnum: "MICROFICHA", descricao: "Microficha" },
  {
    tipoEnum: "PERFIL_PROFISSIOGRAFICO_PREVIDENCIARIO_PPP",
    descricao: "Perfil Profissiográfico Previdenciário - PPP",
  },
  { tipoEnum: "PROCESSO_ADMINISTRATIVO", descricao: "Processo administrativo" },
  { tipoEnum: "PROCURACAO", descricao: "Procuração" },
  { tipoEnum: "PRONTUARIO_MEDICO", descricao: "Prontuário Médico" },
  { tipoEnum: "REGISTRO_DE_EMPREGADOS", descricao: "Registro de Empregados" },
  {
    tipoEnum: "TERMO_DE_RESCISAO_DE_CONTRATO_DE_TRABALHO",
    descricao: "Termo de Rescisão de Contrato de Trabalho",
  },
  {
    tipoEnum: "PROTOCOLO_DE_REQUERIMENTO_ADMINISTRATIVO",
    descricao: "Protocolo de Requerimento Administrativo",
  },
  { tipoEnum: "PROTOCOLO", descricao: "Protocolo" },
];

export const estrategiaDeCorrecaoMonetaria = [
  {
    value: "IGP_DI_INPC_TR_IPCA_E",
    desc: "IGP-DI (até 03/2006), INPC (04/2006 - 06/2009), TR (07/2009-25/03/2015) e IPCA-E (a partir de 25/03/2015)",
  },
  {
    value: "IGP_DI_INPC_TR_IPCA_E2017",
    desc: "IGP-DI (até 03/2006), INPC (04/2006 - 06/2009), TR (07/2009 - 09/2017) e IPCA-E (a partir de 10/2017)",
  },
  {
    value: "IGP_DI_INPC_IPCA_E",
    desc: parser(
      "<b>Tema 810 STF</b>: IGP-DI (até 03/2006), INPC (04/2006 - 06/2009) e IPCA-E (a partir de 07/2009)"
    ),
  },
  {
    value: "IGP_DI_INPC_TR",
    desc: "IGP-DI (até 03/2006), INPC (04/2006 - 06/2009) e TR (a partir de 07/2009)",
  },
  {
    value: "IGP_DI_INPC",
    desc: parser(
      "<b>Tema 905 STJ</b>: IGP-DI (até 03/2006), INPC (a partir de 04/2006)"
    ),
  },
];

export const parseTextType = {
  DOCUMENTO_PESSOAL_NAO_CATEGORIZADO: "Documento de Identidade e CPF",
  COMPROVANTE_DE_RESIDENCIA: "Comprovante de Residência",
  PROCURACAO: "Procuração",
  DECLARACAO_DE_HIPOSUFICIENCIA: "Declaração de Hiposuficiência",
  CARTA_DE_INDEFERIMENTO: "Carta de Indeferimento",
  DOCUMENTO_MEDICO_NAO_CATEGORIZADO: "Documento Médico Não Categorizado",
  DOCUMENTOS_COMPLEMENTARES: "Documentos Complementares",
};

export const typeDocs = [
  "DOCUMENTO_PESSOAL_NAO_CATEGORIZADO",
  "COMPROVANTE_DE_RESIDENCIA",
  "PROCURACAO",
  "DECLARACAO_DE_HIPOSUFICIENCIA",
  "CARTA_DE_INDEFERIMENTO",
  "DOCUMENTO_MEDICO_NAO_CATEGORIZADO",
  "DOCUMENTOS_COMPLEMENTARES",
];
