import {
  Ajuda,
  Button,
  Checkbox,
  CurrencyInput,
  DatePicker,
  EditableLabel,
  Modal,
  PrevPlatformContext,
  Select,
  Tabs,
  Text,
} from "@prev/ui-kit";
import {
  CaretDownOutlined,
  FilterOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Col, Row, Dropdown, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  dateFormatYYYYMMDDToDDMMYYYY,
  isValidDate,
} from "../../functions/momentFunctions";
import {
  formatDateToFetch,
  getAllCurrentItemsYear,
  getSalariosDeContribuicaoPorPeriodo,
} from "../../services/CalculoService";
import ContribuicaoRender, { GridContainer } from "./ContribuicaoRender";
import GerarSalariosModal from "./GerarSalariosModal";
import { textosAjuda, atividadeEspecial } from "../../constants";
import moment from "moment";

const ContribuicoesContainer = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 20px 0;
`;

const HeaderContainer = styled.div`
  margin-top: 16px;
`;

const BodyContainer = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const HeaderBody = styled.div`
  padding: 16px;
  width: 100%;
  background-color: ${(props) => props.theme.color.neutral[200]};
`;

const GridBody = styled.div`
  width: 100%;
`;

const Footer = styled.div`
  height: 64px;
  display: flex;
  gap: 12px 24px;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  max-width: 1280px;
  width: 100%;
  padding: 0 40px;
  z-index: 11;
`;

const SelecionadosBox = styled.div`
  background-color: ${(props) => props.theme.color.brand.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab-active {
    background-color: ${(props) => props.theme.color.light.grayish.orange};
  }
`;

const WarningHeaderContainer = styled.div`
  width: 100%;
  height: 32px;
  background-color: ${(props) => props.theme.color.light.grayish.orange};
  display: flex;
  gap: 9px;
  padding: 0 17px;
  align-items: center;
`;

function Contribuicoes({
  currentItem,
  atualizaPeriodoContribuicao,
  atualizaNomeVinculo,
  atualizaTipoAtividadeVinculo,
  vinculos,
  setVinculos,
}) {
  const { theme } = useContext(PrevPlatformContext);
  const [allCurrentYears, setAllCurrentYears] = useState([]);
  const [currentYear, setCurrentYear] = useState(allCurrentYears[0]);
  const [contribuicoesByYear, setContribuicoesByYear] = useState([]);
  const [listaTratada, setListaTratada] = useState([]);
  const [novoValorContribuicao, setNovoValorContribuicao] = useState(null);
  const [openGerarSalarios, setOpenGerarSalarios] = useState(false);
  const [valorPreenchimento, setValorPreenchimento] = useState("zero");
  const [substituirCnis, setSubstituirCnis] = useState(false);
  const [balancoContribuicao, setBalancoContribuicao] = useState({
    contribuicoesAbaixoDoPiso: 0,
    contribuicoesAcimaDoTeto: 0,
  });
  const [contribuicoesSelecionadas, setContribuicoesSelecionadas] = useState(
    []
  );
  const [contribuicaoEmEdicao, setContribuicaoEmEdicao] = useState("");
  const [contribuicoesInferioresAoPiso, setContribuicoesInferioresAoPiso] =
    useState(false);
  const [contribuicoesSuperioresAoTeto, setContribuicoesSuperioresAoTeto] =
    useState(false);
  const [contribuicoesToRender, setContribuicoesToRender] = useState([]);

  const resolveBalancoContribuicao = () => {
    let contribuicoesAbaixoDoPiso = 0;
    let contribuicoesAcimaDoTeto = 0;

    listaTratada?.forEach((contribuicao) => {
      if (contribuicao.salarioDeContribuicao > contribuicao.teto) {
        contribuicoesAcimaDoTeto += 1;
      }
      if (contribuicao.salarioDeContribuicao < contribuicao.piso) {
        contribuicoesAbaixoDoPiso += 1;
      }
    });

    setBalancoContribuicao({
      contribuicoesAbaixoDoPiso,
      contribuicoesAcimaDoTeto,
    });
  };

  const resolveSalariosDeContribuicaoPorPeriodo = async ({ de, ate }) => {
    const result = await getSalariosDeContribuicaoPorPeriodo(
      formatDateToFetch({ de, ate })
    );
    return result;
  };

  const displayWarningBalanco = () => {
    let formatText;

    if (
      balancoContribuicao.contribuicoesAbaixoDoPiso > 0 &&
      balancoContribuicao.contribuicoesAcimaDoTeto > 0
    ) {
      formatText = (
        <Text>
          {`Existem ${balancoContribuicao.contribuicoesAbaixoDoPiso} contribuição (es) com salário abaixo do mínimo e ${balancoContribuicao.contribuicoesAcimaDoTeto} contribuição (es) com salário acima do teto.`}
        </Text>
      );
    }
    if (
      balancoContribuicao.contribuicoesAbaixoDoPiso > 0 &&
      balancoContribuicao.contribuicoesAcimaDoTeto === 0
    ) {
      formatText = (
        <Text>
          {`Existem ${balancoContribuicao.contribuicoesAbaixoDoPiso} contribuição (es) com salário abaixo do mínimo.`}
        </Text>
      );
    }
    if (
      balancoContribuicao.contribuicoesAcimaDoTeto > 0 &&
      balancoContribuicao.contribuicoesAbaixoDoPiso === 0
    ) {
      formatText = (
        <Text>
          {`Existem ${balancoContribuicao.contribuicoesAcimaDoTeto} contribuição (es) com salário acima do teto.`}
        </Text>
      );
    }
    if (
      balancoContribuicao.contribuicoesAcimaDoTeto === 0 &&
      balancoContribuicao.contribuicoesAbaixoDoPiso === 0
    ) {
      return null;
    }
    const response = (
      <WarningHeaderContainer>
        <InfoCircleFilled
          style={{
            color: theme.color.functional.warning,
            fontSize: 14,
          }}
        />
        {formatText}
      </WarningHeaderContainer>
    );
    return response;
  };
  const optionsCheckHeader = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setContribuicoesSelecionadas(
                  listaTratada.map((contribuicao) => contribuicao.competencia)
                );
              }}
              data-testid="click-selecionar-todas-contribuicoes"
              id="click-selecionar-todas-contribuicoes"
            >
              Selecionar todos
            </div>
          ),
        },
        {
          label: (
            <div
              onClick={() => {
                setContribuicoesSelecionadas(
                  contribuicoesByYear.map(
                    (contribuicao) => contribuicao.competencia
                  )
                );
              }}
              data-testid="click-selecionar-todas-contribuicoes-do-ano"
              id="click-selecionar-todas-contribuicoes-do-ano"
            >
              Selecionar todos do ano
            </div>
          ),
        },
        {
          label: (
            <div
              onClick={() => {
                setContribuicoesSelecionadas([]);
              }}
              data-testid="click-selecionar-nenhuma-contribuicao"
              id="click-selecionar-nenhuma-contribuicao"
            >
              Nenhum
            </div>
          ),
        },
        {
          label: (
            <div
              onClick={() => {
                setContribuicoesSelecionadas(
                  contribuicoesByYear
                    .filter(
                      (contribuicao) =>
                        contribuicao.salarioDeContribuicao < contribuicao.piso
                    )
                    .map((contribuicao) => contribuicao.competencia)
                );
              }}
              data-testid="click-selecionar-abaixo-minimo-contribuicao"
              id="click-selecionar-abaixo-minimo-contribuicao"
            >
              Contribuição abaixo do mínimo
            </div>
          ),
        },
        {
          label: (
            <div
              onClick={() => {
                setContribuicoesSelecionadas(
                  contribuicoesByYear
                    .filter(
                      (contribuicao) =>
                        contribuicao.salarioDeContribuicao > contribuicao.teto
                    )
                    .map((contribuicao) => contribuicao.competencia)
                );
              }}
              data-testid="click-selecionar-acima-teto-contribuicao"
              id="click-selecionar-acima-teto-contribuicao"
            >
              Contribuição superior ao teto
            </div>
          ),
        },
      ]}
    />
  );

  const optionsFilterHeader = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() =>
                setContribuicoesInferioresAoPiso(!contribuicoesInferioresAoPiso)
              }
            >
              <Checkbox checked={contribuicoesInferioresAoPiso} /> Inferiores ao
              salário mínimo
            </div>
          ),
        },
        {
          label: (
            <div
              onClick={() =>
                setContribuicoesSuperioresAoTeto(!contribuicoesSuperioresAoTeto)
              }
            >
              <Checkbox checked={contribuicoesSuperioresAoTeto} /> Superiores ao
              teto previdenciário
            </div>
          ),
        },
      ]}
    />
  );

  const getContribuicaoPeriodo = async () => {
    const getResponseContribuicaoPorPeriodo =
      await resolveSalariosDeContribuicaoPorPeriodo({
        de: currentItem.inicio,
        ate: currentItem.fim,
      });
    const contribuicoesFormatadas = currentItem.contribuicoes.map(
      (contribuicao) => ({
        ...contribuicao,
        ...getResponseContribuicaoPorPeriodo.find(
          (contribuicaoPorPeriodo) =>
            contribuicao.competencia === contribuicaoPorPeriodo.competencia
        ),
      })
    );
    setListaTratada(contribuicoesFormatadas);
  };

  const adicionaListaSelecionadas = (contribuicaoChecked, competencia) => {
    if (contribuicaoChecked) {
      const newData = [...contribuicoesSelecionadas, competencia];
      setContribuicoesSelecionadas(newData);
    } else {
      const newData = contribuicoesSelecionadas.filter(
        (cs) => cs !== competencia
      );
      setContribuicoesSelecionadas(newData);
    }
  };

  const atualizaValorContribuicao = (valor, competencia) => {
    const atualizaValorCompetencia = listaTratada.map((contribuicao) => {
      if (contribuicao.competencia === competencia) {
        return {
          ...contribuicao,
          salarioDeContribuicao: valor,
        };
      }
      return contribuicao;
    });
    setListaTratada(atualizaValorCompetencia);
  };

  const onPressTab = (competencia, inputValue) => {
    const indexContribuicaoAtual = contribuicoesByYear.findIndex(
      (contribuicao) => contribuicao.competencia === competencia
    );
    const proximaContribuicao = contribuicoesByYear[indexContribuicaoAtual + 1];
    if (proximaContribuicao) {
      const atualizaValorCompetencia = listaTratada.map((contribuicao) => {
        if (
          contribuicao.competencia === competencia ||
          contribuicao.competencia === proximaContribuicao.competencia
        ) {
          return {
            ...contribuicao,
            salarioDeContribuicao: inputValue,
          };
        }
        return contribuicao;
      });
      setListaTratada(atualizaValorCompetencia);
      return setContribuicaoEmEdicao(proximaContribuicao.competencia);
    } else {
      atualizaValorContribuicao(inputValue, contribuicoesByYear[0].competencia);
      return setContribuicaoEmEdicao(contribuicoesByYear[0].competencia);
    }
  };

  const onPressShiftTab = (competencia, inputValue) => {
    const indexContribuicaoAtual = contribuicoesByYear.findIndex(
      (contribuicao) => contribuicao.competencia === competencia
    );
    const anteriorContribuicao =
      contribuicoesByYear[indexContribuicaoAtual - 1];
    if (anteriorContribuicao) {
      const atualizaValorCompetencia = listaTratada.map((contribuicao) => {
        if (
          contribuicao.competencia === competencia ||
          contribuicao.competencia === anteriorContribuicao.competencia
        ) {
          return {
            ...contribuicao,
            salarioDeContribuicao: inputValue,
          };
        }
        return contribuicao;
      });
      setListaTratada(atualizaValorCompetencia);
      return setContribuicaoEmEdicao(anteriorContribuicao.competencia);
    } else {
      atualizaValorContribuicao(
        inputValue,
        contribuicoesByYear[contribuicoesByYear.length - 1].competencia
      );
      return setContribuicaoEmEdicao(
        contribuicoesByYear[contribuicoesByYear.length - 1].competencia
      );
    }
  };

  const atualizaListaTratadaComFiltro = () => {
    const contribuicoesFiltradas = listaTratada?.filter((contribuicao) => {
      if (!contribuicoesInferioresAoPiso && !contribuicoesSuperioresAoTeto) {
        return contribuicao;
      }
      if (
        contribuicoesInferioresAoPiso &&
        contribuicao.salarioDeContribuicao < contribuicao.piso
      ) {
        return contribuicao;
      }
      if (
        contribuicoesSuperioresAoTeto &&
        contribuicao.salarioDeContribuicao > contribuicao.teto
      ) {
        return contribuicao;
      }
    });
    setContribuicoesToRender(contribuicoesFiltradas);
  };

  const prepareToRenderContribuicoes = () => {
    const contribuicoesFormatadas = contribuicoesByYear?.map((contribuicao) => {
      const isChecked = contribuicoesSelecionadas.includes(
        contribuicao.competencia
      );
      return (
        <ContribuicaoRender
          key={contribuicao.competencia}
          contribuicao={contribuicao}
          isChecked={isChecked}
          onContribuicaoSelecionada={adicionaListaSelecionadas}
          atualizaValorContribuicao={atualizaValorContribuicao}
          onPressTab={onPressTab}
          onPressShiftTab={onPressShiftTab}
          contribuicaoEmEdicao={contribuicaoEmEdicao}
          setContribuicaoEmEdicao={setContribuicaoEmEdicao}
        />
      );
    });
    if (
      contribuicoesFormatadas.length < 1 &&
      (contribuicoesInferioresAoPiso || contribuicoesSuperioresAoTeto)
    ) {
      return (
        <GridContainer
          style={{ flexDirection: "row", gap: 10, alignItems: "center" }}
        >
          <Text color={theme.color.neutral[600]}>
            Um filtro parece estar ativo, experimente limpar os filtros clicando
            aqui:
          </Text>
          <Button
            onClick={() => {
              setContribuicoesInferioresAoPiso(false);
              setContribuicoesSuperioresAoTeto(false);
            }}
          >
            Limpar Filtros
          </Button>
        </GridContainer>
      );
    }
    return contribuicoesFormatadas;
  };

  const atualizaContribuicoes = () => {
    const removeVinculoAntigo = vinculos.filter(
      (vinculo) => vinculo.key !== currentItem.key
    );
    const novoVinculo = { ...currentItem, contribuicoes: listaTratada };
    const novaListaVinculos = [...removeVinculoAntigo, novoVinculo].sort(
      (a, b) => moment(a.inicio) - moment(b.inicio)
    );

    setVinculos(novaListaVinculos);
  };

  useEffect(() => {
    setAllCurrentYears(getAllCurrentItemsYear(currentItem));
    if (currentItem.contribuicoes.length > 0) {
      getContribuicaoPeriodo();
    }
  }, [currentItem]);

  useEffect(() => {
    setCurrentYear(allCurrentYears[0]);
  }, [allCurrentYears]);

  useEffect(() => {
    const contribuicoesClassificadasPorAno = contribuicoesToRender?.filter(
      (ano) => ano.competencia.includes(currentYear)
    );
    setContribuicoesByYear(contribuicoesClassificadasPorAno);
  }, [currentYear, contribuicoesToRender]);

  useEffect(() => {
    resolveBalancoContribuicao();
    atualizaContribuicoes();
  }, [listaTratada]);

  useEffect(() => {
    atualizaListaTratadaComFiltro();
  }, [
    listaTratada,
    contribuicoesInferioresAoPiso,
    contribuicoesSuperioresAoTeto,
  ]);

  return (
    <>
      <ContribuicoesContainer>
        <Text color={theme.color.neutral[1000]} emphasys>
          Contribuições
        </Text>
        <HeaderContainer>
          <Row gutter={3}>
            <Col span={10}>
              <div>
                <Text color={theme.color.neutral[600]}>Atividade</Text>
                <EditableLabel
                  value={currentItem?.nomeDeExibicao}
                  onValueChange={(value) =>
                    atualizaNomeVinculo(value, currentItem)
                  }
                  id="editable-label-nome-exibicao"
                  data-testid="editable-label-nome-exibicao"
                />
              </div>
            </Col>
            <Col span={10}>
              <div>
                <Text color={theme.color.neutral[600]}>Período</Text>
                <DatePicker.Calculo
                  defaultValue={
                    isValidDate(currentItem.inicio) &&
                    isValidDate(currentItem.fim) && [
                      dateFormatYYYYMMDDToDDMMYYYY(currentItem.inicio),
                      dateFormatYYYYMMDDToDDMMYYYY(currentItem.fim),
                    ]
                  }
                  allowClear={false}
                  range
                  style={{ backgroundColor: "white", color: "black" }}
                  prefix="/"
                  onChange={(dateRange) => {
                    atualizaPeriodoContribuicao(dateRange, currentItem);
                  }}
                  data-testid="input-data-periodo-contribuicao"
                  id="input-data-periodo-contribuicao"
                />
              </div>
            </Col>
            <Col span={4}>
              <div>
                <Text color={theme.color.neutral[600]}>Tipo de atividade</Text>
                <Select.Calculo
                  defaultValue={currentItem?.atividadeEspecial}
                  options={atividadeEspecial}
                  onSelect={(atividade) =>
                    atualizaTipoAtividadeVinculo(atividade, currentItem)
                  }
                  data-testid="select-vinculo-atividade-especial"
                  id="select-vinculo-atividade-especial"
                />
              </div>
            </Col>
          </Row>
        </HeaderContainer>
        <BodyContainer>
          <Row>
            <Col span={2}>
              <StyledTabs
                tabPosition="left"
                components={[
                  ...allCurrentYears.map((year) => ({
                    title: year,
                  })),
                ]}
                onTabClick={(tabPosition) => {
                  setCurrentYear(allCurrentYears[tabPosition - 1]);
                }}
                data-testid="tab-selecao-ano"
                id="tab-selecao-ano"
              />
            </Col>
            <Col style={{ marginBottom: 100 }} span={22}>
              <HeaderBody>
                <Row align="middle">
                  <Col span={3}>
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Text emphasys color={theme.color.neutral[1000]}>
                        Competência
                      </Text>
                      <Ajuda
                        itens={textosAjuda}
                        page="fluxoBeneficioIncapacidade"
                        element="competencia"
                      />
                    </div>
                  </Col>
                  <Col span={1}>
                    <Dropdown overlay={optionsCheckHeader}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          style={{ width: 20 }}
                          checked={contribuicoesSelecionadas.length > 0}
                        />
                        <CaretDownOutlined
                          style={{
                            fontSize: 10,
                            color: theme.color.neutral[600],
                          }}
                        />
                      </div>
                    </Dropdown>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Text emphasys color={theme.color.neutral[1000]}>
                        Salário de contribuição
                      </Text>
                      <Ajuda
                        itens={textosAjuda}
                        page="fluxoBeneficioIncapacidade"
                        element="salarioContribuicao"
                      />
                    </div>
                  </Col>
                  <Col span={3}>
                    <Dropdown overlay={optionsFilterHeader}>
                      <FilterOutlined
                        style={{
                          color: theme.color.neutral[600],
                          fontSize: 13,
                        }}
                      />
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => setOpenGerarSalarios(true)}
                        color="brand-secondary"
                        data-testid="button-abrir-modal-gerar-salarios"
                        id="button-abrir-modal-gerar-salarios"
                      >
                        Gerar salários
                      </Button>
                    </div>
                  </Col>
                </Row>
              </HeaderBody>
              <GridBody>
                {displayWarningBalanco()}
                <Row>
                  {listaTratada.length ? (
                    prepareToRenderContribuicoes()
                  ) : (
                    <h1>Loading...</h1>
                  )}
                </Row>
              </GridBody>
            </Col>
          </Row>
        </BodyContainer>
      </ContribuicoesContainer>
      {openGerarSalarios && (
        <Modal
          title={
            <Text size="lg" emphasys color={theme.color.neutral[1000]}>
              Preenchimento automático de salários de contribuição
            </Text>
          }
          width={696}
          visible={openGerarSalarios}
          cancelText="Cancelar"
          okText="Aplicar"
          onOk={() => {
            if (substituirCnis) {
              const contribuicoesEditadas = listaTratada.map((contribuicao) => {
                const findContribuicaoFiltrada = contribuicoesToRender.find(
                  (contribuicaoFiltrada) =>
                    contribuicaoFiltrada.competencia ===
                    contribuicao.competencia
                );
                if (findContribuicaoFiltrada) {
                  return {
                    ...contribuicao,
                    salarioDeContribuicao:
                      valorPreenchimento === "zero"
                        ? 0
                        : contribuicao[valorPreenchimento],
                  };
                } else {
                  return contribuicao;
                }
              });
              setListaTratada(contribuicoesEditadas);
              setValorPreenchimento("zero");
              setSubstituirCnis(false);
              setOpenGerarSalarios(false);
            }
          }}
          onCancel={() => setOpenGerarSalarios(false)}
          okButtonProps={{
            id: "button-atualizar-salarios",
            "data-testid": "button-atualizar-salarios",
          }}
          cancelButtonProps={{
            id: "button-cancelar-atualizar-salarios",
            "data-testid": "button-cancelar-atualizar-salarios",
          }}
        >
          <GerarSalariosModal
            valorPreenchimento={valorPreenchimento}
            setValorPreenchimento={setValorPreenchimento}
            substituirCnis={substituirCnis}
            setSubstituirCnis={setSubstituirCnis}
            existeFiltro={{
              contribuicoesInferioresAoPiso,
              contribuicoesSuperioresAoTeto,
            }}
          />
        </Modal>
      )}
      {contribuicoesSelecionadas.length > 0 && (
        <Footer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <SelecionadosBox>
              <Text emphasys color={theme.color.neutral[100]}>
                {contribuicoesSelecionadas.length}
              </Text>
            </SelecionadosBox>
            <Text color={theme.color.neutral[1000]}>selecionadas</Text>
          </div>
          <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
            <CurrencyInput
              placeholder="Novo valor de contribuição"
              value={novoValorContribuicao}
              decimalsLimit={2}
              onValueChange={(value, name, floatValue) =>
                setNovoValorContribuicao(floatValue.float)
              }
              decimalSeparator=","
              groupSeparator="."
              decimalScale={2}
              style={{ width: 216 }}
            />
            <Button
              onClick={() => setContribuicoesSelecionadas([])}
              color={theme.color.neutral[400]}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                const contribuicoesEditadas = listaTratada.map(
                  (contribuicao) => {
                    if (
                      contribuicoesSelecionadas.includes(
                        contribuicao.competencia
                      )
                    ) {
                      return {
                        ...contribuicao,
                        salarioDeContribuicao: novoValorContribuicao,
                      };
                    }
                    return contribuicao;
                  }
                );
                setListaTratada(contribuicoesEditadas);
              }}
            >
              Aplicar
            </Button>
          </div>
        </Footer>
      )}
    </>
  );
}

export default Contribuicoes;
