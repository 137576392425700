import axios from "axios";
import { API_HOST, API_HOST_PREV_DASHBOARD_V2 } from "../constants";
import qs from "qs";
import { authenticatedGet, authenticatedPost } from "./adapter";

const {REACT_APP_PREV_API} = process.env

const getToken = (paramName = "access_token_prev") => {
  if (localStorage) {
    return localStorage.getItem(paramName);
  }
  return null;
};

const withToken = (fn) => async (params) => {
  if (!getToken()) return;
  const token = getToken();
  return await fn({ token, ...params });
};

const authenticatedPut = withToken(
  async ({ path, data, token, host = API_HOST, headers = {} }) => {
    const url = `${host}${path}`;
    const auth = "Bearer " + token;

    return await axios
      .put(url, data, {
        headers: {
          Authorization: auth,
          ...headers,
        },
      })
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
        if (response.status === 204) {
          return [];
        }
      })
      .catch(handleErrors);
  }
);

export async function criarPeticao(param) {
  if (!getToken()) return;

  const url = `${API_HOST}/peticoes`;
  const auth = "Bearer " + getToken();

  return axios
    .post(url, param, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function atualizarPeticao(param) {
  if (!getToken()) return;

  const url = `${API_HOST}/peticoes/${param.id}`;
  const auth = "Bearer " + getToken();

  return axios
    .put(url, param, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function avaliarPeticao(param) {
  if (!getToken()) return;

  const url = `${API_HOST}/peticoes/avaliacoes`;
  const auth = "Bearer " + getToken();

  return axios
    .post(url, param, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function getAvaliacoesOpcoes() {
  if (!getToken()) return;

  const url = `${API_HOST}/peticoes/avaliacoes/opcoes`;
  const auth = "Bearer " + getToken();

  return axios
    .get(url, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function getPeticoes(param) {
  if (!getToken()) return;

  const queryString = qs.stringify(param, {
    addQueryPrefix: true,
    skipNulls: true,
    indices: false,
  });

  const url = `${API_HOST}/peticoes${queryString}`;
  const auth = "Bearer " + getToken();

  return axios
    .get(url, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(function () {
      return [];
    });
}

export async function getPeticaoById(id) {
  if (!getToken()) return;

  const url = `${API_HOST}/peticoes/${id}`;
  const auth = "Bearer " + getToken();

  return axios
    .get(url, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function getPeticaoByIdLimitada(id) {
  const url = `${API_HOST}/peticoes/${id}`;

  return axios
    .get(url)
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
      if (response.status === 204) {
        return [];
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function updateStatus(param) {
  if (!getToken()) return;

  const url = `${API_HOST}/peticoes/${param.id}/status/${param.status}`;
  const auth = "Bearer " + getToken();

  return axios
    .put(url, param, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function excluirPeticao(param) {
  if (!getToken()) return;

  const url = `${API_HOST}/peticoes/${param.id}`;
  const auth = "Bearer " + getToken();

  return axios
    .delete(url, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export async function convertePeticao(param) {
  if (!getToken()) return;

  const url = `${API_HOST}/utils/peticoes/converter`;
  const auth = "Bearer " + getToken();

  return axios
    .put(url, param, {
      headers: { Authorization: auth },
    })
    .then(function (response) {
      if (response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      throw error;
    });
}

export const atualizarPeticaoAdvogado = async (paramPeticao, paramUrl) => {
  return await authenticatedPut({
    path: `/clientes-peticoes/${paramUrl.idDaPeticao}`,
    data: paramPeticao,
    host: API_HOST_PREV_DASHBOARD_V2,
  });
};

export const getPeticao = async (param) => {
  const response = await authenticatedGet({
    path: `/clientes-peticoes?cpf=${param}`,
    host: API_HOST_PREV_DASHBOARD_V2,
    token: getToken(),
  });
  return response;
}

export const generatePdfPeticao = async (body) => {
  const response = await axios({
    method: "POST",
    url: REACT_APP_PREV_API + '/download-converter/pdf',
    data: body,
    responseType: "blob",
  })

  return response.data
}

export const uploadPeticaoInicial = async (file, cpfCliente, idBeneficio, type, numeroDoBeneficio) => {
  const host = API_HOST_PREV_DASHBOARD_V2;
  const blob = new Blob([file], { type: "application/pdf" });
  const path = `/clientes-arquivos/${cpfCliente}`;
  let data = new FormData();
  data.append("arquivo", blob, `Peticao_inicial_${type}_${numeroDoBeneficio}.pdf`);
  data.append("tipoDocumento", "PETICAO_INICIAL");
  data.append("uuidBeneficio", idBeneficio);
  return authenticatedPost({ path, host, data });;
}

// export async function atualizarPeticaoAdvogado(paramPeticao, paramUrl) {
//   if (!getToken()) return;

//   const url = `${API_HOST}/clientes-peticoes/${paramUrl.idDaPeticao}`;
//   const auth = "Bearer " + getToken();

//   return axios
//     .put(url, paramPeticao, {
//       headers: { Authorization: auth },
//     })
//     .then(function (response) {
//       if (response.data) {
//         return response.data;
//       }
//     })
//     .catch(function (error) {
//       throw error;
//     });
// }
