import {
  DownloadOutlined,
  FileZipOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  PrevPlatformContext,
  SegmentContext,
  Text,
} from "@prev/ui-kit";
import { Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";
import pasteImage from "../images/pasta-documentos-prev.png";
import {
  downloadArquivo,
  downloadArquivoZip,
} from "../services/uploadFilesService";
import filesSaver from "../utils/filesSaver";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ConclusaoContainer = styled.div`
  margin: 40px auto;
  max-width: 710px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 22px;
`;

const ArquivosContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const FilesContainer = styled.div`
  max-height: 152px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 3px;
  ::-webkit-scrollbar {
    width: 8px; /* tamanho do scroll inteiro */
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.theme.color.neutral[300]}; /* cor do fundo do scoll */
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.theme.color.neutral[500]}; /* cor do scroll */
    border-radius: 20px;
  }
`;

const SaveFilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.brand.primary};
  width: 100%;
  border-radius: 4px;
  && > div {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 11px 16px;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 287px;
  align-items: center;
  justify-content: center;
  word-break: "break-word";
  padding: 16px;
  max-width: 301px;
  margin: auto;
`;

function capitalizeFirstLetter(string) {
  const words = string.toLowerCase().split(" ");
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}

function Conclusao() {
  const { theme } = useContext(PrevPlatformContext);
  const {
    parseCnis,
    beneficio,
    tipoFluxo,
    parseCartaDeConcessao,
    filesPdf,
    valorRmi,
  } = useContext(FluxoBeneficioContext);
  const [dadosSeguradoAtualizado, setDadosSeguradoAtualizado] = useState();
  const [downloadingAllFiles, setDownloadingAllFiles] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState();
  const { analytics, identified } = useContext(SegmentContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (analytics && identified) {
      analytics.track(
        "Entrou na tela de download do processo no fluxo de benefício por incapacidade"
      );
    }
  }, [analytics, identified]);

  const realizaDownloaIndividual = async (uuidArquivo, fileName) => {
    setDownloadingFile((prevState) => ({ ...prevState, [uuidArquivo]: true }));
    const response = await downloadArquivo(uuidArquivo);
    if (!response.error && analytics && identified) {
      analytics.track(
        "Fez download de pdf individual no fluxo de benefício por incapacidade"
      );
    }
    filesSaver(response, fileName);
    setDownloadingFile((prevState) => ({ ...prevState, [uuidArquivo]: false }));
  };

  const realizaDownloadZip = async () => {
    setDownloadingAllFiles(true);
    const response = await downloadArquivoZip({
      cpf: dadosSeguradoAtualizado.cpf,
      uuidBeneficio: beneficio.uuid,
    });
    if (!response.error && analytics && identified) {
      analytics.track(
        "Fez download do processo completo em PDF no fluxo de benefício por incapacidade"
      );
    }
    filesSaver(response, `${dadosSeguradoAtualizado.cpf}_arquivos.zip`);
    setDownloadingAllFiles(false);
  };

  useEffect(() => {
    if (tipoFluxo === "CONCESSAO")
      setDadosSeguradoAtualizado(parseCnis.segurado);
    if (tipoFluxo === "RESTABELECIMENTO")
      setDadosSeguradoAtualizado(parseCartaDeConcessao.cliente);
  }, []);

  useEffect(() => {
    if (!filesPdf?.length) {
      navigate("/");
    } else {
      filesPdf.forEach((file) => {
        setDownloadingFile((prevState) => ({
          ...prevState,
          [file.uuid]: false,
        }));
      });
    }
  }, [filesPdf]);

  if (!filesPdf?.length) return null;

  return (
    <ConclusaoContainer>
      <TitleContainer>
        <img src={pasteImage} alt="documento finalizado" />
        <Text emphasys size="xl">
          Processo concluído com sucesso!
        </Text>
      </TitleContainer>
      <div style={{ marginTop: 40 }}>
        {dadosSeguradoAtualizado && (
          <Row>
            <Col span={12}>
              <Row gutter={[0, 18]}>
                <Col span={24}>
                  <Text emphasys>Resumo do processo</Text>
                </Col>
                <Col span={24}>
                  <Text color={theme.color.neutral[600]}>PROCESSO</Text>
                  <Text>
                    {(() => {
                      if (tipoFluxo === "CONCESSAO") return "Concessão";
                      if (tipoFluxo === "RESTABELECIMENTO")
                        return "Restabelecimento";
                    })()}{" "}
                    de benefício por incapacidade
                  </Text>
                </Col>
                <Col span={24}>
                  <Text color={theme.color.neutral[600]}>NOME DO CLIENTE</Text>
                  <Text>
                    {capitalizeFirstLetter(dadosSeguradoAtualizado.nome)}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text color={theme.color.neutral[600]}>CPF</Text>
                  <Text>{dadosSeguradoAtualizado.cpf}</Text>
                </Col>
                <Col span={12}>
                  <Text color={theme.color.neutral[600]}>NASCIMENTO</Text>
                  <Text>
                    {moment(
                      dadosSeguradoAtualizado.dataDeNascimento,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text color={theme.color.neutral[600]}>SEXO</Text>
                  <Text>
                    {capitalizeFirstLetter(dadosSeguradoAtualizado.sexo)}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text color={theme.color.neutral[600]}>VALOR DA CAUSA</Text>
                  <Text>
                    {valorRmi?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <ArquivosContainer>
                <StyledCard>
                  {filesPdf ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 16,
                      }}
                    >
                      <Text emphasys>Arquivos para download</Text>
                      <FilesContainer>
                        {filesPdf.map((file) => (
                          <div
                            key={file.uuid}
                            style={{ display: "flex", gap: 10 }}
                          >
                            {downloadingFile[file.uuid] ? (
                              <LoadingOutlined
                                style={{
                                  fontSize: 20,
                                  color: theme.color.brand.primary,
                                }}
                              />
                            ) : (
                              <DownloadOutlined
                                style={{
                                  fontSize: 20,
                                  color: theme.color.brand.primary,
                                }}
                                onClick={() =>
                                  realizaDownloaIndividual(file.uuid, file.nome)
                                }
                              />
                            )}
                            <Text>{file.nome}</Text>
                          </div>
                        ))}
                      </FilesContainer>
                      {downloadingAllFiles ? (
                        <SaveFilesContainer>
                          <div>
                            <LoadingOutlined
                              style={{
                                fontSize: 32,
                                color: theme.color.neutral[100],
                              }}
                            />
                            <div>
                              <Text
                                size="sm"
                                emphasys
                                color={theme.color.neutral[100]}
                              >
                                Salvando todos os arquivos
                              </Text>
                              <Text
                                size="xs-2"
                                color={theme.color.neutral[100]}
                              >
                                Por favor aguarde...
                              </Text>
                            </div>
                          </div>
                        </SaveFilesContainer>
                      ) : (
                        <SaveFilesContainer
                          onClick={() => realizaDownloadZip()}
                        >
                          <div>
                            <FileZipOutlined
                              style={{
                                fontSize: 32,
                                color: theme.color.neutral[100],
                              }}
                            />
                            <div>
                              <Text
                                size="sm"
                                emphasys
                                color={theme.color.neutral[100]}
                              >
                                Salvar todos os arquivos
                              </Text>
                              <Text
                                size="xs-2"
                                color={theme.color.neutral[100]}
                              >
                                {filesPdf?.length || 0} arquivos enviados
                              </Text>
                            </div>
                            <DownloadOutlined
                              style={{
                                fontSize: 13,
                                color: theme.color.neutral[100],
                              }}
                            />
                          </div>
                        </SaveFilesContainer>
                      )}
                      <Button
                        onClick={() => {
                          localStorage.removeItem("fluxo");
                          location.href = "/";
                        }}
                        variant="link"
                      >
                        Voltar para o painel
                      </Button>
                    </div>
                  ) : null}
                </StyledCard>
              </ArquivosContainer>
            </Col>
          </Row>
        )}
      </div>
    </ConclusaoContainer>
  );
}

export default Conclusao;
