import {
  Ajuda,
  Button,
  DatePicker,
  FieldInput,
  Label,
  LoadingContext,
  PrevPlatformContext,
  SegmentContext,
  Select,
  Text,
  validateCpf,
} from "@prev/ui-kit";
import { Col, Form, Input, Row } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { textosAjuda } from "../constants";
import { FluxoBeneficioContext } from "../context/FluxoBeneficioProvider";
import {
  isValidDate,
  dateFormatYYYYMMDDToDDMMYYYY,
} from "../functions/momentFunctions";
import { getDoencas, getProfissoes } from "../services/CalculoService";
import onKeyUpDatepickerMask from "../utils/masks/maskDatePicker";
import {
  deleteArquivo,
  listarArquivosDoBeneficio,
  uploadFile,
  verificaCartaExistenteEDeleta,
} from "../services/uploadFilesService";

const InformacoesComplementaresContainer = styled.div`
  max-width: 1100px;
  margin: 18px auto;
  padding-bottom: 100px;
`;

const HeaderContainer = styled.div`
  margin: 22px 0px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  width: 100%;
  box-shadow: 0px 1px 15px rgba(99, 99, 99, 0.2);
  background-color: white;
`;

const CustomFormItem = styled(Form.Item)`
  && .ant-form-item-control-input {
    display: none;
  }
`;

function InformacoesComplementares() {
  const { theme } = useContext(PrevPlatformContext);

  const {
    parseCnis,
    setParseCnis,
    tipoFluxo,
    postBeneficio,
    postComunicado,
    calculoBeneficio,
    parseComunicado,
    beneficio,
    resultadoComunicado,
    setInformacoesComplementares,
    parseCartaDeConcessao,
    setParseCartaDeConcessao,
    arquivoCartaConcessao,
    arquivoComunicado,
    arquivoCnis,
  } = useContext(FluxoBeneficioContext);
  const [listaProfissao, setListaProfissao] = useState([]);
  const [isLoadingProfissaoFetch, setIsLoadingProfissaoFetch] = useState(false);
  const [fetchProfissoesResponse, setFetchProfissoesResponse] = useState([]);
  const [descricaoSumaria, setDescricaoSumaria] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.informacoesComplementares
      ?.descricaoSumaria
  );
  const [condicoesGeraisDoExercicio, setCondicoesGeraisDoExercicio] = useState(
    JSON.parse(localStorage.getItem("fluxo"))?.informacoesComplementares
      ?.condicoesGeraisDoExercicio
  );
  const [listaDoencas, setListaDoencas] = useState([]);
  const [isLoadingDoencasFetch, setIsLoadingDoencasFetch] = useState(false);
  const [genero, setGenero] = useState(
    parseCnis?.segurado.sexo || parseCartaDeConcessao?.cliente.sexo || ""
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [dadosInformacoesComplementares, setDadosInformacoesComplementares] =
    useState(
      JSON.parse(localStorage.getItem("fluxo"))?.informacoesComplementares
    );
  const { analytics, identified } = useContext(SegmentContext);
  const { loading, loaded } = useContext(LoadingContext);

  const propsButtonNoSelected = {
    style: {
      border: `1px solid ${theme.color.neutral[600]}`,
      width: "49%",
    },
    color: theme.color.neutral[100],
    textcolor: theme.color.neutral[600],
  };

  const propsButtonSelected = {
    color: "brand-secondary",
    style: {
      width: "49%",
    },
  };

  useEffect(() => {
    if (analytics && identified)
      analytics.track(
        "Entrou na tela de Informações Complementares no fluxo de benefício por incapacidade"
      );
  }, [analytics, identified]);

  useEffect(() => {
    if (tipoFluxo === "CONCESSAO") {
      return setDadosInformacoesComplementares(parseCnis?.segurado);
    }
    if (tipoFluxo === "RESTABELECIMENTO")
      return setDadosInformacoesComplementares(parseCartaDeConcessao?.cliente);
  }, [parseCnis, parseCartaDeConcessao]);

  const profissoesResolver = async (value) => {
    const response = await getProfissoes(value);
    if (response) {
      const formatResponse = response.map((profissao) => ({
        value: `${profissao.codigo} - ${profissao.titulo}`,
        label: `CBO ${profissao.codigo} - ${profissao.titulo}`,
      }));
      setListaProfissao(formatResponse);
      setFetchProfissoesResponse(response);
      setIsLoadingProfissaoFetch(false);
    } else {
      setIsLoadingProfissaoFetch(false);
    }
  };

  const doencasResolver = async (value) => {
    const response = await getDoencas(value);
    if (response) {
      const formatResponse = response.map((doenca) => ({
        value: `${doenca.codigo} - ${doenca.descricao}`,
        label: `${doenca.codigo} - ${doenca.descricao}`,
      }));
      setListaDoencas(formatResponse);
      setIsLoadingDoencasFetch(false);
    } else {
      setIsLoadingDoencasFetch(false);
    }
  };

  const profissaoHandler = (value) => {
    if (value.length >= 3) {
      setIsLoadingProfissaoFetch(true);
      profissoesResolver(value);
    }
  };

  const doencasHandler = (value) => {
    if (value.length >= 3) {
      setIsLoadingDoencasFetch(true);
      doencasResolver(value);
    }
  };

  const debouncedProfissaoCallback = useMemo(
    () => debounce(profissaoHandler, 300),
    []
  );

  const debouncedDoencaCallback = useMemo(
    () => debounce(doencasHandler, 300),
    []
  );

  const onFinish = async (values) => {
    if (tipoFluxo === "CONCESSAO") {
      const payloadBeneficioConcessao = {
        cliente: {
          ...calculoBeneficio.segurado,
        },
        numeroDoBeneficio: parseComunicado.numeroDoBeneficio,
        tipoBeneficio: parseComunicado.tipoBeneficio,
        codigoBeneficio: parseComunicado.codigoBeneficio,
        dataRequerimentoDoBeneficio: values.dataRequerimentoDoBeneficio,
      };

      const beneficioResponse = await postBeneficio(payloadBeneficioConcessao);
      if (beneficioResponse.error) return navigate("/error");
      const payloadComunicadoConcessao = {
        ...parseComunicado,
        beneficio: {
          uuid: beneficioResponse.uuid,
          numeroDoBeneficio: parseComunicado.numeroDoBeneficio,
        },
        cliente: {
          ...beneficioResponse.cliente,
          sexo: values.genero,
          dataDeNascimento: moment(values.dataDeNascimento).format(
            "YYYY-MM-DD"
          ),
        },
      };
      // atualiza CNIS conforme edita informações complementares
      setParseCnis((prevState) => ({
        ...prevState,
        segurado: {
          ...prevState.segurado,
          cpf: values.cpf,
          sexo: values.genero,
          dataDeNascimento: moment(values.dataDeNascimento).format(
            "YYYY-MM-DD"
          ),
        },
      }));
      const response = await postComunicado(payloadComunicadoConcessao);
      loading();
      await verificaCartaExistenteEDeleta({
        cpf: values.cpf,
        uuidBeneficio: beneficioResponse.uuid,
        tipoArquivoDocumento: "CARTA_DE_INDEFERIMENTO",
      });

      await verificaCartaExistenteEDeleta({
        cpf: values.cpf,
        uuidBeneficio: beneficioResponse.uuid,
        tipoArquivoDocumento: "CNIS_CADASTRO_NACIONAL_DE_INFORMACOES_SOCIAIS",
      });

      // FAZ O UPLOAD DO CNIS
      arquivoCnis.length &&
        (await uploadFile({
          file: arquivoCnis[0].file,
          tipoDocumento: "CNIS_CADASTRO_NACIONAL_DE_INFORMACOES_SOCIAIS",
          cpf: values.cpf,
          uuidBeneficio: beneficioResponse.uuid,
        }));

      // FAZ O UPLOAD DA CARTA_DE_INDEFERIMENTO
      arquivoComunicado.length &&
        (await uploadFile({
          file: arquivoComunicado[0].file,
          tipoDocumento: "CARTA_DE_INDEFERIMENTO",
          cpf: values.cpf,
          uuidBeneficio: beneficioResponse.uuid,
        }));
      loaded();

      if (response.error) navigate("/error");
    }
    if (tipoFluxo === "RESTABELECIMENTO") {
      const payloadBeneficioRestabelecimento = {
        ...parseCartaDeConcessao,
        cliente: {
          ...parseCartaDeConcessao.cliente,
          cpf: values.cpf,
          dataDeNascimento: moment(values.dataDeNascimento).format(
            "YYYY-MM-DD"
          ),
          sexo: values.genero,
        },
        ocupacao: {
          ocupacao: values.ocupacao,
          descricao_sumaria: descricaoSumaria,
          condicoes_gerais_exercicio: condicoesGeraisDoExercicio,
        },
        doenca: [
          ...values.doencas.map((e) => ({
            codigo: e.split("-")[0].trim(),
            nome: e.split("-")[1].trim(),
          })),
        ],
        dataCessacaoDoBeneficio: moment(values.cessacaoDoBeneficio).format(
          "YYYY-MM-DD"
        ),
      };
      // atualiza Carta de Concessao conforme edita informações complementares
      setParseCartaDeConcessao((prevState) => ({
        ...prevState,
        cliente: {
          ...prevState.cliente,
          cpf: values.cpf,
          sexo: values.genero,
          dataDeNascimento: moment(values.dataDeNascimento).format(
            "YYYY-MM-DD"
          ),
        },
      }));
      const response = await postBeneficio(payloadBeneficioRestabelecimento);
      await verificaCartaExistenteEDeleta({
        cpf: values.cpf,
        uuidBeneficio: response.uuid,
        tipoArquivoDocumento: "CARTA_DE_CONCESSAO",
      });
      loading();

      // FAZ O UPLOAD DA CARTA_DE_CONCESSAO
      arquivoCartaConcessao.length &&
        (await uploadFile({
          file: arquivoCartaConcessao[0].file,
          tipoDocumento: "CARTA_DE_CONCESSAO",
          cpf: values.cpf,
          uuidBeneficio: response.uuid,
        }));
      loaded();

      if (response.error) navigate("/error");
    }
    let fluxo = JSON.parse(localStorage.getItem("fluxo"));
    fluxo = {
      ...fluxo,
      informacoesComplementares: {
        ...values,
        dataDeNascimento: values.dataDeNascimento,
        genero: values.genero,
        descricaoSumaria: descricaoSumaria,
        condicoesGeraisDoExercicio: condicoesGeraisDoExercicio,
      },
    };
    localStorage.setItem("fluxo", JSON.stringify(fluxo));
    setInformacoesComplementares({
      ...values,
      genero: values.genero,
      descricaoSumaria: descricaoSumaria,
      condicoesGeraisDoExercicio: condicoesGeraisDoExercicio,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    return () => {
      debouncedProfissaoCallback.cancel();
      debouncedDoencaCallback.cancel();
    };
  }, []);

  useEffect(() => {
    if (tipoFluxo === "CONCESSAO" && beneficio && resultadoComunicado)
      navigate("/editor");
    if (tipoFluxo === "RESTABELECIMENTO" && beneficio) navigate("/editor");
  }, [beneficio, resultadoComunicado]);

  useEffect(() => {
    if (
      (!tipoFluxo || tipoFluxo === "RESTABELECIMENTO") &&
      !parseCartaDeConcessao
    )
      return navigate("/");
    if (
      (!tipoFluxo || tipoFluxo === "CONCESSAO") &&
      (!parseCnis || !parseComunicado)
    )
      return navigate("/");
  }, []);

  if (!dadosInformacoesComplementares) return null;

  return (
    <Form
      name="informacoesComplementares"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <InformacoesComplementaresContainer>
        <HeaderContainer>
          <Text>Informações complementares</Text>
          <Label>
            <Text type="span" color="red">
              *{" "}
            </Text>
            Campos de preenchimento obrigatório
          </Label>
        </HeaderContainer>
        <div style={{ marginBottom: 24 }}>
          <Text color="#949595">Nome do segurado</Text>
          <Text>
            {parseCartaDeConcessao?.cliente?.nome || parseCnis?.segurado?.nome}
          </Text>
        </div>
        <Row gutter={[24, 0]}>
          <Col span={6}>
            <Form.Item
              name="cpf"
              rules={[
                {
                  required: true,
                  message: "Por favor insira um cpf válido",
                  whitespace: true,
                },
                {
                  validator: (_, value) => {
                    return validateCpf(value) || value === "" || !value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Número de CPF inválido"));
                  },
                },
              ]}
              initialValue={dadosInformacoesComplementares?.cpf || undefined}
            >
              <FieldInput.Cpf
                label="CPF do segurado"
                required
                legacy={true}
                data-testid="input-informacoes-complementares-cpf"
                id="input-informacoes-complementares-cpf"
                placeholder="Digite o CPF"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Label>
              <Text type="span" color="red">
                *{" "}
              </Text>
              Nascimento do segurado
              <Form.Item
                name="dataDeNascimento"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira uma data válida",
                  },
                  {
                    validator: (_, value) => {
                      if (value > moment().subtract(1, "day")) {
                        return Promise.reject(
                          "Data de nascimento não deve ser maior do que hoje"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                initialValue={
                  isValidDate(
                    dadosInformacoesComplementares?.dataDeNascimento
                  ) &&
                  dateFormatYYYYMMDDToDDMMYYYY(
                    dadosInformacoesComplementares.dataDeNascimento
                  )
                }
                onKeyUp={(event) => onKeyUpDatepickerMask(event)}
              >
                <DatePicker
                  prefix="/"
                  style={{ width: "100%", marginTop: 8 }}
                  size="large"
                  data-testid="input-informacoes-complementares-data-nascimento"
                  id="input-informacoes-complementares-data-nascimento"
                />
              </Form.Item>
            </Label>
          </Col>
          <Col span={5}>
            <Label>
              <Text type="span" color="red">
                *{" "}
              </Text>
              Sexo do segurado
              <div style={{ display: "flex", gap: 8, marginTop: 8 }}>
                <Button
                  {...(genero === "MASCULINO"
                    ? propsButtonSelected
                    : propsButtonNoSelected)}
                  size="large"
                  onClick={() => {
                    form.setFieldsValue({ genero: "MASCULINO" });
                    setGenero("MASCULINO");
                  }}
                  data-testid="button-informacoes-complementares-masculino"
                  id="button-informacoes-complementares-masculino"
                >
                  Masculino
                </Button>
                <Button
                  {...(genero === "FEMININO"
                    ? propsButtonSelected
                    : propsButtonNoSelected)}
                  size="large"
                  onClick={() => {
                    form.setFieldsValue({ genero: "FEMININO" });
                    setGenero("FEMININO");
                  }}
                  data-testid="button-informacoes-complementares-feminino"
                  id="button-informacoes-complementares-feminino"
                >
                  Feminino
                </Button>
              </div>
              <CustomFormItem
                name="genero"
                rules={[
                  {
                    required: true,
                    message: "Por favor selecione um gênero",
                  },
                ]}
                initialValue={genero}
                shouldUpdate={(prevState, currentState) => {
                  return prevState.genero !== currentState.genero;
                }}
              >
                <Input style={{ display: "none" }} type="hidden" />
              </CustomFormItem>
            </Label>
          </Col>
          <Col span={7}>
            <Label>
              <div style={{ display: "flex", gap: 3 }}>
                <Text type="span" color="red">
                  *{" "}
                </Text>
                {tipoFluxo === "RESTABELECIMENTO"
                  ? "Data de Cessação do Benefício (DCB)"
                  : "Data Requerimento do Benefício"}
                <Ajuda
                  itens={textosAjuda}
                  page="fluxoBeneficioIncapacidade"
                  element={
                    tipoFluxo === "RESTABELECIMENTO"
                      ? "dataDeCessacaoDoBeneficio"
                      : "dataDeRequerimentoDoBeneficio"
                  }
                  propsPopover={{ placement: "left" }}
                />
              </div>
              {tipoFluxo === "RESTABELECIMENTO" && (
                <Form.Item
                  name="cessacaoDoBeneficio"
                  initialValue={
                    isValidDate(
                      dadosInformacoesComplementares.cessacaoDoBeneficio
                    ) &&
                    dateFormatYYYYMMDDToDDMMYYYY(
                      dadosInformacoesComplementares.cessacaoDoBeneficio
                    )
                  }
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira uma data válida",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value >
                          moment()
                            .endOf("month")
                            .subtract(1, "month")
                            .subtract(2, "day")
                        ) {
                          return Promise.reject(
                            `Informe no máximo o penúltimo dia do mês anterior (${moment()
                              .endOf("month")
                              .subtract(1, "month")
                              .subtract(2, "day")
                              .format("DD/MM/YYYY")})`
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  onKeyUp={(event) => onKeyUpDatepickerMask(event)}
                >
                  <DatePicker
                    prefix="/"
                    style={{ width: "100%", marginTop: 8 }}
                    size="large"
                    data-testid="input-informacoes-complementares-data-cessacao-beneficio"
                    id="input-informacoes-complementares-data-cessacao-beneficio"
                  />
                </Form.Item>
              )}
              {tipoFluxo === "CONCESSAO" && (
                <Form.Item
                  name="dataRequerimentoDoBeneficio"
                  initialValue={
                    isValidDate(parseCnis?.dataDoFimDoCalculo) &&
                    dateFormatYYYYMMDDToDDMMYYYY(parseCnis.dataDoFimDoCalculo)
                  }
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira uma data válida",
                    },
                    {
                      validator: (_, value) => {
                        if (value > moment().endOf("day")) {
                          return Promise.reject(
                            "A data do requerimento não deve ser maior do que hoje"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                    {
                      validator: (_, value) => {
                        if (value <= moment("2019-11-13").endOf("day")) {
                          return Promise.reject(
                            `Informe uma data a partir de 13/11/2019 (EC 103/19)`
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  onKeyUp={(event) => onKeyUpDatepickerMask(event)}
                >
                  <DatePicker
                    prefix="/"
                    style={{ width: "100%", marginTop: 8 }}
                    size="large"
                    data-testid="input-informacoes-complementares-data-requerimento-beneficio"
                    id="input-informacoes-complementares-data-requerimento-beneficio"
                  />
                </Form.Item>
              )}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Label>
              <div style={{ display: "flex" }}>
                <Text type="span" color="red">
                  *{" "}
                </Text>
                Ocupação
                <Ajuda
                  itens={textosAjuda}
                  page="fluxoBeneficioIncapacidade"
                  element="ocupacaoCBO"
                  propsPopover={{ placement: "left" }}
                />
              </div>
              <Form.Item
                name="ocupacao"
                initialValue={dadosInformacoesComplementares?.ocupacao}
                rules={[
                  {
                    required: true,
                    message: "Por favor insira uma ocupação",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%", marginTop: 8 }}
                  showSearch
                  options={listaProfissao}
                  onSearch={debouncedProfissaoCallback}
                  loading={isLoadingProfissaoFetch}
                  allowClear
                  onSelect={(profissao) => {
                    const findProfissao = fetchProfissoesResponse.find(
                      (titulo) =>
                        titulo.codigo === profissao.split("-")[0].trim()
                    );
                    setDescricaoSumaria(findProfissao.descricao_sumaria);
                    setCondicoesGeraisDoExercicio(
                      findProfissao.condicoes_gerais_exercicio
                    );
                  }}
                  onClear={() => {
                    setDescricaoSumaria();
                    setCondicoesGeraisDoExercicio();
                  }}
                  notFoundContent={null}
                  data-testid="select-informacoes-complementares-ocupacao"
                  id="select-informacoes-complementares-ocupacao"
                  placeholder="Pesquise por CBO ou profissão"
                  filterOption={false}
                />
              </Form.Item>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {descricaoSumaria ? (
              <div>
                <Text emphasys>Descrição Sumária</Text>
                <Text>{descricaoSumaria}</Text>
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            {condicoesGeraisDoExercicio ? (
              <div style={{ marginBottom: 24 }}>
                <Text emphasys>Condições Gerais de Exercicio</Text>
                <Text>{condicoesGeraisDoExercicio}</Text>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Label>
              <div style={{ display: "flex" }}>
                <Text type="span" color="red">
                  *{" "}
                </Text>
                Doença
                <Ajuda
                  itens={textosAjuda}
                  page="fluxoBeneficioIncapacidade"
                  element="listaDoencasCID10"
                  propsPopover={{ placement: "left" }}
                />
              </div>
              <Form.Item
                name="doencas"
                initialValue={dadosInformacoesComplementares?.doencas}
                rules={[
                  {
                    required: true,
                    message:
                      "Por favor insira ao menos uma condição geral de exercício",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%", marginTop: 8 }}
                  showSearch
                  options={listaDoencas}
                  onSearch={debouncedDoencaCallback}
                  loading={isLoadingDoencasFetch}
                  allowClear
                  mode="multiple"
                  data-testid="select-informacoes-complementares-doencas"
                  id="select-informacoes-complementares-doencas"
                  notFoundContent={null}
                  placeholder="Pesquise por CID10 ou nome da doença"
                  filterOption={false}
                />
              </Form.Item>
            </Label>
          </Col>
        </Row>
      </InformacoesComplementaresContainer>
      <Footer>
        <div
          style={{
            maxWidth: 1100,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
            margin: "auto",
          }}
        >
          <div style={{ display: "flex", gap: 24 }}>
            <Button size="large" color="error" onClick={() => navigate(-1)}>
              Cancelar
            </Button>
            <Button size="large" htmlType="submit">
              Continuar
            </Button>
          </div>
        </div>
      </Footer>
    </Form>
  );
}

export default InformacoesComplementares;
